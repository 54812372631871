html {
  height: 100%;
  box-sizing: border-box;
  background-color: transparent;
  margin: 0;
  padding: 0;
  font-size: 10px;
}

body {
  box-sizing: border-box;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  margin: 0;
  min-height: 100%;
  background-color: #ffffff;
  color: #171717;
  font-size: 1.4rem;
  font-family: "Open Sans", sans-serif;
  background-image: radial-gradient(#21212122 20%, transparent 20%);
  background-position: 0 0, 50px 50px;
  background-size: 8px 8px;
}

#root {
  min-height: 100vh;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  outline: none !important;
}
*:focus,
*::before:focus,
*::after:focus {
  outline: none !important;
}

.spacing {
  width: 100%;
  height: 1.6rem;
}

.no-data {
  padding: 1.6rem;
  width: 100%;
  display: block;
  text-align: center;
  background-color: #ffffff;
  color: #6a6a6a;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

address {
  font-style: normal;
}

button {
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
}

a {
  text-decoration: unset;
  color: unset;
  cursor: pointer;
}
a:hover {
  -webkit-filter: brightness(110%);
  -ms-filter: brightness(110%);
  filter: brightness(110%);
}
a:active, a:visited {
  -webkit-filter: brightness(110%);
  -ms-filter: brightness(110%);
  filter: brightness(110%);
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #ffffff;
}

::-webkit-scrollbar-thumb {
  background: #757575;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}

::-webkit-scrollbar-thumb:hover {
  background: #525252;
}

.global__btnv2 {
  padding: 0;
  border: none;
  display: grid;
  color: #e7ecef;
  cursor: pointer;
  min-height: 3.4rem;
  position: relative;
  align-items: stretch;
  grid-template-columns: auto 3rem;
  background-color: #490151;
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
  -webkit-box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.3);
}
.global__btnv2__text {
  font-weight: 600;
  font-size: 1.4rem;
  align-self: center;
  padding: 0.8rem 3rem 0.8rem 2rem;
}
.global__btnv2__icon {
  height: 100%;
  display: flex;
  color: #171717;
  padding-left: 14%;
  position: relative;
  align-items: center;
  background-color: #ffffff;
  justify-content: flex-start;
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
}
.global__btnv2__icon--triangle {
  width: 0;
  height: 0;
  left: -1.4rem;
  position: absolute;
  border-right: 1.5rem solid #ffffff;
  border-top: 3.45rem solid transparent;
  border-bottom: 0rem solid transparent;
  -webkit-transition: border-right-color 200ms linear;
  -moz-transition: border-right-color 200ms linear;
  -ms-transition: border-right-color 200ms linear;
  -o-transition: border-right-color 200ms linear;
  transition: border-right-color 200ms linear;
}
.global__btnv2:hover {
  color: #171717;
  background-color: #ffffff;
}
.global__btnv2:hover .global__btnv2__icon {
  color: #e7ecef;
  background-color: #490151;
}
.global__btnv2:hover .global__btnv2__icon--triangle {
  border-right-color: #490151;
}
.global__btnv2.rounded-corners {
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
}
.global__btnv2.rounded-corners .global__btnv2__icon {
  -webkit-border-top-right-radius: 1rem;
  -webkit-border-bottom-right-radius: 1rem;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topright: 1rem;
  -moz-border-radius-bottomright: 1rem;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-topleft: 0;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.global__btnv2.--SECONDARY {
  background-color: #bdbdbd;
  color: #171717;
}
.global__btnv2.--SECONDARY:hover {
  background-color: #ffffff;
  color: #171717;
}
.global__btnv2.--SECONDARY:hover .global__btnv2__icon {
  background-color: #bdbdbd;
  color: #171717;
}
.global__btnv2.--SECONDARY:hover .global__btnv2__icon--triangle {
  border-right-color: #bdbdbd;
}
.global__btnv2:disabled {
  background-color: #d6d6d6 !important;
  color: #6a6a6a !important;
  cursor: not-allowed !important;
}
.global__btnv2:disabled .global__btnv2__icon {
  color: #e7ecef !important;
  background-color: #393939 !important;
}
.global__btnv2:disabled .global__btnv2__icon--triangle {
  border-right-color: #393939 !important;
}
.global__btnv2.dark-icon .global__btnv2__icon {
  color: #e7ecef;
  background-color: #393939;
}
.global__btnv2.dark-icon .global__btnv2__icon--triangle {
  border-right-color: #393939;
}
.global__btnv2.dark-icon:hover {
  color: #e7ecef;
  background-color: #393939;
}
.global__btnv2.dark-icon:hover .global__btnv2__icon {
  background-color: #490151;
}
.global__btnv2.dark-icon:hover .global__btnv2__icon--triangle {
  border-right-color: #490151;
}
.global__btnv2.dark-icon.--SECONDARY .frame-button__icon {
  background-color: #171717;
  color: #e7ecef;
}
.global__btnv2.dark-icon.--SECONDARY .frame-button__icon--triangle {
  border-right-color: #171717;
}
.global__btnv2.dark-icon.--SECONDARY:hover {
  background-color: #171717;
  color: #e7ecef;
}
.global__btnv2.dark-icon.--SECONDARY:hover .frame-button__icon {
  background-color: #bdbdbd;
  color: #171717;
}
.global__btnv2.dark-icon.--SECONDARY:hover .frame-button__icon--triangle {
  border-right-color: #bdbdbd;
}
.global__btnv2.dark-icon:disabled {
  background-color: #585858 !important;
  color: #999999 !important;
}
.global__rounded-corners {
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
}
.global__lottie-container {
  overflow: hidden;
  margin: 0 auto;
  outline: none !important;
  width: 100%;
  height: 100%;
}
.global__footer {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  left: 0;
  right: 0;
  padding: 0.8rem;
  background-color: #4c4c4c;
  -webkit-transition: left 0.3s linear;
  -moz-transition: left 0.3s linear;
  -ms-transition: left 0.3s linear;
  -o-transition: left 0.3s linear;
  transition: left 0.3s linear;
}
.global__menu-open-padding {
  padding-left: 300px !important;
}
.global__menu-open-absolute {
  left: 300px !important;
  -webkit-transition: left linear 0.3s;
  -moz-transition: left linear 0.3s;
  -ms-transition: left linear 0.3s;
  -o-transition: left linear 0.3s;
  transition: left linear 0.3s;
}
.global__error-wrapper {
  padding: 0 1.2rem 0.8rem 1.2rem;
  margin-top: -0.8rem;
}
.global__error {
  color: #fb3350;
  padding-top: 0.4rem;
  font-size: 10px;
  text-align: right;
}
.global__units {
  display: flex;
  align-items: center;
}
.global__units--item {
  color: #6a6a6a;
  padding: 0 0.4rem;
  font-size: 1.2rem;
  cursor: pointer;
}
.global__units--item:hover {
  color: #171717;
}
.global__units--item.active-unit {
  color: #171717;
  font-weight: 500;
}
.global__insert-above {
  border-top: 3px solid #d529ff;
}
.global__insert-below {
  border-bottom: 3px solid #d529ff;
}
.global__insert-into-column {
  -webkit-box-shadow: inset 0 0 0 3px #d529ff !important;
  -moz-box-shadow: inset 0 0 0 3px #d529ff !important;
  box-shadow: inset 0 0 0 3px #d529ff !important;
}
.global__space-after {
  margin-bottom: 0.8rem;
}
.global__link {
  display: flex;
  text-align: center;
  color: #a93e73;
  justify-content: center;
}
.global__link:hover {
  text-decoration: underline;
}
.global__form-group--desc {
  font-size: 1.2rem;
  color: #6a6a6a;
  padding-bottom: 0.4rem;
}
.global__form-group--apply-warning {
  grid-area: 3/1/4/5;
  font-size: 1.1rem;
  color: #fb3350;
  font-weight: 500;
  padding-bottom: 0.4rem;
}
.global__form-group--three-row {
  grid-area: 2/1/3/4;
}
.global__form-group--four-row {
  grid-area: 2/1/3/5;
}
.global__draggable {
  border: 2px dotted #d529ff !important;
}
.global__centerized {
  text-align: center;
  justify-content: center;
}
.global__justified {
  text-align: justify;
}
.global__left {
  text-align: left;
  justify-content: flex-start;
}
.global__right {
  text-align: right;
  justify-content: flex-end;
}
.global__quantity-hook {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.global__quantity-hook--btn {
  background-color: #a93e73;
  color: #e7ecef;
  width: 3rem;
  height: 3rem;
  border: none;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.global__quantity-hook--btn:focus {
  background-color: #a93e73;
}
.global__quantity-hook--btn:hover {
  -webkit-filter: brightness(0.9);
  -ms-filter: brightness(0.9);
  filter: brightness(0.9);
}
.global__quantity-hook--btn:disabled {
  background-color: #e0e7e9 !important;
  color: #999999;
  -webkit-filter: none;
  -ms-filter: none;
  filter: none;
  cursor: not-allowed;
  border: none;
}
.global__quantity-hook--qty {
  width: 3rem;
  font-size: 1.6rem;
  text-align: center;
  padding: 1.2rem 0.4rem;
}

.error-container__oneline-text {
  border: 1px solid #fb3350;
  color: #fb3350;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 3.2rem;
  font-weight: 500;
}

h2 {
  font-size: 2.8rem;
  font-weight: 500;
}

h3 {
  font-size: 2.4rem;
  font-weight: 600;
}

h4 {
  font-size: 2rem;
  font-weight: 600;
}

h5 {
  font-size: 1.8rem;
  font-weight: 600;
}

h6 {
  font-size: 1.6rem;
  font-weight: 600;
}

.roboto {
  font-family: "Roboto", sans-serif;
}

.opensans {
  font-family: "Open Sans", sans-serif;
}

.montserrat {
  font-family: "Montserrat", sans-serif;
}

.ubuntu {
  font-family: "Ubuntu", sans-serif;
}

.ibmplexsans {
  font-family: "IBM Plex Sans", sans-serif;
}

.hind {
  font-family: "Hind", sans-serif;
}

.ibmplexmono {
  font-family: "IBM Plex Mono", monospace;
}

.robotomono {
  font-family: "Roboto Mono", monospace;
}

.balsamiq {
  font-family: "Balsamiq Sans", cursive;
}

.arimamadurai {
  font-family: "Arima Madurai", cursive;
}

.teko {
  font-family: "Teko", sans-serif;
}

.robotoslab {
  font-family: "Roboto Slab", serif;
}

.lato {
  font-family: "Lato", sans-serif;
}

.source-sans-pro {
  font-family: "Source Sans Pro", sans-serif;
}

.raleway {
  font-family: "Raleway", sans-serif;
}

.playfair-display {
  font-family: "Playfair Display", serif;
}

.oswald {
  font-family: "Oswald", sans-serif;
}

.merriweather {
  font-family: "Merriweather", serif;
}

.pt-sans {
  font-family: "PT Sans", sans-serif;
}

.inter {
  font-family: "Inter", sans-serif;
}

.dm-sans {
  font-family: "DM Sans", sans-serif;
}

.quicksand {
  font-family: "Quicksand", sans-serif;
}

.poppins {
  font-family: "Poppins", sans-serif;
}

.nunito {
  font-family: "Nunito", sans-serif;
}

.grandhotel {
  font-family: "Grand Hotel", cursive;
}

.grandstander {
  font-family: "Grandstander", cursive;
}

.josefinslab {
  font-family: "Josefin Slab", serif;
}

.balootammudu {
  font-family: "Baloo Tammudu", sans-serif;
}

.gruppo {
  font-family: "Gruppo", sans-serif;
}

.fixed-edit-page-button-container {
  position: fixed;
  bottom: 4rem;
  right: 4rem;
  z-index: 1000;
}

.page-container {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 47.7rem;
  min-height: 100vh;
  background-color: #e0e7e9;
  position: relative;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  margin-right: auto;
  margin-left: auto;
  left: 0;
  -webkit-box-shadow: 0 0 7px 4px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0 7px 4px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 7px 4px rgba(0, 0, 0, 0.15);
}
@supports not (position: sticky) {
  .page-container {
    padding-top: 92px;
  }
}
.page-container .text-input {
  font-size: 1.6rem !important;
}
.page-container .competition-image-container {
  position: absolute;
  right: 0;
  z-index: 1000;
}
.page-container .competition-image-container .competition-image {
  max-width: 20rem;
  height: auto;
  max-height: 20rem;
  width: auto;
}
.page-container .competition-image-container.--TOP {
  top: 10.1rem;
}
.page-container .competition-image-container.--BOTTOM {
  bottom: 0;
}
.page-container .section {
  display: grid;
  position: relative;
  width: 100%;
  min-height: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.page-container .section.special-section {
  min-height: unset;
}
.page-container .section.show-as-top-section {
  z-index: 101;
}
.page-container .section.section-grid-col-1 {
  grid-template-columns: 100%;
}
.page-container .section.section-grid-col-2 {
  grid-template-columns: repeat(2, 1fr);
}
.page-container .section.section-grid-col-3 {
  grid-template-columns: repeat(3, 1fr);
}
.page-container .section.section-grid-col-4 {
  grid-template-columns: repeat(4, 1fr);
}
.page-container .section.section-grid-col-5 {
  grid-template-columns: repeat(5, 1fr);
}
.page-container .section.section-xs.section-grid-col-2, .page-container .section.section-xs.section-grid-col-3, .page-container .section.section-xs.section-grid-col-4, .page-container .section.section-xs.section-grid-col-5 {
  grid-template-columns: 1fr;
}
.page-container .section.section-sm.section-grid-col-2, .page-container .section.section-sm.section-grid-col-3, .page-container .section.section-sm.section-grid-col-4, .page-container .section.section-sm.section-grid-col-5 {
  grid-template-columns: 1fr;
}
.page-container .section.section-md.section-grid-col-4 {
  grid-template-columns: repeat(2, 1fr);
}
.page-container .section.section-md.section-grid-col-5 {
  grid-template-columns: repeat(3, 1fr);
}
.page-container .section.inactive {
  opacity: 0.5;
  backdrop-filter: blur(1rem);
}
.page-container .column {
  min-height: 50px;
  padding: 2.4rem;
  width: 100%;
  display: inline-block;
  margin: 0;
  position: relative;
}
.page-container .column.special-column {
  padding: 0;
  min-height: unset;
}
.page-container .dom__header {
  width: 100%;
  display: block;
  position: relative;
  padding-bottom: 1.2rem;
}
.page-container .dom__header .h {
  padding: 0;
  margin: 0;
  width: 100%;
}
.page-container .dom__header--h1 {
  font-size: 4.2rem;
  font-weight: 500;
}
.page-container .dom__header--h2 {
  font-size: 2.8rem;
  font-weight: 500;
}
.page-container .dom__header--h3 {
  font-size: 2.4rem;
  font-weight: 500;
}
.page-container .dom__header--h4 {
  font-size: 2rem;
  font-weight: 500;
}
.page-container .dom__header--h5 {
  font-size: 1.8rem;
  font-weight: 600;
}
.page-container .dom__header--h6 {
  font-size: 1.6rem;
  font-weight: 600;
}
.page-container .dom__video {
  position: relative;
}
.page-container .dom__video--player {
  border: none;
  width: 100%;
  height: 100%;
}
.page-container .dom__slideshow {
  position: relative;
  padding-bottom: 2rem;
}
.page-container .dom__slideshow .slider-wrapper .section {
  margin-top: 2rem;
}
.page-container .dom__slideshow .section {
  min-height: 8rem;
}
.page-container .dom__slideshow--info {
  text-align: center;
  font-size: 1.6rem;
  padding: 0.8rem;
}
.page-container .dom__slideshow--no-content {
  padding: 2.4rem;
  text-align: center;
  font-size: 2.4rem;
  font-weight: 600;
}
.page-container .dom__slideshow--no-content .desc {
  font-size: 1.4rem;
  max-width: 60rem;
  font-weight: 400;
  margin: 0 auto;
  text-align: center;
}
.page-container .dom__slideshow.no-spacing-on-sections .slider-wrapper {
  padding-top: 0;
}
.page-container .dom__slideshow.no-spacing-on-sections .section {
  min-height: 8rem;
}
.page-container .dom__text {
  width: 100%;
  display: block;
  text-align: left;
  word-break: break-word;
  font-size: 1.6rem;
  position: relative;
  padding-bottom: 1.2rem;
}
.page-container .dom__link {
  position: relative;
  color: #29b9ff;
}
.page-container .dom__link:hover {
  -webkit-filter: brightness(115%);
  -ms-filter: brightness(115%);
  filter: brightness(115%);
}
.page-container .dom__link--button-type {
  padding: 1.2rem;
  cursor: pointer;
  border: none;
}
.page-container .dom__link--button-type:hover {
  -webkit-filter: brightness(100%);
  -ms-filter: brightness(100%);
  filter: brightness(100%);
}
.page-container .dom__image-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-container .dom__image-container--image {
  max-width: 100%;
  height: auto;
  max-height: 100%;
  width: auto;
}
.page-container .dom__image-container--placeholder {
  background-color: #d6d6d6;
  padding: 2.4rem;
  max-width: 20rem;
  color: #171717;
  text-align: center;
}
.page-container .dom__bullet-list {
  width: 100%;
  display: block;
  position: relative;
}
.page-container .dom__button {
  border: none;
  padding: 0.8rem 1.6rem;
  outline: none;
  display: grid;
  align-items: center;
  font-size: 1.4rem;
  grid-template-columns: 100%;
  cursor: pointer;
  background-color: #a93e73;
  color: #e7ecef;
  position: relative;
}
.page-container .dom__button:focus {
  outline: none;
  border: none;
}
.page-container .dom__button--icons {
  display: flex;
  align-items: center;
}
.page-container .dom__button--text {
  padding: 0 0.8rem;
}
.page-container .dom__button--left-icon {
  justify-content: flex-start;
}
.page-container .dom__button--right-icon {
  justify-content: flex-end;
}
.page-container .dom__button.--SECONDARY_BUTTON {
  background-color: #e0e7e9;
  color: #6a6a6a;
}
.page-container .dom__button.with-left-icon {
  grid-template-columns: 2rem auto;
}
.page-container .dom__button.with-left-icon .dom__button--text {
  padding-left: 0.8rem;
}
.page-container .dom__button.with-right-icon {
  grid-template-columns: auto 2rem;
}
.page-container .dom__button.with-right-icon .dom__button--text {
  padding-right: 0.8rem;
}
.page-container .dom__button.with-two-icons {
  grid-template-columns: 2rem auto 2rem;
}
.page-container .dom__button.with-two-icons .dom__button--text {
  padding: 0 0.8rem;
}
.page-container .dom__button:hover {
  -webkit-filter: brightness(0.9);
  -ms-filter: brightness(0.9);
  filter: brightness(0.9);
}
.page-container .dom__button:disabled {
  background-color: #bebebe !important;
  color: #171717;
  cursor: not-allowed;
  -webkit-filter: none;
  -ms-filter: none;
  filter: none;
}
.page-container .dom__quantity-hook {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.page-container .dom__quantity-hook--btn {
  background-color: #a93e73;
  color: #e7ecef;
  width: 3rem;
  height: 3rem;
  border: none;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.page-container .dom__quantity-hook--btn:focus {
  background-color: #a93e73;
}
.page-container .dom__quantity-hook--btn:hover {
  -webkit-filter: brightness(0.9);
  -ms-filter: brightness(0.9);
  filter: brightness(0.9);
}
.page-container .dom__quantity-hook--btn:disabled {
  background-color: #e0e7e9 !important;
  color: #999999;
  -webkit-filter: none;
  -ms-filter: none;
  filter: none;
  cursor: not-allowed;
  border: none;
}
.page-container .dom__quantity-hook--qty {
  width: 3rem;
  font-size: 1.6rem;
  text-align: center;
  padding: 1.2rem 0.4rem;
}
.page-container .dom__form {
  display: grid;
  color: #171717;
  align-items: center;
  background-color: #ffffff;
  gap: 2.4rem 6.4rem;
  grid-template-columns: 2fr 2fr;
  position: relative;
}
.page-container .dom__form--title {
  font-weight: 900;
  margin-bottom: 2.4rem;
}
.page-container .dom__form--element .text-input {
  min-height: 6rem;
  font-size: 1.8rem;
  font-weight: 600;
}
.page-container .dom__form--element .text-area {
  resize: none;
  font-weight: 600;
  font-size: 1.8rem;
  padding: 1.2rem;
  min-height: 25rem;
}
.page-container .dom__form--element .dom__button {
  font-size: 1.8rem;
  padding: 0.8rem 3.2rem;
  margin: 0 auto;
}
.page-container .dom__form--image {
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-container .dom__form--image .img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
.page-container .dom__contact-form {
  position: relative;
}
.page-container .dom__contact-form--left {
  padding: 4rem 2.4rem 4rem 10rem;
}
.page-container .dom__table-reserver {
  position: relative;
}
.page-container .dom__table-reserver--right {
  padding: 4rem 6.4rem 4rem 0;
}
.page-container .dom__table-reserver--desc {
  max-width: 60rem;
  text-align: left;
  font-size: 1.6rem;
  padding-bottom: 2.4rem;
}
.page-container .dom__gallery {
  padding: 2.4rem 6.4rem;
  position: relative;
}
.page-container .dom__gallery--images {
  max-width: 85%;
  margin: 0 auto;
}
.page-container .dom__gallery--images .img-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-container .dom__gallery--show-more {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1.6rem;
}
.page-container .dom__gallery .grid-system {
  display: grid;
  row-gap: 1.2rem;
  column-gap: 1.2rem;
}
.page-container .dom__gallery .grid-2 {
  grid-template-columns: repeat(2, 1fr);
}
.page-container .dom__gallery .grid-3 {
  grid-template-columns: repeat(3, 1fr);
}
.page-container .dom__gallery .grid-4 {
  max-width: 90%;
  column-gap: 4rem;
  grid-template-columns: repeat(4, 1fr);
}
.page-container .dom__gallery .grid-5 {
  max-width: 95%;
  row-gap: 3.2rem;
  column-gap: 3.2rem;
  grid-template-columns: repeat(5, 1fr);
}
.page-container .dom__image-viewer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  z-index: 10000;
  padding: 0 2.4rem;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.9);
  grid-template-columns: 4rem auto 4rem;
  grid-template-rows: 8rem auto;
  -webkit-box-shadow: 2px 2px 12px 4px rgba(255, 255, 255, 0.2);
  -moz-box-shadow: 2px 2px 12px 4px rgba(255, 255, 255, 0.2);
  box-shadow: 2px 2px 12px 4px rgba(255, 255, 255, 0.2);
}
.page-container .dom__image-viewer--heading {
  grid-area: 1/1/2/4;
  display: flex;
  justify-content: flex-end;
}
.page-container .dom__image-viewer--heading--close {
  margin-top: 4rem;
  background-color: #ffffff;
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #171717;
  cursor: pointer;
  grid-area: 1/3/2/4;
}
.page-container .dom__image-viewer--left, .page-container .dom__image-viewer--right {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ffffff;
  height: 90%;
}
.page-container .dom__image-viewer--left:hover, .page-container .dom__image-viewer--right:hover {
  background-color: #ffffff;
  color: #6a6a6a;
}
.page-container .dom__image-viewer--container {
  display: flex;
  overflow: auto;
  align-items: center;
  justify-content: center;
  height: 90%;
}
.page-container .dom__map {
  position: relative;
}
.page-container .dom__map-content {
  display: grid;
  align-items: stretch;
  padding: 2.4rem 4rem;
  grid-template-columns: 40rem auto;
  margin: 0 auto;
  max-width: 120rem;
}
.page-container .dom__map-content.single-grid {
  grid-template-columns: 1fr;
  max-width: 100%;
  margin: 0;
  padding: 0;
}
.page-container .dom__map-content.single-grid .dom__map--texts {
  display: none;
}
.page-container .dom__map-content.single-grid .dom__map--container {
  max-width: 100%;
}
.page-container .dom__map--texts {
  padding-right: 4rem;
  display: flex;
  align-self: center;
  flex-direction: column;
}
.page-container .dom__map--texts--title {
  padding-bottom: 2.4rem;
  font-size: 4.2rem;
  font-weight: 900;
}
.page-container .dom__map--texts--block {
  display: block;
  padding-bottom: 1.6rem;
}
.page-container .dom__map--texts--block .label {
  display: block;
  font-size: 2.8rem;
  color: #ff781d;
}
.page-container .dom__map--texts--block .value {
  font-size: 2rem;
  display: block;
  word-break: break-all;
}
.page-container .dom__map--container {
  min-height: 70rem;
  max-width: 70rem;
  max-height: 70rem;
}
.page-container .dom__map--container--map-instance {
  width: 100%;
  height: 100%;
}
.page-container .dom__app-store {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.page-container .dom__app-store--icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-container .dom__app-store--icon:first-of-type {
  margin-right: 1.2rem;
}
.page-container .dom__app-store--icon .image {
  max-height: 6rem;
  width: auto;
}
.page-container .dom__app-store--icon .svg {
  width: auto;
}
.page-container.show-white-border {
  border: 10px solid white;
}
.page-container.page-xs {
  padding-bottom: 88.2rem;
  max-width: 340px;
}
.page-container.page-xs .dom__contact-form--right {
  grid-area: 1/1/2/2;
}
.page-container.page-xs .dom__contact-form--left {
  padding: 1.6rem 0;
  grid-area: 2/1/3/2;
}
.page-container.page-xs .dom__table-reserver--desc {
  text-align: left;
  font-size: 1.2rem;
  padding: 0 0.8rem 1.2rem;
}
.page-container.page-xs .dom__table-reserver--right {
  padding-right: 0;
}
.page-container.page-xs .dom__form {
  grid-template-columns: 1fr;
  padding: 2.4rem 0 0;
  gap: 1.2rem;
}
.page-container.page-xs .dom__form--title {
  font-size: 2.4rem;
  margin-bottom: 0.8rem;
  text-align: center;
}
.page-container.page-xs .dom__form--element {
  padding: 0 0.8rem;
}
.page-container.page-xs .dom__form--element .text-input,
.page-container.page-xs .dom__form--element .text-area {
  font-size: 1.4rem;
  font-weight: 400;
  min-height: 3.5rem;
}
.page-container.page-xs .dom__form--element .text-area {
  min-height: 8rem;
}
.page-container.page-xs .dom__form--element .dom__button {
  padding: 0.8rem 1.6rem;
  font-size: 1.6rem;
}
.page-container.page-xs .dom__gallery {
  padding: 1.2rem 1.6rem;
}
.page-container.page-xs .dom__gallery--images {
  column-gap: 0.4rem;
  max-width: 100%;
}
.page-container.page-xs .dom__gallery .grid-4 {
  grid-template-columns: repeat(2, 1fr);
}
.page-container.page-xs .dom__gallery .grid-5,
.page-container.page-xs .dom__gallery .grid-3 {
  grid-template-columns: 1fr;
}
.page-container.page-xs .dom__image-viewer {
  padding: 0 0.4rem;
}
.page-container.page-xs .dom__map-content {
  grid-template-columns: 1fr;
  padding: 2.4rem 0;
}
.page-container.page-xs .dom__map--texts {
  padding: 0 1.6rem 1.6rem;
}
.page-container.page-xs .dom__map--texts--title {
  font-size: 3.2rem;
  text-align: center;
}
.page-container.page-xs .dom__map--texts--block {
  text-align: center;
}
.page-container.page-xs .dom__map--texts--block .label {
  font-size: 2rem;
}
.page-container.page-xs .dom__map--texts--block .value {
  font-size: 1.6rem;
}
.page-container.page-xs .dom__map--container {
  min-height: 32rem;
}
.page-container.page-xs .dom__app-store {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.page-container.page-xs .dom__app-store--icon:first-of-type {
  margin-right: 0;
}
.page-container.page-sm {
  padding-bottom: 49.7rem;
  max-width: 768px;
}
.page-container.page-sm .dom__form {
  gap: 1.2rem;
}
.page-container.page-sm .dom__form--title {
  font-size: 2.4rem;
  margin-bottom: 0.8rem;
}
.page-container.page-sm .dom__form--element .text-input,
.page-container.page-sm .dom__form--element .text-area {
  font-size: 1.4rem;
  font-weight: 400;
  min-height: 3.5rem;
}
.page-container.page-sm .dom__form--element .text-area {
  min-height: 8rem;
}
.page-container.page-sm .dom__form--element .dom__button {
  padding: 0.8rem 1.6rem;
  font-size: 1.6rem;
}
.page-container.page-sm .dom__table-reserver--desc {
  max-width: 100%;
  font-size: 1.4rem;
}
.page-container.page-sm .dom__table-reserver--right {
  padding-right: 1.2rem;
}
.page-container.page-sm .dom__contact-form--left {
  padding-left: 1.2rem;
  padding-right: 0;
}
.page-container.page-sm .dom__gallery--images {
  max-width: 100%;
}
.page-container.page-sm .dom__map-content {
  grid-template-columns: 35rem auto;
}
.page-container.page-sm .dom__map-content.single-grid {
  grid-template-columns: 1fr;
}
.page-container.page-sm .dom__map--texts--title {
  font-size: 3.2rem;
}
.page-container.page-sm .dom__map--texts--block .label {
  font-size: 2rem;
}
.page-container.page-sm .dom__map--texts--block .value {
  font-size: 1.6rem;
}
.page-container.page-sm .dom__map--container {
  min-height: 35rem;
}
.page-container.page-md {
  padding-bottom: 48.7rem;
  max-width: 991px;
}
.page-container.page-md .dom__gallery--images {
  max-width: 100%;
}
.page-container.page-md .dom__map-content {
  grid-template-columns: 40rem auto;
}
.page-container.page-md .dom__map-content.single-grid {
  grid-template-columns: 1fr;
}
.page-container.page-md .dom__map--container {
  min-height: 40rem;
}
.page-container.page-lg {
  padding-bottom: 47.7rem;
  max-width: 1200px;
}
.page-container.page-lg .dom__gallery--images {
  max-width: 90%;
}
.page-container.page-lg .dom__map-content {
  grid-template-columns: 50rem auto;
}
.page-container.page-lg .dom__map-content.single-grid {
  grid-template-columns: 1fr;
}
.page-container.page-lg .dom__map--container {
  min-height: 50rem;
}
.page-container.page-open-menu {
  left: 150px;
}

.no-page-elements-container {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -moz-user-drag: none;
  -ms-user-drag: none;
  user-select: none;
  padding-top: 6.4rem;
}
.no-page-elements-container .no-page-elements-info {
  margin: 0 auto 0 auto;
  padding: 2.4rem;
  max-width: 500px;
  text-align: center;
  background-color: #e0e7e9;
  line-height: 2rem;
  font-size: 1.4rem;
  color: #171717;
}
.no-page-elements-container .no-page-elements-info .svg {
  margin-top: 1.2rem;
  opacity: 0.5;
}

.overlay {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  border: 1px dashed #d529ff;
  pointer-events: none;
  background: none !important;
}
.overlay__controls {
  display: flex;
  align-items: center;
  pointer-events: none;
  background: none !important;
  font-size: 1.6rem;
}
.overlay__controls .svg {
  cursor: pointer;
  padding: 0.2rem;
  background-color: #e166ff;
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 5px;
  -moz-border-radius-bottomleft: 5px;
  -moz-border-radius-topleft: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  color: #ffffff;
  pointer-events: all;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.2);
  margin-right: 0.4rem;
}
.overlay__controls .svg:last-of-type {
  margin-right: 0;
}
.overlay__controls .svg:hover {
  background-color: #d529ff;
  color: #ffffff;
}
.overlay__controls .danger-svg {
  background-color: #e63753;
}
.overlay__controls .danger-svg:hover {
  background-color: #ec647a;
}
.overlay.z-index-0 {
  z-index: 0;
}
.overlay.z-index-1 {
  z-index: 1;
}
.overlay.overlay-align-left .overlay__controls {
  justify-content: flex-start;
}
.overlay.overlay-align-center .overlay__controls {
  justify-content: center;
}
.overlay.overlay-align-right .overlay__controls {
  justify-content: flex-end;
}
.overlay.section-overlay .overlay__controls {
  margin-top: -20px;
}
.overlay.section-overlay .overlay__controls .svg {
  -webkit-border-top-right-radius: 5px;
  -webkit-border-bottom-right-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-top-left-radius: 5px;
  -moz-border-radius-topright: 5px;
  -moz-border-radius-bottomright: 0;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-topleft: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 5px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-box-shadow: 0 -2px 3px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 -2px 3px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 -2px 3px 0 rgba(0, 0, 0, 0.2);
}
.overlay.slideshow-column-overlay {
  padding-left: 2.4rem;
}

.dark-theme {
  background-color: #171717;
  color: #e7ecef;
}
.dark-theme .checkbox-container .check-mark {
  border-color: #ffffff;
}
.dark-theme .section {
  color: #e7ecef;
}
.dark-theme .column {
  color: #e7ecef;
}
.dark-theme .dom__header .h {
  color: #e7ecef;
}
.dark-theme .dom__text {
  color: #e7ecef;
}
.dark-theme .dom__link:hover {
  -webkit-filter: brightness(115%);
  -ms-filter: brightness(115%);
  filter: brightness(115%);
}
.dark-theme .dom__link--button-type {
  background-color: #333333;
  color: #e7ecef;
}
.dark-theme .dom__bullet-list {
  color: #e7ecef;
}
.dark-theme .dom__form {
  background-color: #171717;
  color: #e7ecef;
}
.dark-theme .dom__form .input-label {
  color: #e7ecef;
}
.dark-theme .dom__form .text-input,
.dark-theme .dom__form .text-area {
  background-color: #585858;
  color: #e7ecef;
}
.dark-theme .products {
  background-color: #393939;
}
.dark-theme .products__search {
  background-color: #333333;
}

.products-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 6.4rem 0;
  position: relative;
  min-height: 50rem;
}

.products {
  max-width: 90rem;
  background-color: #fbfbfb;
  -webkit-box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  position: relative;
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
  display: grid;
}
.products__search {
  display: flex;
  align-items: center;
  justify-content: stretch;
  padding: 2.4rem;
  background-color: #ffffff;
  -webkit-box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
}
.products__search--menu {
  color: #a93e73;
  font-weight: 600;
  font-size: 1.8rem;
  padding-right: 1.6rem;
}
.products__search--filter {
  width: 100%;
  margin-bottom: 0 !important;
}
.products__search--filter .text-input {
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  border-radius: 2rem;
  background-color: #e0e7e9 !important;
}
.products__heading {
  padding: 1.2rem 2.4rem;
}
.products__heading-container {
  border-bottom: 4px solid #ff781d;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding-bottom: 0.4rem;
}
.products__heading--name {
  align-self: center;
}
.products__info-text {
  display: block;
  text-align: center;
  padding: 2.4rem 1.2rem;
}
.products__categories .category-row {
  display: grid;
  align-items: center;
  grid-template-columns: auto 3rem;
  padding: 0.4rem 0;
}
.products__categories .category-row__name {
  position: relative;
  font-size: 1.4rem;
  font-weight: 500;
  word-break: break-all;
}
.products__categories .category-row__control {
  display: flex;
  justify-content: center;
  align-items: center;
}
.products__categories .category-row__sub-categories {
  grid-area: 2/1/3/3;
  padding-left: 1.2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: stretch;
}
.products__categories .category-row__sub-categories .sub-category {
  width: 100%;
}
.products__categories .category-row__sub-categories .category-row__name {
  font-style: italic;
  font-weight: 400;
}
.products__categories .category-with-child {
  cursor: pointer;
}
.products__categories .end-category {
  cursor: pointer;
  -webkit-transition: color 200ms linear;
  -moz-transition: color 200ms linear;
  -ms-transition: color 200ms linear;
  -o-transition: color 200ms linear;
  transition: color 200ms linear;
}
.products__categories .end-category:hover {
  -webkit-filter: brightness(0.8);
  -ms-filter: brightness(0.8);
  filter: brightness(0.8);
}
.products__categories .no-hover-filter {
  -webkit-filter: none !important;
  -ms-filter: none !important;
  filter: none !important;
}
.products__categories.default-categories {
  padding: 2.4rem 0.4rem 2.4rem 2.4rem;
  border-right: 1px solid #e7ecef;
}
.products__categories.tile-categories {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding: 2.4rem;
  gap: 1.2rem;
}
.products__tile {
  width: 100%;
  cursor: pointer;
  padding: 0.4rem;
  -webkit-transition: transform 200ms linear;
  -moz-transition: transform 200ms linear;
  -ms-transition: transform 200ms linear;
  -o-transition: transform 200ms linear;
  transition: transform 200ms linear;
  -webkit-box-shadow: -5px -5px 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -5px -5px 10px rgba(0, 0, 0, 0.1);
  box-shadow: -5px -5px 10px rgba(0, 0, 0, 0.1);
}
.products__tile--name {
  padding: 0.8rem 1.2rem 0.4rem;
  font-weight: 500;
  word-break: break-all;
  text-align: center;
}
.products__tile--image {
  width: 100%;
  height: 14rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #e0e7e9;
}
.products__tile:hover {
  transform: scale(1.03);
  -webkit-transform: scale(1.03);
  -moz-transform: scale(1.03);
  -ms-transform: scale(1.03);
  -o-transform: scale(1.03);
  -webkit-box-shadow: -1px -1px 5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: -1px -1px 5px rgba(0, 0, 0, 0.2);
  box-shadow: -1px -1px 5px rgba(0, 0, 0, 0.2);
}
.products__product-map--heading {
  border-bottom: 4px solid #ff781d;
  margin-bottom: 1.2rem;
}
.products__product-map--heading--name {
  font-size: 2rem;
  font-weight: 500;
}
.products__product-map.--block-mode {
  padding: 1.6rem;
}
.products__product-map.--tile-mode {
  padding: 0.4rem 1.6rem 1.6rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 0.8rem;
}
.products.product-layout--default_layout {
  max-width: 90rem;
  grid-template-columns: 1fr 2fr;
}
.products.product-layout--default_layout .products__search {
  grid-area: 1/1/2/3;
}
.products.product-layout--default_layout .products__category {
  grid-area: 2/1/3/2;
}
.products.product-layout--default_layout .products__product-map {
  grid-area: 2/2/3/3;
}
.products.product-layout--tile_layout {
  grid-template-columns: 1fr;
}
.products.product-layout--tile_layout .products__search {
  grid-area: 1/1/2/2;
}
.products.product-layout--tile_layout .products__heading {
  grid-area: 2/1/3/2;
}
.products.product-layout--tile_layout .products__category {
  grid-area: 3/1/4/2;
}
.products.product-layout--tile_layout .products__product-map {
  grid-area: 4/1/5/2;
}
.products.products-sm .tile-categories {
  grid-template-columns: repeat(4, 1fr);
}
.products.products-sm .--tile-mode {
  grid-template-columns: repeat(4, 1fr);
}
.products.products-xs.product-layout--default_layout {
  grid-template-columns: 1fr;
}
.products.products-xs.product-layout--default_layout .products__search {
  grid-area: 1/1/2/2;
}
.products.products-xs.product-layout--default_layout .products__category {
  grid-area: 2/1/3/2;
}
.products.products-xs.product-layout--default_layout .products__product-map {
  grid-area: 3/1/4/2;
}
.products.products-xs .tile-categories {
  grid-template-columns: 1fr;
}
.products.products-xs .--tile-mode {
  grid-template-columns: 1fr;
}

.rounded-corners .products {
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
}
.rounded-corners .products__search, .rounded-corners .products__heading {
  -webkit-border-top-right-radius: 1rem;
  -webkit-border-bottom-right-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-top-left-radius: 1rem;
  -moz-border-radius-topright: 1rem;
  -moz-border-radius-bottomright: 0;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-topleft: 1rem;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 1rem;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.rounded-corners .products__tile {
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
}
.rounded-corners .products__tile--image {
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
}

.dark-theme .products {
  background-color: #393939;
}
.dark-theme .products__search {
  background-color: #333333;
}
.dark-theme .products__tile {
  background-color: #171717;
}

.products__product-map .product {
  display: grid;
  grid-template-columns: auto 16rem;
  border-bottom: 3px solid #e7ecef;
  margin-bottom: 0.8rem;
}
.products__product-map .product__details {
  display: block;
  padding-bottom: 1.2rem;
}
.products__product-map .product__details--name {
  padding-bottom: 0.8rem;
}
.products__product-map .product__details--description {
  padding-bottom: 1.2rem;
  font-style: italic;
}
.products__product-map .product__details--price {
  color: #ff781d;
  padding-bottom: 0.8rem;
}
.products__product-map .product__details--cart-button {
  background-color: #ff781d;
  font-weight: 600;
  color: #ffffff;
  border: none;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  border-radius: 2rem;
  padding: 0.8rem 1.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 12rem;
  font-size: 1.2rem;
  cursor: pointer;
}
.products__product-map .product__details--cart-button:hover {
  -webkit-filter: invert(0.1);
  -ms-filter: invert(0.1);
  filter: invert(0.1);
}
.products__product-map .product__image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.8rem 0.8rem;
  position: relative;
}
.products__product-map .product__image-container--image {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.rounded-corners .product__image-container--image {
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
}

.categorized-container {
  padding: 0 0 6.4rem 0;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.categorized-container .overlay {
  z-index: 10;
}
.categorized-container .topbar {
  z-index: 1;
  width: 100%;
  color: #171717;
  background-color: #ffffff;
  border-top: 2px solid #d6d6d6;
  border-bottom: 2px solid #d6d6d6;
}
.categorized-container .topbar .hash-link {
  align-self: center;
  position: relative;
  padding: 0.8rem 0.8rem;
  white-space: nowrap;
  font-size: 1.4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  cursor: pointer;
}
.categorized-container .topbar .hash-link.active-hash {
  font-weight: 600;
}
.categorized-container .topbar .hash-link .sub-categories {
  display: flex;
  align-items: center;
}
.categorized-container .topbar .hash-link .sub-categories .hash-link {
  font-weight: 400;
  padding: 0 0.8rem;
}
.categorized-container .topbar .hash-link .sub-categories .hash-link.active-hash {
  font-weight: 600;
}
.categorized-container .topbar__container {
  max-width: 120rem;
  margin: 0 auto;
  display: grid;
  align-items: center;
  padding-left: 2.8rem;
  grid-template-columns: 1fr 4rem;
}
.categorized-container .topbar__container--control {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}
.categorized-container .topbar__container--categories {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  max-width: calc(120rem - 4rem);
  overflow-x: auto;
  overflow-y: visible;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.categorized-container .topbar__container--categories .hash-link {
  padding: 1.6rem 1.2rem;
}
.categorized-container .topbar__container--categories::-webkit-scrollbar {
  display: none;
}
.categorized-container .topbar__category-menu {
  position: absolute;
  top: 2.5rem;
  left: 1rem;
  background-color: #ffffff;
  color: #171717;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  z-index: 2;
  width: 30rem;
  min-height: 30rem;
  max-height: 30rem;
  border: 3px solid #e7ecef;
}
.categorized-container .topbar__category-menu .content {
  overflow-y: auto;
}
.categorized-container .topbar__category-menu .hash-link {
  width: 100%;
  flex-wrap: wrap;
  text-align: left;
}
.categorized-container .topbar__category-menu .hash-link .sub-categories {
  width: 100%;
  flex-direction: column;
}
.categorized-container .topbar__category-menu .hash-link .sub-categories .hash-link {
  display: grid;
  padding: 0.8rem;
  white-space: pre-wrap;
  grid-template-columns: 1.6rem auto;
}
.categorized-container .topbar__category-menu .hash-link:hover {
  background-color: #d6d6d6;
}
.categorized-container .topbar.topbar-dark {
  color: #e7ecef;
  background-color: #171717;
  border-top: 2px solid #393939;
  border-bottom: 2px solid #393939;
}
.categorized-container .topbar.topbar-dark .topbar__category-menu {
  border-color: #585858;
  background-color: #333333;
  color: #e7ecef;
}
.categorized-container .topbar.topbar-dark .topbar__category-menu .hash-link:hover,
.categorized-container .topbar.topbar-dark .topbar__category-menu .hash-link:hover {
  background-color: #585858;
}
.categorized-container .products-with-cart {
  display: grid;
  max-width: 124.8rem;
  column-gap: 2.4rem;
  align-items: flex-start;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  margin: 6.4rem auto 0 auto;
  grid-template-columns: 4fr 2fr;
}
.categorized-container .products-with-cart .container,
.categorized-container .products-with-cart .cart-container {
  width: 100%;
  padding: 2.4rem;
  background-color: #ffffff;
}
.categorized-container .products-with-cart .container {
  display: flex;
  flex-direction: column;
}
.categorized-container .products-with-cart .container .category-products {
  display: grid;
  gap: 0.8rem;
  grid-template-columns: repeat(2, 1fr);
}
.categorized-container .products-with-cart .container .category {
  display: block;
  margin-bottom: 2.4rem;
}
.categorized-container .products-with-cart .container .category__name {
  margin-bottom: 0.4rem;
}
.categorized-container .products-with-cart .container .category__description {
  font-weight: 400;
  font-style: italic;
  font-size: 1.4rem;
  color: #999999;
  margin-bottom: 1.2rem;
}
.categorized-container .products-with-cart .container .sub-category {
  margin-bottom: 1.2rem;
}
.categorized-container .products-with-cart .container .sub-category__name {
  font-size: 1.6rem;
  padding-bottom: 0.8rem;
}
.categorized-container .products-with-cart .container .sub-category__description {
  padding-bottom: 1.2rem;
  color: #999999;
}
.categorized-container .products-with-cart .container .product {
  display: grid;
  cursor: pointer;
  padding: 0.8rem;
  border: 1px solid #e7ecef;
  grid-template-columns: auto 8.7rem;
  grid-template-rows: 2.6rem 3.5rem 2.6rem;
  -webkit-transition: background-color 300ms linear;
  -moz-transition: background-color 300ms linear;
  -ms-transition: background-color 300ms linear;
  -o-transition: background-color 300ms linear;
  transition: background-color 300ms linear;
}
.categorized-container .products-with-cart .container .product:hover {
  background-color: #e7ecef;
}
.categorized-container .products-with-cart .container .product__name {
  grid-area: 1/1/2/2;
  font-size: 1.6rem;
  padding-right: 0.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.categorized-container .products-with-cart .container .product__description {
  padding-right: 0.8rem;
  color: #999999;
  font-size: 1.2rem;
  grid-area: 2/1/3/2;
}
.categorized-container .products-with-cart .container .product__price {
  grid-area: 3/1/4/2;
  font-weight: 500;
  font-size: 1.6rem;
}
.categorized-container .products-with-cart .container .product__image {
  grid-area: 1/2/4/3;
  max-width: 8.7rem;
  max-height: 8.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.categorized-container .products-with-cart .container .product.--trim-description .product__description {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.categorized-container .products-with-cart .cart-container {
  margin-bottom: 2.4rem;
}
.categorized-container .products-with-cart .cart {
  width: 100%;
}
.categorized-container .products-with-cart .cart__items {
  border-bottom: 1px solid #e7ecef;
  padding: 1.2rem 0;
  display: block;
}
.categorized-container .products-with-cart .cart__items .cart-item {
  display: grid;
  padding-left: 0;
  padding-bottom: 0.8rem;
  margin-bottom: 0.8rem;
  grid-template-columns: 3rem auto 8rem 1.6rem;
  border-bottom: 1px solid #e7ecef;
}
.categorized-container .products-with-cart .cart__items .cart-item:last-of-type {
  border-bottom: none;
}
.categorized-container .products-with-cart .cart__items .cart-item__count {
  text-align: center;
  background-color: #e7ecef;
  color: #171717;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.categorized-container .products-with-cart .cart__items .cart-item__name {
  font-size: 1.4rem;
}
.categorized-container .products-with-cart .cart__items .cart-item__delete {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: #fb3350;
  cursor: default;
  border: none;
  padding: 0;
}
.categorized-container .products-with-cart .cart__items .cart-item__delete .trashcan {
  cursor: pointer;
}
.categorized-container .products-with-cart .cart__items .cart-item__delete .gift {
  color: #7de394;
}
.categorized-container .products-with-cart .cart__footer {
  padding-top: 1.2rem;
}
.categorized-container .products-with-cart .cart__footer--prices {
  display: flex;
  padding-bottom: 0.4rem;
  justify-content: space-between;
}
.categorized-container .products-with-cart .cart__footer .total-price {
  font-weight: 600;
  font-size: 2rem;
  padding-top: 0.4rem;
}
.categorized-container .products-with-cart .cart__footer .dom__button {
  width: 100%;
  margin-top: 1.2rem;
}
.categorized-container .products-with-cart .cart__info {
  display: grid;
  align-items: center;
  grid-template-columns: 5rem auto;
}
.categorized-container .products-with-cart .cart__info:first-of-type {
  margin-right: 1.6rem;
}
.categorized-container .products-with-cart .cart__info--icon {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-area: 1/1/3/2;
}
.categorized-container .products-with-cart .cart__info--label {
  font-weight: 600;
  grid-area: 1/2/2/3;
}
.categorized-container .products-with-cart .cart__info--note {
  grid-area: 2/2/3/3;
}
.categorized-container .products-with-cart .cart .info-container {
  display: flex;
  justify-content: center;
}
.categorized-container .products-with-cart.rounded-corners .container,
.categorized-container .products-with-cart.rounded-corners .cart-container {
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
}
.categorized-container .products-with-cart.rounded-corners .container .product {
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
}
.categorized-container .products-with-cart.rounded-corners .container .product__image {
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
}
.categorized-container .products-with-cart.rounded-corners .container .product__image .noselect-xa-image {
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
}
.categorized-container .dark-theme-products .container,
.categorized-container .dark-theme-products .cart-container {
  background-color: #333333;
}
.categorized-container .dark-theme-products .container .category__description {
  color: #bfbfbf;
}
.categorized-container .dark-theme-products .container .product {
  border-color: #585858;
}
.categorized-container .dark-theme-products .container .product:hover {
  background-color: #171717;
}
.categorized-container .dark-theme-products .container .product__description {
  color: #bfbfbf;
}
.categorized-container .dark-theme-products .cart__items {
  border-color: #585858;
}
.categorized-container .dark-theme-products .cart__items .cart-item {
  border-color: #585858;
}
.categorized-container .dark-theme-products .cart__items .cart-item__count {
  color: #e7ecef;
  background-color: #585858;
}
.categorized-container .products-with-cart-lg,
.categorized-container .products-with-cart-md {
  grid-template-columns: 5fr 3fr;
}
.categorized-container .products-with-cart-sm,
.categorized-container .products-with-cart-xs {
  grid-template-columns: 1fr;
}
.categorized-container .products-with-cart-sm .cart,
.categorized-container .products-with-cart-xs .cart {
  margin-top: 2.4rem;
}
.categorized-container .products-with-cart-xs {
  padding: 0;
}
.categorized-container .products-with-cart-xs .container .category-products {
  grid-template-columns: 1fr;
}
.categorized-container .products-with-cart-xs .container .product {
  grid-template-rows: 3rem 2.5rem 2.6rem;
}
.categorized-container .products-with-cart-xs .container .product__name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.footer {
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: #ffffff;
  min-height: 47.7rem;
  -webkit-transition: left 300ms linear;
  -moz-transition: left 300ms linear;
  -ms-transition: left 300ms linear;
  -o-transition: left 300ms linear;
  transition: left 300ms linear;
}
.footer__top {
  width: 100%;
  position: relative;
  padding: 0 1.2rem;
}
.footer__top--content {
  display: grid;
  row-gap: 1.2rem;
  column-gap: 2.4rem;
  grid-template-columns: repeat(5, 1fr);
  padding: 4.8rem 6.4rem 4.8rem;
}
.footer__top--logo {
  display: flex;
  max-width: 20rem;
  max-height: 20rem;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.footer__top--logo--image {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
}
.footer__top--block {
  display: flex;
  flex-direction: column;
}
.footer__top--block--icon {
  text-align: center;
  border-bottom: 1px solid #e7ecef;
  padding-bottom: 0.4rem;
}
.footer__top--block--data {
  padding-top: 1.2rem;
  text-align: center;
}
.footer__top--block--data .address,
.footer__top--block--data .city {
  text-transform: capitalize;
}
.footer__top--block--data .city {
  padding-bottom: 0.2rem;
}
.footer__top--block--data .open-hours--item {
  display: flex;
  padding-bottom: 0.4rem;
  justify-content: space-between;
}
.footer__top--block--data .open-hours--item:last-of-type {
  padding-bottom: 0;
}
.footer__top.--NO_OPEN_HOUR .footer__top--content {
  grid-template-columns: repeat(4, 1fr);
}
.footer__mid {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.footer__mid--social {
  width: 100%;
  display: grid;
  row-gap: 0.8rem;
  padding: 1.2rem 0;
}
.footer__mid--social .social {
  display: flex;
  font-size: 1.6rem;
  align-items: center;
  justify-content: center;
}
.footer__mid--social .social__icon {
  margin-right: 0.4rem;
}
.footer__bottom {
  display: flex;
  position: relative;
  align-items: center;
  row-gap: 0.8rem;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 2.4rem 1.6rem 2.4rem;
}
.footer__bottom--payment-methods {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.footer__bottom--payment-methods .payment-method-image {
  max-height: 4rem;
  margin-right: 0.8rem;
  width: auto;
}
.footer__bottom--logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer__bottom--logo--onemin {
  margin-left: 0.8rem;
  max-height: 2rem;
  width: auto;
}
.footer__bottom--policy-files {
  display: flex;
  column-gap: 1.2rem;
}
.footer__bottom--policy-files .policy-link {
  color: unset;
}

.footer-xl.footer-with-sidemenu-opened {
  left: 30rem;
}

.footer-lg .footer__top--content {
  padding-left: 2.4rem;
  padding-right: 2.4rem;
}
.footer-lg .footer__bottom {
  flex-direction: column;
  padding: 1.2rem;
}
.footer-lg .footer__bottom--payment-methods .payment-method-image {
  margin-top: 1.2rem;
  max-height: 3rem;
}
.footer-lg .footer__bottom--logo {
  margin: 1.2rem 0;
}

.footer-md .footer__top--content {
  padding: 1.6rem 1.6rem;
  grid-template-columns: 20rem 1fr 1fr;
}
.footer-md .footer__top--content .logo-block {
  grid-row: 1/3;
}
.footer-md .footer__bottom {
  flex-direction: column;
  padding: 1.2rem;
}
.footer-md .footer__bottom--payment-methods .payment-method-image {
  margin-top: 1.2rem;
  max-height: 3rem;
}
.footer-md .footer__bottom--logo {
  margin: 1.2rem 0;
}

.footer-sm .footer__top--content {
  padding: 1.6rem 1.6rem;
  grid-template-columns: 1fr 1fr;
}
.footer-sm .footer__top--content .logo-block {
  margin: 0 auto;
}
.footer-sm .footer__top--content .open-hours {
  grid-area: 1/2/3/3;
}
.footer-sm .footer__bottom {
  flex-direction: column;
  padding: 1.2rem;
}
.footer-sm .footer__bottom--payment-methods .payment-method-image {
  margin-top: 1.2rem;
  max-height: 3rem;
}
.footer-sm .footer__bottom--logo {
  margin: 1.2rem 0;
}

.footer-xs .footer__top--content {
  row-gap: 2.4rem;
  padding: 1.6rem 1.2rem;
  grid-template-columns: 1fr;
}
.footer-xs .footer__top--content .logo-block {
  margin: 0 auto;
}
.footer-xs .footer__bottom {
  flex-direction: column;
  padding: 0.4rem 1.2rem 1.2rem;
}
.footer-xs .footer__bottom--payment-methods .payment-method-image {
  margin-top: 1.2rem;
  max-height: 3rem;
}
.footer-xs .footer__bottom--logo {
  margin: 1.2rem 0;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.dark-theme .footer {
  background-color: #333333;
  color: #e7ecef;
}
.dark-theme .footer__top {
  color: #999999;
}
.dark-theme .footer__top--block--icon {
  border-bottom-color: #585858;
}

.widget {
  position: absolute;
}

.navbar {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  min-height: 92px;
  background-color: #e7ecef;
  z-index: 100;
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  font-family: inherit;
}
@supports not (position: sticky) {
  .navbar {
    position: fixed;
  }
}
.navbar__top {
  display: grid;
  height: 4.2rem;
  overflow: hidden;
  align-items: center;
  position: relative;
  grid-template-columns: 1fr 3fr;
  padding: 0 2.4rem 0;
}
.navbar__top__languages {
  font-size: 1.6rem;
  position: relative;
}
.navbar__top__languages--button {
  padding: 0.4rem 0.8rem;
  font-size: 1.6rem;
  border: none;
  background-color: transparent;
  margin: 0;
  color: #999999;
  cursor: pointer;
}
.navbar__top__languages--button.active-language {
  cursor: default;
  font-weight: 600;
  color: #171717;
}
.navbar__top__languages--button:hover {
  -webkit-filter: invert(0.1);
  -ms-filter: invert(0.1);
  filter: invert(0.1);
}
.navbar__top__languages--separator {
  color: #999999;
}
.navbar__top__meta {
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
}
.navbar__top__meta--button {
  align-self: center;
  margin-left: 0.4rem;
  color: #6a6a6a;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  min-width: 12rem;
  cursor: pointer;
  border: none;
  background-color: transparent;
  position: relative;
  font-family: inherit;
}
.navbar__top__meta--button .text {
  align-self: center;
  padding: 0.2rem 0 0.2rem 0.4rem;
}
.navbar__top__meta--button .icon {
  align-self: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.4rem;
}
.navbar__top__meta--button:first-of-type {
  font-weight: 600;
}
.navbar__top__meta--button:hover {
  -webkit-filter: invert(0.1);
  -ms-filter: invert(0.1);
  filter: invert(0.1);
}
.navbar__top__meta--info {
  height: 2.6rem;
  align-self: center;
  display: inline-flex;
  align-items: stretch;
  justify-content: flex-start;
  color: #7de394;
  position: relative;
}
.navbar__top__meta--info--status {
  align-self: center;
  margin-left: 1.2rem;
  min-width: 0.8rem;
  min-height: 0.8rem;
  width: 0.8rem;
  height: 0.8rem;
  display: inline-block;
  background-color: #7de394;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  font-family: inherit;
}
.navbar__top__meta--info--text {
  font-family: inherit;
  align-self: center;
  padding-left: 0.4rem;
  font-weight: 600;
  font-size: 1.2rem;
}
.navbar__top__meta--info.--closed {
  color: #fb3350;
}
.navbar__top__meta--info.--closed .widget__navbar--top--meta--info--status {
  background-color: #fb3350;
}
.navbar__top__meta--toggle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding-left: 1.2rem;
  display: none;
  cursor: pointer;
  height: 2.6rem;
}
.navbar__top__meta--toggle:hover {
  -webkit-filter: invert(0.2);
  -ms-filter: invert(0.2);
  filter: invert(0.2);
}
.navbar__bottom {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: stretch;
  min-height: 58px;
}
.navbar__bottom__links {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 2.4rem;
  position: relative;
}
.navbar__bottom__links--element {
  padding: 1.2rem;
  position: relative;
}
.navbar__bottom__logo {
  display: flex;
  justify-content: center;
  align-items: stretch;
  position: relative;
  max-height: 5.8rem;
}
.navbar__bottom__logo--image-container {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  max-height: 5.8rem;
}
.navbar__bottom__logo--image-container--image {
  max-height: 4.5rem;
  width: auto;
}
.navbar__bottom__logo--name {
  align-self: center;
  font-size: 1.8rem;
  font-weight: 500;
}
.navbar__bottom__checkout {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2.4rem;
}
.navbar__bottom__checkout--button {
  position: relative;
  border: none;
  display: flex;
  align-items: stretch;
  background-color: #a93e73;
  color: #e7ecef;
  padding: 0.8rem 1.6rem;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  border-radius: 2rem;
  cursor: pointer;
  font-family: inherit;
}
.navbar__bottom__checkout--button--text {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
}
.navbar__bottom__checkout--button--text .price {
  padding-left: 0.4rem;
}
.navbar__bottom__checkout--button--arrow {
  padding-left: 1.6rem;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
}
.navbar__bottom__checkout--button:hover {
  -webkit-filter: invert(0.1);
  -ms-filter: invert(0.1);
  filter: invert(0.1);
}
.navbar__bottom.--left-logo-and-name .navbar__bottom__links {
  grid-area: 1/2/2/3;
}
.navbar__bottom.--left-logo-and-name .navbar__bottom__logo {
  grid-area: 1/1/2/2;
  justify-content: flex-start;
  padding-left: 2.4rem;
}
.navbar.navbar-sm .navbar__top, .navbar.navbar-xs .navbar__top {
  grid-template-columns: 1fr 1fr;
  min-height: 4.2rem;
  height: auto;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
}
.navbar.navbar-sm .navbar__top__languages--button, .navbar.navbar-xs .navbar__top__languages--button {
  padding: 0.4rem;
}
.navbar.navbar-sm .navbar__top__languages--button:first-of-type, .navbar.navbar-xs .navbar__top__languages--button:first-of-type {
  padding-left: 0;
}
.navbar.navbar-sm .navbar__top__meta--button, .navbar.navbar-xs .navbar__top__meta--button {
  display: none;
}
.navbar.navbar-sm .navbar__top__meta--toggle, .navbar.navbar-xs .navbar__top__meta--toggle {
  padding-left: 2.4rem;
  display: flex;
}
.navbar.navbar-sm .navbar__bottom, .navbar.navbar-xs .navbar__bottom {
  grid-template-columns: 1fr 16rem;
}
.navbar.navbar-sm .navbar__bottom__links, .navbar.navbar-xs .navbar__bottom__links {
  display: none;
}
.navbar.navbar-sm .navbar__bottom__logo, .navbar.navbar-xs .navbar__bottom__logo {
  padding-left: 1.2rem;
}
.navbar.navbar-sm .navbar__bottom__checkout, .navbar.navbar-xs .navbar__bottom__checkout {
  padding-right: 1.2rem;
  justify-content: flex-end;
}
.navbar.navbar-sm.navbar-opened .navbar__top, .navbar.navbar-xs.navbar-opened .navbar__top {
  grid-template-columns: 1fr;
  position: relative;
}
.navbar.navbar-sm.navbar-opened .navbar__top__meta, .navbar.navbar-xs.navbar-opened .navbar__top__meta {
  display: flex;
  padding-top: 0.4rem;
}
.navbar.navbar-sm.navbar-opened .navbar__top__meta--info, .navbar.navbar-xs.navbar-opened .navbar__top__meta--info {
  position: absolute;
  top: 0.8rem;
  right: 5.6rem;
}
.navbar.navbar-sm.navbar-opened .navbar__top__meta--button, .navbar.navbar-xs.navbar-opened .navbar__top__meta--button {
  padding: 0.8rem 0;
  display: flex;
}
.navbar.navbar-sm.navbar-opened .navbar__top__meta--toggle, .navbar.navbar-xs.navbar-opened .navbar__top__meta--toggle {
  position: absolute;
  top: 0.8rem;
  right: 1.2rem;
}
.navbar.navbar-sm.navbar-opened .navbar__bottom__links, .navbar.navbar-xs.navbar-opened .navbar__bottom__links {
  display: flex;
  flex-direction: column;
  grid-area: 2/1/3/3;
  align-items: flex-start;
}
.navbar.navbar-sm.navbar-opened .navbar__bottom__logo, .navbar.navbar-xs.navbar-opened .navbar__bottom__logo {
  grid-area: 1/1/2/2;
}
.navbar.navbar-sm.navbar-opened .navbar__bottom__checkout, .navbar.navbar-xs.navbar-opened .navbar__bottom__checkout {
  grid-area: 1/2/2/3;
}

.dark-theme .navbar {
  background-color: #333333;
  color: #e7ecef;
}
.dark-theme .navbar__top__languages--button {
  color: #6a6a6a;
}
.dark-theme .navbar__top__languages--button.active-language {
  color: #e7ecef;
}
.dark-theme .navbar__top__meta--button {
  color: #999999;
}

.registration__block {
  display: grid;
  column-gap: 0.8rem;
  grid-template-columns: repeat(4, 1fr);
}
.registration__title {
  grid-column: 1/5;
  padding-bottom: 1.2rem;
  padding-top: 2.4rem;
}
.registration__title.no-space {
  padding-top: 0;
}
.registration .email,
.registration .phone_number,
.registration .password,
.registration .city,
.registration .street {
  grid-column: 1/5;
}
.registration .password .text-input-unit {
  top: 2.2rem;
  right: 0;
  width: 3rem;
  height: 2.9rem;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: #e7ecef;
}
.registration .password .text-input-unit:hover {
  color: #ffffff;
  background-color: #a93e73;
}
.registration .first_name {
  grid-column: 1/3;
}
.registration .last_name {
  grid-column: 3/5;
}

.cart .input-label {
  font-size: 1.6rem !important;
}
.cart .checkbox-container {
  font-size: 1.6rem;
}
.cart__header {
  color: #171717;
  text-align: center;
  padding: 3.2rem 1.6rem;
  background-color: #f7f7f7;
}
.cart__container {
  display: grid;
  grid-template-columns: 35rem auto;
  max-width: 90rem;
  margin: 0 auto;
  padding: 1.6rem;
  column-gap: 1.6rem;
}
.cart__title {
  font-size: 1.6rem;
  padding-bottom: 1.2rem;
}
.cart .cart-block {
  padding: 1.6rem;
  background-color: #ffffff;
  -webkit-box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.1);
}
.cart__registration {
  display: block;
}
.cart__registration .registration__block {
  padding: 1.6rem;
}
.cart__registration .registration__block .reg_with_order,
.cart__registration .registration__block .is_take_away {
  grid-column: 1/5;
}
.cart__registration .registration__title {
  font-size: 1.6rem;
  padding-top: 0;
}
.cart__registration .address-block {
  margin-top: 1.6rem;
}
.cart__items {
  padding: 1.6rem;
}
.cart__items--coupon {
  display: grid;
  align-items: center;
  margin-top: 0.8rem;
  padding-top: 1.2rem;
  border-top: 1px solid #e0e7e9;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 0.8rem;
}
.cart__items--coupon .wrapper {
  margin-bottom: 0;
}
.cart__items--coupon .dom__button {
  height: 4rem;
}
.cart__items--footer {
  margin-top: 0.2rem;
  background-color: #ffffff;
  padding: 1.6rem;
  border-right: 0.3rem solid;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cart__items--footer .price-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.cart__items--footer .price-container__prices {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.cart__items--footer .price-container__prices .crossed-price {
  text-decoration: line-through;
  color: #6a6a6a;
}
.cart__items--footer .price-container__prices .final-price {
  font-weight: 500;
  font-size: 2.4rem;
}
.cart__items--footer--total-price {
  color: #6a6a6a;
  padding-right: 1.6rem;
  font-size: 1.8rem;
  font-weight: 500;
}
.cart__dmca, .cart__message, .cart__delivery-at, .cart__payment-split, .cart__payment-methods {
  margin-top: 1.6rem;
}
.cart__finalize-button-container {
  margin-top: 1.6rem;
}
.cart__finalize-button-container .dom__button {
  width: 100%;
  font-size: 1.6rem;
  font-weight: 600;
}
.cart__dmca {
  display: grid;
  align-items: center;
  padding: 0 !important;
  background-color: transparent !important;
  grid-template-columns: repeat(2, 1fr);
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
.cart__dmca .link {
  color: #171717;
}
.cart__radio-row {
  display: grid;
  cursor: pointer;
  align-items: center;
  padding: 0 0.8rem;
  margin-bottom: 0.4rem;
  grid-template-columns: 3rem auto;
}
.cart__radio-row:last-of-type {
  margin-bottom: 0;
}
.cart__radio-row.no-margin-bottom {
  margin-bottom: 0;
}
.cart__radio-row:hover {
  -webkit-filter: invert(0.1);
  -ms-filter: invert(0.1);
  filter: invert(0.1);
}
.cart__radio-row.no-border {
  border-bottom: none;
}
.cart__radio-row.with-children {
  grid-template-columns: 3rem auto 20rem;
}
.cart .mock-datepicker {
  display: flex;
  color: #171717;
  min-height: 4rem;
  text-align: center;
  align-items: center;
  background-color: #f3f6f7;
  border-bottom: 1px solid #d6d6d6;
  padding: 0.4rem 1.6rem;
  justify-content: space-between;
}

.cart-sm .cart__container,
.cart-xs .cart__container {
  grid-template-columns: 1fr;
  max-width: 55rem;
}

.cart-xs .cart__container {
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.cart-xs .cart__container--right {
  padding-top: 1.6rem;
}
.cart-xs .cart__registration .registration__block {
  grid-template-columns: repeat(2, 1fr);
}
.cart-xs .cart__registration .registration__block .city,
.cart-xs .cart__registration .registration__block .email,
.cart-xs .cart__registration .registration__block .street,
.cart-xs .cart__registration .registration__block .is_take_away,
.cart-xs .cart__registration .registration__block .phone_number,
.cart-xs .cart__registration .registration__block .reg_with_order,
.cart-xs .cart__registration .registration__block .registration__title {
  grid-column: 1/3;
}
.cart-xs .cart__registration .registration__block .first_name,
.cart-xs .cart__registration .registration__block .premise_number,
.cart-xs .cart__registration .registration__block .door_number {
  grid-column: 1/2;
}
.cart-xs .cart__registration .registration__block .last_name,
.cart-xs .cart__registration .registration__block .floor,
.cart-xs .cart__registration .registration__block .doorbell {
  grid-column: 2/3;
}
.cart-xs .cart__items--footer {
  align-items: stretch;
  flex-direction: column;
}
.cart-xs .cart__items--footer .price-container {
  padding-top: 1.6rem;
  justify-content: space-between;
}
.cart-xs .cart__items--coupon {
  align-items: stretch;
  grid-template-columns: auto 14rem;
}
.cart-xs .cart__items--coupon .wrapper .text-input {
  height: 100%;
}
.cart-xs .cart__dmca {
  grid-template-columns: 1fr;
  row-gap: 0.8rem;
}
.cart-xs .cart__radio-row--child {
  padding-top: 0.4rem;
  grid-area: 2/1/3/3;
}
.cart-xs .cart__radio-row.with-children {
  padding-top: 0.8rem;
  grid-template-columns: 3rem auto;
}

.rounded-corners .text-input,
.rounded-corners .select-input__search,
.rounded-corners .cart-block,
.rounded-corners .mock-datepicker,
.rounded-corners .dom__button,
.rounded-corners .with-right-icon {
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
}

.dark-theme .cart__header,
.dark-theme-iframe .cart__header,
.dark-theme-app .cart__header {
  background-color: #393939;
  color: #e7ecef;
}
.dark-theme .cart__container .registration__block,
.dark-theme-iframe .cart__container .registration__block,
.dark-theme-app .cart__container .registration__block {
  background-color: #333333;
}
.dark-theme .cart__container .registration__block .input-label,
.dark-theme .cart__container .registration__block .checkbox-container,
.dark-theme-iframe .cart__container .registration__block .input-label,
.dark-theme-iframe .cart__container .registration__block .checkbox-container,
.dark-theme-app .cart__container .registration__block .input-label,
.dark-theme-app .cart__container .registration__block .checkbox-container {
  color: #d6d6d6;
}
.dark-theme .cart .cart-block,
.dark-theme-iframe .cart .cart-block,
.dark-theme-app .cart .cart-block {
  background-color: #333333;
}
.dark-theme .cart .cart-block .cart-block,
.dark-theme-iframe .cart .cart-block .cart-block,
.dark-theme-app .cart .cart-block .cart-block {
  -webkit-box-shadow: 2px 2px 2px 0 rgba(255, 255, 255, 0.1);
  -moz-box-shadow: 2px 2px 2px 0 rgba(255, 255, 255, 0.1);
  box-shadow: 2px 2px 2px 0 rgba(255, 255, 255, 0.1);
}
.dark-theme .cart .cart-block .text-input,
.dark-theme .cart .cart-block .select-input__search,
.dark-theme-iframe .cart .cart-block .text-input,
.dark-theme-iframe .cart .cart-block .select-input__search,
.dark-theme-app .cart .cart-block .text-input,
.dark-theme-app .cart .cart-block .select-input__search {
  background-color: #585858;
  color: #e7ecef;
}
.dark-theme .cart .cart-block .checkbox-container,
.dark-theme-iframe .cart .cart-block .checkbox-container,
.dark-theme-app .cart .cart-block .checkbox-container {
  color: #e7ecef;
}
.dark-theme .cart .cart__dmca,
.dark-theme-iframe .cart .cart__dmca,
.dark-theme-app .cart .cart__dmca {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
.dark-theme .cart .cart__dmca .link,
.dark-theme-iframe .cart .cart__dmca .link,
.dark-theme-app .cart .cart__dmca .link {
  color: #999999;
}
.dark-theme .cart__message .input-label,
.dark-theme-iframe .cart__message .input-label,
.dark-theme-app .cart__message .input-label {
  color: #d6d6d6;
}
.dark-theme .cart__items--footer .crossed-price,
.dark-theme-iframe .cart__items--footer .crossed-price,
.dark-theme-app .cart__items--footer .crossed-price {
  color: #bebebe;
}
.dark-theme .cart__items--footer--total-price,
.dark-theme-iframe .cart__items--footer--total-price,
.dark-theme-app .cart__items--footer--total-price {
  color: #e7ecef;
}
.dark-theme .cart .mock-datepicker,
.dark-theme-iframe .cart .mock-datepicker,
.dark-theme-app .cart .mock-datepicker {
  background-color: #585858;
  color: #e7ecef;
}

.cart-item {
  display: grid;
  align-items: center;
  grid-template-columns: 10rem auto 8rem 3rem;
}
.cart-item__apply {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 1.2rem;
}
.cart-item__apply--button {
  width: 3rem;
  height: 3rem;
  border: none;
  display: flex;
  cursor: pointer;
  color: #e7ecef;
  align-items: center;
  justify-content: center;
  background-color: #6c6c6c;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.cart-item__apply--button:hover {
  -webkit-filter: invert(0.1);
  -ms-filter: invert(0.1);
  filter: invert(0.1);
}
.cart-item__name {
  font-weight: 500;
  font-size: 1.6rem;
  padding-right: 0.8rem;
}
.cart-item__name--options {
  padding-top: 0.2rem;
  font-weight: 400;
  font-size: 1.2rem;
}
.cart-item__price {
  font-weight: 600;
  padding-right: 1.2rem;
}
.cart-item__control {
  width: 3rem;
  height: 3rem;
  border: none;
  display: flex;
  color: #fb3350;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: transparent;
}
.cart-item__control:hover {
  background-color: #e7ecef;
}
.cart-item.cart-item-with-apply {
  grid-template-columns: 10rem 8rem auto;
}
.cart-item.cart-item-with-apply .cart-item__price,
.cart-item.cart-item-with-apply .cart-item__control {
  display: none;
}

.cart-xs .cart-item {
  grid-template-columns: 10rem auto;
  border-bottom: 2px solid #e0e7e9;
  padding-bottom: 0.4rem;
  margin-bottom: 1.2rem;
}
.cart-xs .cart-item__apply {
  grid-area: 2/1/3/2;
}
.cart-xs .cart-item__price {
  grid-area: 2/1/3/2;
}
.cart-xs .cart-item__name {
  font-size: 1.2rem;
  padding-bottom: 0.8rem;
}
.cart-xs .cart-item__name--options {
  font-size: 1.1rem;
}
.cart-xs .cart-item__control {
  justify-self: flex-end;
  grid-area: 2/2/3/3;
}

.dark-theme .cart-item__apply--button {
  color: #171717;
  background-color: #e7ecef;
}
.dark-theme .cart-item__control {
  color: #e7ecef;
}
.dark-theme .cart-item__control:hover {
  background-color: #585858;
}

.ty {
  padding: 6.4rem 1.2rem;
  position: relative;
  color: #171717;
}
.ty .ty-container {
  padding: 2.4rem 0 0;
  background-color: #ffffff;
  margin: 0 auto 1.2rem;
  max-width: 96rem;
}
.ty__title {
  text-align: center;
  padding: 0 2.4rem 2.4rem;
}
.ty__sub-title {
  text-align: center;
  font-weight: 400;
  padding: 0 2.4rem 2.4rem;
}
.ty__tracking {
  display: grid;
  grid-template-columns: 3fr 4fr;
  column-gap: 1.6rem;
}
.ty__tracking--image {
  max-width: 100%;
  height: auto;
  position: relative;
}
.ty__tracking--image--text {
  position: absolute;
  text-align: center;
  padding: 1.2rem;
  top: 40%;
  width: 100%;
  display: flex;
  align-items: center;
  color: #e7ecef;
  font-weight: 500;
  font-size: 1.6rem;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
}
.ty__tracking--details {
  padding-right: 2.4rem;
}
.ty__tracking--details--table {
  display: grid;
  gap: 0.4rem;
  grid-template-columns: repeat(3, 1fr);
}
.ty__tracking--details--table .label {
  font-size: 1.2rem;
  display: inline-block;
  padding-bottom: 0.4rem;
}
.ty__tracking--details--table .value {
  display: inline-block;
  font-weight: 600;
}
.ty__tracking--details--table .details-table__head {
  grid-area: 1/1/2/4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #eeeeee;
  padding: 1.2rem;
}
.ty__tracking--details--table .details-table__head--left .label {
  padding-right: 0.8rem;
  padding-bottom: 0;
}
.ty__tracking--details--table .details-table__head--right {
  font-style: italic;
  font-size: 1.1rem;
  padding-left: 0.4rem;
  color: #515151;
}
.ty__tracking--details--table .details-table__block {
  display: flex;
  text-align: left;
  padding: 1.2rem;
  flex-direction: column;
  background-color: #eeeeee;
  align-items: flex-start;
  justify-content: space-between;
}
.ty__tracking--details--statuses {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 2.4rem;
  padding-bottom: 1.6rem;
}
.ty__tracking--details--statuses .tracking-status {
  display: grid;
  position: relative;
  align-items: center;
  column-gap: 1.2rem;
  grid-template-columns: 2rem auto;
}
.ty__tracking--details--statuses .tracking-status__icon {
  color: #e7ecef;
}
.ty__tracking--details--statuses--connector {
  height: 1.5rem;
  background-color: #eeeeee;
  width: 2px;
  display: block;
  margin-left: 0.9rem;
}
.ty__tracking--details--statuses .completed-status {
  font-weight: 500;
  text-decoration: line-through;
}
.ty__details {
  display: grid;
  padding: 2.4rem 2.4rem 4rem;
  grid-template-columns: 3fr 2fr;
}
.ty__details .label {
  padding-bottom: 0.4rem;
  display: inline-block;
  font-size: 1.2rem;
}
.ty__details .value {
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 500;
}
.ty__details .comment {
  font-style: italic;
  font-weight: 400;
  font-size: 1.2rem;
}
.ty__details-container {
  display: grid;
  padding-right: 2.4rem;
  grid-template-columns: 1fr 1fr;
  gap: 1.2rem;
}
.ty__details-prices {
  display: flex;
  row-gap: 0.4rem;
  flex-direction: column;
  border-left: 1px solid #d6d6d6;
  padding-left: 3.2rem;
  padding-right: 2.4rem;
}
.ty__details-prices .label {
  font-size: 1.2rem;
}
.ty__details--data {
  display: flex;
  flex-direction: column;
}
.ty__details--data.full-width-data {
  grid-column: 1/3;
}
.ty__details--price {
  display: flex;
  text-align: left;
  flex-direction: column;
  align-items: flex-start;
  background-color: #eeeeee;
  padding: 1.2rem;
  justify-content: flex-start;
}
.ty__details--price .total-price {
  padding-top: 0.4rem;
  font-size: 2.4rem;
}

.ty-sm .ty__details-prices {
  padding-right: 0;
  padding-left: 1.6rem;
}

.ty-xs {
  padding: 6.4rem 0;
}
.ty-xs .ty__tracking {
  grid-template-columns: 1fr;
}
.ty-xs .ty__tracking--image {
  margin-bottom: 1.2rem;
}
.ty-xs .ty__tracking--details {
  padding: 0 1.2rem;
}
.ty-xs .ty__tracking--details--statuses {
  padding-top: 1.6rem;
}
.ty-xs .ty__details {
  grid-template-columns: 1fr;
}
.ty-xs .ty__details-container {
  grid-template-columns: 1fr;
}
.ty-xs .ty__details-prices {
  margin-top: 1.6rem;
  border-left: none;
  padding-left: 0;
  padding-right: 0;
}
.ty-xs .ty__details .full-width-data {
  grid-column: 1/2;
}

.rounded-corners .ty .ty-container {
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
}
.rounded-corners .ty__tracking--image {
  -webkit-border-top-right-radius: 1rem;
  -webkit-border-bottom-right-radius: 0;
  -webkit-border-bottom-left-radius: 1rem;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topright: 1rem;
  -moz-border-radius-bottomright: 0;
  -moz-border-radius-bottomleft: 1rem;
  -moz-border-radius-topleft: 0;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 1rem;
  border-top-left-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

.dark-theme .ty .ty-container {
  background-color: #393939;
  color: #e7ecef;
}
.dark-theme .ty__tracking--details--table .details-table__block, .dark-theme .ty__tracking--details--table .details-table__head {
  background-color: #333333;
}
.dark-theme .ty__tracking--details--table .details-table__head--right {
  color: #999999;
}
.dark-theme .ty__details--price {
  background-color: #333333;
}

.profile__header {
  color: #171717;
  text-align: center;
  padding: 3.2rem 1.2rem;
  background-color: #f7f7f7;
}
.profile__prev-orders {
  padding: 2.4rem;
}
.profile__prev-orders--title {
  margin-bottom: 1.6rem;
}
.profile__prev-orders--title .status-label {
  margin-left: 0.8rem;
  color: #171717;
  font-size: 1.4rem;
  background-color: #bebebe;
  padding: 0.4rem 1.2rem;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  border-radius: 2rem;
}
.profile__prev-orders--shadow-container {
  -webkit-box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 2.4rem;
}
.profile__prev-orders--item {
  width: 100%;
  display: grid;
  border-bottom: 1px solid #e7ecef;
  padding: 0.8rem 1.2rem;
  grid-template-columns: 3rem auto 8rem;
}
.profile__prev-orders--item .quantity {
  color: #999999;
  font-weight: 600;
}
.profile__prev-orders--item .name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.profile__prev-orders--item .price {
  font-weight: 500;
}
.profile__prev-orders--extra-details {
  padding: 1.2rem;
}
.profile__prev-orders--extra-details .detail-block {
  display: flex;
  flex-direction: column;
}
.profile__prev-orders--extra-details .detail-block .label {
  font-size: 1.2rem;
  padding-bottom: 0.4rem;
}
.profile__prev-orders--extra-details .detail-block .value {
  font-size: 1.2rem;
  font-weight: 500;
}
.profile__prev-orders--extra-details .extra-details-block-1 {
  display: grid;
  column-gap: 1.2rem;
  grid-template-columns: repeat(3, 1fr);
  padding-bottom: 1.2rem;
}
.profile__prev-orders--extra-details .extra-details-block-2 {
  display: grid;
  column-gap: 1.2rem;
  grid-template-columns: repeat(2, 1fr);
  padding-bottom: 1.2rem;
}
.profile__prev-orders--track {
  display: flex;
  justify-content: center;
  padding-bottom: 1.6rem;
}
.profile__prev-orders--order-again {
  display: grid;
  margin-bottom: 2.4rem;
  padding-bottom: 1.2rem;
  border-bottom: 1px solid #e7ecef;
  grid-template-columns: repeat(4, 1fr);
}
.profile__prev-orders--order-again .order-again {
  display: flex;
  padding: 0.8rem;
  flex-direction: column;
}
.profile__prev-orders--order-again .order-again__name {
  text-align: left;
  font-weight: 500;
  padding-bottom: 0.4rem;
}
.profile__prev-orders--order-again .order-again__img-container {
  width: 100%;
}
.profile__prev-orders--order-again .order-again__img-container--image {
  max-width: 100%;
  height: auto;
}
.profile__prev-orders--order-again .order-again__desc {
  height: 5rem;
  font-size: 1.1rem;
  word-break: break-all;
}
.profile__prev-orders--order-again .order-again__price {
  padding-bottom: 0.8rem;
}
.profile__prev-orders--order-again .order-again__add {
  display: flex;
  justify-content: center;
}
.profile__prev-orders--order-again .order-again:hover {
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}
.profile__prev-orders--order {
  display: flex;
  flex-direction: column;
  padding-bottom: 2.4rem;
}
.profile__prev-orders--order .order-info {
  display: flex;
  align-items: center;
  padding-bottom: 0.2rem;
}
.profile__prev-orders--order .date-time {
  color: #999999;
  font-style: italic;
  padding-right: 1.2rem;
}
.profile__prev-orders--order .add-to-cart {
  background-color: transparent;
  color: #490151;
  cursor: pointer;
  border: none;
}
.profile__prev-orders--order .add-to-cart:hover {
  -webkit-filter: brightness(0.9);
  -ms-filter: brightness(0.9);
  filter: brightness(0.9);
}
.profile__addresses {
  padding: 2.4rem;
  max-width: 40rem;
  margin: 0 auto;
}
.profile__addresses--title {
  display: flex;
  align-items: center;
  padding-bottom: 1.6rem;
  justify-content: space-between;
}
.profile__addresses--form {
  display: grid;
  column-gap: 1.6rem;
  grid-template-columns: repeat(2, 1fr);
}
.profile__addresses--form--controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  grid-column: 1/3;
}
.profile__addresses--form--controls .dom__button {
  margin-left: 1.2rem;
}
.profile__addresses--address {
  display: flex;
  text-align: left;
  align-items: stretch;
  padding: 1.2rem;
  background-color: #e7ecef;
  margin-bottom: 0.8rem;
  justify-content: space-between;
}
.profile__addresses--address .controls {
  display: flex;
  min-width: 5rem;
  align-items: center;
  padding-left: 0.8rem;
  justify-content: space-between;
}
.profile__addresses--address .controls .icon {
  cursor: pointer;
}
.profile__my-data {
  display: grid;
  padding: 2.4rem;
  column-gap: 1.2rem;
  grid-template-columns: repeat(2, 1fr);
  max-width: 40rem;
  margin: 0 auto;
}
.profile__my-data--title {
  margin: 0 auto;
  max-width: 40rem;
  grid-column: 1/3;
  text-align: center;
  padding-bottom: 1.6rem;
}
.profile__my-data--control {
  display: flex;
  grid-column: 1/3;
  justify-content: center;
}
.profile__my-data .full-row {
  grid-column: 1/3;
}
.profile__my-data-block {
  border-top: 2px solid #e0e7e9;
  flex-direction: column;
  justify-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.6rem 2.4rem;
}
.profile__my-data-block--sub-title {
  max-width: 35rem;
  text-align: left;
  font-weight: 500;
  font-size: 1.2rem;
  width: 100%;
  padding-bottom: 0.8rem;
}
.profile__my-data-block .dom__button {
  width: 100%;
  max-width: 35rem;
}
.profile__my-data-terminate {
  padding-bottom: 2.4rem;
}
.profile__my-data-terminate .dom__button {
  background-color: transparent !important;
  color: #ba1b23 !important;
  border: 2px solid #ba1b23 !important;
}
.profile__my-data-terminate .dom__button:hover {
  background-color: #ba1b23 !important;
  color: #ffffff !important;
}

.profile-xs .profile__prev-orders--title {
  display: flex;
  text-align: left;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.profile-xs .profile__prev-orders--title .status-label {
  margin-left: 0;
  margin-top: 0.4rem;
}
.profile-xs .profile__addresses {
  padding: 2.4rem 1.2rem;
}
.profile-xs .profile__addresses--form {
  grid-template-columns: 1fr;
}
.profile-xs .profile__addresses--form--controls {
  grid-column: 1/2;
}

.dark-theme .profile__header,
.dark-theme-iframe .profile__header,
.dark-theme-app .profile__header {
  background-color: #393939;
  color: #e7ecef;
}
.dark-theme .profile__prev-orders--shadow-container,
.dark-theme-iframe .profile__prev-orders--shadow-container,
.dark-theme-app .profile__prev-orders--shadow-container {
  -webkit-box-shadow: 0 6px 8px 0 rgba(130, 130, 130, 0.2);
  -moz-box-shadow: 0 6px 8px 0 rgba(130, 130, 130, 0.2);
  box-shadow: 0 6px 8px 0 rgba(130, 130, 130, 0.2);
}
.dark-theme .profile__prev-orders--item,
.dark-theme-iframe .profile__prev-orders--item,
.dark-theme-app .profile__prev-orders--item {
  border-bottom-color: #585858;
}
.dark-theme .profile__prev-orders--order-again,
.dark-theme-iframe .profile__prev-orders--order-again,
.dark-theme-app .profile__prev-orders--order-again {
  border-bottom-color: #585858;
}
.dark-theme .profile__prev-orders--order-again .order-again:hover,
.dark-theme-iframe .profile__prev-orders--order-again .order-again:hover,
.dark-theme-app .profile__prev-orders--order-again .order-again:hover {
  -webkit-box-shadow: 0 0 10px 0 rgba(200, 200, 200, 0.2);
  -moz-box-shadow: 0 0 10px 0 rgba(200, 200, 200, 0.2);
  box-shadow: 0 0 10px 0 rgba(200, 200, 200, 0.2);
}
.dark-theme .profile__prev-orders--order .add-to-cart,
.dark-theme-iframe .profile__prev-orders--order .add-to-cart,
.dark-theme-app .profile__prev-orders--order .add-to-cart {
  color: #ff0154;
}
.dark-theme .profile__addresses--address,
.dark-theme-iframe .profile__addresses--address,
.dark-theme-app .profile__addresses--address {
  background-color: #585858;
}
.dark-theme .profile__addresses .no-data,
.dark-theme-iframe .profile__addresses .no-data,
.dark-theme-app .profile__addresses .no-data {
  background-color: #585858;
  color: #d6d6d6;
  margin: 0 auto;
  max-width: 40rem;
}
.dark-theme .profile__my-data-block,
.dark-theme-iframe .profile__my-data-block,
.dark-theme-app .profile__my-data-block {
  border-top-color: #585858;
}
.dark-theme .profile .wrapper .input-label,
.dark-theme-iframe .profile .wrapper .input-label,
.dark-theme-app .profile .wrapper .input-label {
  color: #d6d6d6;
}
.dark-theme .profile .wrapper .text-input,
.dark-theme-iframe .profile .wrapper .text-input,
.dark-theme-app .profile .wrapper .text-input {
  background-color: #585858;
  color: #e7ecef;
}

.profile-tabs {
  display: grid;
  margin: 0 auto;
  max-width: 90rem;
  column-gap: 4.8rem;
  align-items: flex-start;
  grid-template-columns: 2fr 5fr;
  padding: 6.4rem 2.4rem;
}
.profile-tabs__links {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}
.profile-tabs__links--link {
  padding: 1.2rem 1.6rem;
  border-bottom: 2px solid #d6d6d6;
}
.profile-tabs__links--link:last-of-type {
  border-bottom: none;
}
.profile-tabs__links--link:hover {
  -webkit-filter: none;
  -ms-filter: none;
  filter: none;
}
.profile-tabs__body {
  background-color: #ffffff;
}

.profile-md .profile-tabs {
  column-gap: 2.4rem;
  padding: 6.4rem 1.6rem;
  grid-template-columns: 20rem 1fr;
}

.profile-sm .profile-tabs,
.profile-xs .profile-tabs {
  column-gap: 0;
  padding: 6.4rem 0.4rem;
  row-gap: 1.6rem;
  grid-template-columns: 1fr;
}

.rounded-corners .profile-tabs__links {
  overflow: hidden;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
}
.rounded-corners .profile-tabs__body {
  overflow: hidden;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
}

.dark-theme .profile-tabs__links,
.dark-theme-iframe .profile-tabs__links,
.dark-theme-app .profile-tabs__links {
  background-color: #393939;
}
.dark-theme .profile-tabs__links--link,
.dark-theme-iframe .profile-tabs__links--link,
.dark-theme-app .profile-tabs__links--link {
  border-bottom-color: #171717;
}
.dark-theme .profile-tabs__body,
.dark-theme-iframe .profile-tabs__body,
.dark-theme-app .profile-tabs__body {
  background-color: #393939;
}

.forgotten-password {
  padding: 6.4rem 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
}
.forgotten-password__form {
  width: 100%;
  max-width: 40rem;
  -webkit-box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.2);
}
.forgotten-password__form--title {
  margin-bottom: 1px;
}
.forgotten-password__form--content, .forgotten-password__form--title {
  color: #171717;
  padding: 2.4rem;
  background-color: #ffffff;
}
.forgotten-password__form--content .dom__button, .forgotten-password__form--title .dom__button {
  margin-left: auto;
  margin-right: auto;
}

.dark-theme .forgotten-password__form--title, .dark-theme .forgotten-password__form--content {
  background-color: #333333;
  color: #e7ecef;
}
.dark-theme .forgotten-password__form--title .wrapper .input-label, .dark-theme .forgotten-password__form--content .wrapper .input-label {
  color: #ffffff;
}

.rounded-corners .forgotten-password__form--title {
  -webkit-border-top-right-radius: 1rem;
  -webkit-border-bottom-right-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-top-left-radius: 1rem;
  -moz-border-radius-topright: 1rem;
  -moz-border-radius-bottomright: 0;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-topleft: 1rem;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 1rem;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.rounded-corners .forgotten-password__form--content {
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 1rem;
  -webkit-border-bottom-left-radius: 1rem;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 1rem;
  -moz-border-radius-bottomleft: 1rem;
  -moz-border-radius-topleft: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  border-top-left-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

.--NAVBAR_v2 {
  height: auto;
  display: grid;
  min-height: auto;
  align-items: center;
  grid-template-columns: 12rem auto 22rem;
}
.--NAVBAR_v2 .v2navbar__logo {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 0.8rem;
}
.--NAVBAR_v2 .v2navbar__logo--image {
  max-height: 6rem;
  max-width: 100%;
  height: auto;
  width: auto;
}
.--NAVBAR_v2 .v2navbar__links {
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
}
.--NAVBAR_v2 .v2navbar__links--element {
  padding: 1.2rem;
  position: relative;
}
.--NAVBAR_v2 .v2navbar__right {
  height: 100%;
  display: grid;
  align-items: center;
  row-gap: 0.4rem;
  column-gap: 0.8rem;
  padding: 0.8rem 0 0.8rem 1.2rem;
  grid-template-columns: 10rem 4rem 4rem;
}
.--NAVBAR_v2 .v2navbar__right .navbar__top__meta--info {
  height: auto;
  align-self: stretch;
  font-weight: 400;
  grid-area: 1/1/2/2;
}
.--NAVBAR_v2 .v2navbar__right .navbar__top__meta--info--status {
  margin-left: 0;
}
.--NAVBAR_v2 .v2navbar__right .navbar__top__meta--info--text {
  font-size: 1.6rem;
  font-weight: inherit;
}
.--NAVBAR_v2 .v2navbar__right .navbar__bottom__checkout {
  grid-area: 2/1/3/4;
  padding-right: 0;
  width: 100%;
}
.--NAVBAR_v2 .v2navbar__right .navbar__bottom__checkout .dom__button {
  width: 100%;
}
.--NAVBAR_v2 .v2navbar__profile {
  border: none;
  height: 100%;
  display: flex;
  font-size: 1.6rem;
  grid-area: 1/3/2/4;
  position: relative;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  background-color: transparent;
}
.--NAVBAR_v2 .v2navbar__profile--icon {
  margin-left: 0.8rem;
}
.--NAVBAR_v2 .v2navbar__langs {
  grid-area: 1/2/2/3;
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
}
.--NAVBAR_v2 .v2navbar__langs--button {
  display: flex;
  font-size: 1.6rem;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}
.--NAVBAR_v2 .v2navbar__toggle {
  display: none;
}
.--NAVBAR_v2.navbar-sm, .--NAVBAR_v2.navbar-xs {
  grid-template-columns: 12rem auto;
}
.--NAVBAR_v2.navbar-sm .v2navbar__links, .--NAVBAR_v2.navbar-xs .v2navbar__links {
  display: none;
}
.--NAVBAR_v2.navbar-sm .v2navbar__langs, .--NAVBAR_v2.navbar-xs .v2navbar__langs {
  grid-area: 1/2/2/3;
  display: none;
}
.--NAVBAR_v2.navbar-sm .v2navbar__right, .--NAVBAR_v2.navbar-xs .v2navbar__right {
  justify-content: flex-end;
  grid-template-columns: auto 6rem;
}
.--NAVBAR_v2.navbar-sm .v2navbar__right .navbar__top__meta--info, .--NAVBAR_v2.navbar-xs .v2navbar__right .navbar__top__meta--info {
  grid-area: 1/1/2/3;
  text-align: center;
  justify-content: center;
}
.--NAVBAR_v2.navbar-sm .v2navbar__right .navbar__bottom__checkout, .--NAVBAR_v2.navbar-xs .v2navbar__right .navbar__bottom__checkout {
  grid-area: 2/1/3/2;
  margin-top: 0;
}
.--NAVBAR_v2.navbar-sm .v2navbar__right .navbar__bottom__checkout .dom__button, .--NAVBAR_v2.navbar-xs .v2navbar__right .navbar__bottom__checkout .dom__button {
  width: auto;
  padding: 0;
  display: flex;
  width: 4rem;
  height: 4rem;
  justify-content: center;
  align-items: center;
}
.--NAVBAR_v2.navbar-sm .v2navbar__right .navbar__bottom__checkout .dom__button--left-icon, .--NAVBAR_v2.navbar-xs .v2navbar__right .navbar__bottom__checkout .dom__button--left-icon {
  justify-content: center;
}
.--NAVBAR_v2.navbar-sm .v2navbar__right .navbar__bottom__checkout .dom__button--text, .--NAVBAR_v2.navbar-xs .v2navbar__right .navbar__bottom__checkout .dom__button--text {
  display: none;
}
.--NAVBAR_v2.navbar-sm .v2navbar__profile, .--NAVBAR_v2.navbar-xs .v2navbar__profile {
  display: none;
}
.--NAVBAR_v2.navbar-sm .v2navbar__toggle, .--NAVBAR_v2.navbar-xs .v2navbar__toggle {
  justify-self: center;
  grid-area: 2/2/3/3;
  display: flex;
  width: 4rem;
  height: 4rem;
  align-items: center;
  justify-content: center;
}

.v2sidemenu {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 100;
  position: absolute;
  pointer-events: none;
  background-color: #00000088;
}
.v2sidemenu__content {
  opacity: 0;
  height: 100%;
  width: 28rem;
  display: flex;
  overflow-y: auto;
  position: relative;
  flex-direction: column;
  background-color: #ffffff;
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
}
.v2sidemenu__content .v2navbar__logo {
  display: block;
}
.v2sidemenu__content .v2navbar__logo--image {
  height: auto;
  max-width: 100%;
}
.v2sidemenu__content .v2navbar__links {
  display: flex;
  flex-direction: column;
}
.v2sidemenu__content .v2navbar__links--element {
  padding: 0.8rem 1.2rem;
}
.v2sidemenu__content .v2navbar__langs {
  display: flex;
  text-align: center;
  justify-content: center;
}
.v2sidemenu__content .v2navbar__langs--button {
  font-weight: 600;
  font-size: 1.8rem;
  background-color: transparent;
  padding: 0.8rem 1.2rem;
}
.v2sidemenu__content .v2navbar__profile {
  padding: 1.2rem 1.2rem;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  display: flex;
}
.v2sidemenu__content .v2navbar__profile--icon {
  margin-left: 1.6rem;
}

.--OPENED_SIDEMENU {
  opacity: 1;
  pointer-events: all;
}
.--OPENED_SIDEMENU .v2sidemenu__content {
  opacity: 1;
  transform: translateX(0%);
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
}

.rounded-corners .v2navbar__toggle {
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
}

.dark-theme .v2sidemenu__content {
  background-color: #333333;
  color: #e7ecef;
}

.--NAVBAR_v3 .v3-navbar__top {
  padding: 0;
  display: flex;
  height: 2.2rem;
  position: relative;
  align-items: stretch;
  padding: 0 2.4rem;
  column-gap: 1.2rem;
  justify-content: flex-end;
}
.--NAVBAR_v3 .v3-navbar__top .navbar__top__meta--button {
  display: flex;
  height: 2.2rem;
  color: #171717;
  min-width: auto;
  text-align: right;
  font-weight: normal;
  line-height: 2.2rem;
  align-items: center;
  justify-content: flex-end;
  padding: 0.4rem 0.8rem 0;
}
.--NAVBAR_v3 .v3-navbar__top .navbar__top__meta--info {
  padding: 0.4rem 0 0;
  display: flex;
  height: 2.2rem;
  color: #171717;
  text-align: right;
  font-weight: normal;
  line-height: 2.2rem;
  align-items: center;
  justify-content: flex-end;
}
.--NAVBAR_v3 .v3-navbar__top .navbar__top__meta--info--status {
  margin-left: 0;
  background-color: #171717;
}
.--NAVBAR_v3 .v3-navbar__top .navbar__top__meta--info--text {
  font-weight: normal;
}
.--NAVBAR_v3 .v3-navbar__bottom {
  display: grid;
  position: relative;
  grid-template-columns: auto 22rem;
}
.--NAVBAR_v3 .v3-navbar__bottom .navbar__bottom__logo {
  grid-column: 1/3;
  max-height: unset;
}
.--NAVBAR_v3 .v3-navbar__bottom .navbar__bottom__logo--image-container {
  max-height: unset;
}
.--NAVBAR_v3 .v3-navbar__bottom .navbar__bottom__logo--image-container--image {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
}
.--NAVBAR_v3 .v3-navbar__bottom .navbar__bottom__links {
  padding: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.--NAVBAR_v3 .v3-navbar__bottom--1col {
  grid-template-columns: 1fr;
}
.--NAVBAR_v3 .v3-navbar__bottom--1col .navbar__bottom__logo {
  grid-column: 1/2;
}
.--NAVBAR_v3 .v3-navbar__bottom--1col .navbar__bottom__links {
  justify-content: center;
}

.page-xs .--NAVBAR_v3 .v3-navbar__top {
  padding: 0 0.8rem;
}
.page-xs .--NAVBAR_v3 .v3-navbar__bottom {
  justify-content: flex-start;
  grid-template-columns: auto 17rem;
}
.page-xs .--NAVBAR_v3 .v3-navbar__bottom--1col {
  justify-content: center;
  grid-template-columns: 1fr;
}

.dark-theme .--NAVBAR_v3 .navbar__top__meta--button {
  color: #e7ecef;
}
.dark-theme .--NAVBAR_v3 .navbar__top__meta--info {
  color: #e7ecef;
}
.dark-theme .--NAVBAR_v3 .navbar__top__meta--info--status {
  background-color: #e7ecef;
}

.login-page {
  display: flex;
  min-height: 100vh;
  width: 100%;
  flex-direction: column;
  background-color: #e7ecef;
  padding-bottom: 2.4rem;
}
.login-page__header {
  background-image: -moz-linear-gradient(180deg, #ee3753 0%, #ee3753 40%, #b63958 100%);
  background-image: -webkit-gradient(180deg, #ee3753 0%, #ee3753 40%, #b63958 100%);
  background-image: -webkit-linear-gradient(180deg, #ee3753 0%, #ee3753 40%, #b63958 100%);
  background-image: -o-linear-gradient(180deg, #ee3753 0%, #ee3753 40%, #b63958 100%);
  background-image: -ms-linear-gradient(180deg, #ee3753 0%, #ee3753 40%, #b63958 100%);
  background-image: linear-gradient(180deg, #ee3753 0%, #ee3753 40%, #b63958 100%);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}
.login-page__header--block {
  display: flex;
  flex-direction: column;
}
.login-page__header--text {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  color: #ffffff;
  padding-top: 0.4rem;
  font-size: 12px;
  line-height: 24px;
  vertical-align: middle;
}
.login-page__header--text .--italic {
  color: #ffd750;
  font-weight: 600;
  font-style: italic;
  margin-left: 0.4rem;
  font-size: 20px;
}
.login-page__container {
  margin: 3.2rem auto;
  max-width: 40rem;
}
.login-page__title {
  background-color: #ffffff;
  padding: 2.4rem 0.8rem;
  margin-bottom: 2px;
  font-weight: 600;
  text-align: center;
}
.login-page__form {
  padding: 1.6rem 3.2rem;
  background-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.login-page__form .login-input {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.login-page__form .login-input-icon {
  position: absolute;
  left: 10px;
  top: 38px;
  color: #777777;
}
.login-page__form .login-input-error {
  font-size: 12px;
  margin-top: -0.8rem;
  margin-bottom: 0.8rem;
  color: #fb3350;
  font-style: italic;
  width: 100%;
  text-align: right;
}
.login-page__form--input-field {
  width: 100%;
  margin-bottom: 1.6rem;
}
.login-page__form--input-field .text-input {
  padding-left: 40px !important;
}
.login-page__form--submit {
  margin-top: 1.2rem !important;
}
.login-page__forgotten {
  margin-top: 1.6rem;
  text-align: center;
}
.login-page .login-footer {
  background-color: #e0e7e9;
  color: #6a6a6a;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}
@media screen and (max-width: 576px) {
  .login-page__form {
    padding: 1.6rem;
  }
  .login-page__form .login-input-icon {
    top: 3rem;
    left: 1rem;
  }
  .login-page__form--submit {
    margin: 0 auto !important;
  }
}

.sidemenu {
  position: fixed;
  background-color: #ffffff;
  width: 300px;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 3000;
  overflow-y: auto;
  overflow-x: visible;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  -webkit-transition: transform 0.3s linear;
  -moz-transition: transform 0.3s linear;
  -ms-transition: transform 0.3s linear;
  -o-transition: transform 0.3s linear;
  transition: transform 0.3s linear;
}
.sidemenu__side-control {
  top: calc(50% - 24px);
  -webkit-transition: left 0.3s linear !important;
  -moz-transition: left 0.3s linear !important;
  -ms-transition: left 0.3s linear !important;
  -o-transition: left 0.3s linear !important;
  transition: left 0.3s linear !important;
  left: 0;
  position: fixed;
  background-color: #d6d6d6;
  opacity: 0.3;
  z-index: 3000;
}
.sidemenu__side-control--icon-container {
  padding: 1.2rem 0.4rem;
  cursor: pointer;
  color: #171717;
}
.sidemenu__side-control:hover {
  opacity: 1;
  background-color: #e0e7e9;
}
.sidemenu__side-control.global__menu-open-absolute {
  opacity: 1;
}
.sidemenu.sidemenu-editor-full-height .editor {
  padding-bottom: 0;
}
.sidemenu.sidemenu-editor-full-height .editor__content {
  height: calc(100vh - 37px);
}
.sidemenu.sidemenu-editor-full-height .editor__footer {
  display: none;
}
.sidemenu.sidemenu-opened {
  transform: translateX(0%);
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
}

.menu-head {
  display: flex;
  position: relative;
  align-items: stretch;
  background-color: #ffffff;
  justify-content: space-between;
  border-bottom: 1px solid #e7ecef;
}
.menu-head__pages {
  padding-left: 1.2rem;
}
.menu-head__icon {
  cursor: pointer;
  align-self: center;
  position: relative;
}
.menu-head__icon:hover {
  color: #a93e73;
}
.menu-head__icon.--active {
  color: #a93e73;
}
.menu-head__dropmenu {
  position: absolute;
  top: 30px;
  left: 5px;
  display: inline-flex;
  flex-direction: column;
  color: #171717;
  width: 200px;
  z-index: 10;
  -webkit-box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
}
.menu-head__dropmenu--close {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  font-size: 1.2rem;
  color: #6a6a6a;
  padding: 0.8rem 1.2rem 0.8rem 1.2rem;
  -webkit-box-shadow: 0 3px 4px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 3px 4px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 4px 1px rgba(0, 0, 0, 0.05);
  margin-bottom: 0.4rem;
}
.menu-head__dropmenu--item {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0.4rem 1.2rem;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.menu-head__dropmenu--item .text {
  margin-left: 1.2rem;
}
.menu-head__dropmenu--item:hover {
  background-color: #e0e7e9;
}
.menu-head__dropmenu--item.active-item {
  background-color: #a93e73;
  color: #ffffff;
}
.menu-head__dropmenu--lang {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem 1.2rem;
  border-bottom: 1px solid #e7ecef;
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
}
.menu-head__dropmenu--lang .separator {
  padding: 0 0.2rem;
}
.menu-head__dropmenu--lang .lang {
  padding: 0 0.4rem;
}
.menu-head__dropmenu--lang .lang:hover {
  color: #a93e73;
}
.menu-head__dropmenu--lang .active-lang {
  font-weight: 500;
  color: #a93e73;
}
.menu-head__dropmenu--templates {
  border-bottom: 1px solid #e7ecef !important;
  text-align: left;
}
.menu-head__dropmenu--restaurant .wrapper {
  margin-bottom: 0;
}
.menu-head__creator {
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #a93e73;
  color: #ffffff;
  padding: 0 0.4rem;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.menu-head__creator:hover {
  background-color: #b4427b;
}
.menu-head__creator.disabled-create {
  cursor: default;
  opacity: 0;
}

.editor__content .navbar-editor .om-container {
  padding-right: 1.2rem;
  padding-bottom: 1.2rem;
}
.editor__content .navbar-editor__link {
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  align-items: stretch;
  padding: 0 1.2rem 0.4rem 1.2rem;
  gap: 0.4rem;
}
.editor__content .navbar-editor__link--drag {
  padding: 0.4rem 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f6f7;
  cursor: grab;
}
.editor__content .navbar-editor__link--control {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f6f7;
  padding: 0.4rem 0.4rem;
  cursor: pointer;
}
.editor__content .navbar-editor__link--control:hover {
  color: #a93e73;
}
.editor__content .navbar-editor__link--content {
  display: block;
}
.editor__content .navbar-editor__link .wrapper {
  padding: 0;
  margin-bottom: 0.4rem;
}
.editor__content .navbar-editor__link .wrapper:last-of-type {
  margin-bottom: 0;
}
.editor__content .navbar-editor__link-with-dnd {
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  padding: 0 1.2rem;
}
.editor__content .navbar-editor__above-link {
  border-top-color: #d529ff;
}
.editor__content .navbar-editor__below-link {
  border-bottom-color: #d529ff;
}

.editor {
  height: calc(100vh - 37px);
  position: relative;
  padding-bottom: 6.4rem;
}
.editor__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.8rem;
  background-color: #ffffff;
  margin-bottom: 1.6rem;
  color: #171717;
  padding: 0.8rem 1.2rem;
  -webkit-box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.2);
}
.editor__header.--centerized {
  display: block;
  text-align: center;
}
.editor__page-group-name {
  padding: 1.2rem 1.2rem 0.4rem;
  font-weight: 600;
}
.editor__page-group-name.no-top-spacing {
  padding-top: 0;
}
.editor__content {
  display: block;
  overflow-y: auto;
  height: calc(100vh - 155px);
}
.editor__publish-widget {
  padding: 1.2rem 1.2rem;
}
.editor__threeway-toggle {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.editor__threeway-toggle .toggle {
  border: none;
  cursor: pointer;
  color: #171717;
  background-color: #ffffff;
  border-bottom: 2px solid #d6d6d6;
  padding: 0.8rem 0.8rem;
}
.editor__threeway-toggle .toggle.active-toggle {
  border-bottom: 2px solid #a93e73;
  color: #a93e73;
  font-weight: 600;
}
.editor__footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  color: #ffffff;
  display: grid;
  grid-template-rows: 55px;
  grid-template-columns: 60% 40%;
}
.editor__footer--last-saved {
  display: flex;
  cursor: pointer;
  align-items: center;
  background-color: #6a6a6a;
  padding: 0 1.2rem;
  font-size: 1.2rem;
  -webkit-transition: background-color 0.2s linear;
  -moz-transition: background-color 0.2s linear;
  -ms-transition: background-color 0.2s linear;
  -o-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
}
.editor__footer--last-saved:hover {
  background-color: #747474;
}
.editor__footer--edit {
  background-color: #e63753;
  height: auto !important;
  grid-area: 1/1/2/3;
}
.editor__footer--save {
  cursor: pointer;
  padding: 0 1.2rem;
  background-color: #a93e73;
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-transition: background-color 0.2s linear;
  -moz-transition: background-color 0.2s linear;
  -ms-transition: background-color 0.2s linear;
  -o-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
}
.editor__footer--save:hover {
  background-color: #bb4680;
}
.editor__footer--save .svg {
  display: inline-flex;
}
.editor__footer--last-saved, .editor__footer--save, .editor__footer--edit {
  text-align: left;
  border: none;
  color: #e7ecef;
}
.editor__footer.--disabled {
  grid-template-columns: 100%;
}
.editor__footer.--disabled .editor__footer--last-saved {
  background-color: #6a6a6a;
  cursor: not-allowed;
}
.editor__footer.--disabled .editor__footer--save {
  display: none;
}
.editor__footer.--dual-row {
  grid-template-rows: repeat(2, 55px);
}
.editor__submit {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 1.2rem;
}
.editor__submit .om-btn {
  margin-left: 0.4rem;
}
.editor .wrapper,
.editor .input-grouper {
  padding: 0 1.2rem;
}
.editor__page-form--title {
  padding: 0 1.2rem 1.2rem 1.2rem;
  font-weight: 500;
}
.editor__page-form .checkbox-container {
  text-align: justify;
}
.editor__page-form .wrapper .text-input-unit {
  font-size: 1.2rem;
  right: 15px;
  top: 3px;
}

.page {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.4rem;
  padding: 0.8rem 1.2rem;
  background-color: #e7ecef;
  margin-left: 1.2rem;
  margin-right: 1.2rem;
  cursor: pointer;
  -webkit-transition: background-color 0.2s linear;
  -moz-transition: background-color 0.2s linear;
  -ms-transition: background-color 0.2s linear;
  -o-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.page:hover {
  background-color: #d1dbde;
}
.page__name {
  font-size: 1.4rem;
  display: inline-flex;
  align-items: center;
  line-height: 1.8rem;
  width: calc(100% - 48px);
}
.page__name .name {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.page__name .svg {
  margin-right: 0.8rem;
  display: inline-flex;
  align-items: flex-end;
}
.page__name .no-title {
  font-size: 1.2rem;
}
.page__controls {
  display: flex;
  align-items: center;
}
.page__controls .svg {
  margin-left: 0.8rem;
  cursor: pointer;
  align-self: center;
}
.page__controls .svg:hover {
  color: #a93e73;
}
.page__child-marker {
  position: absolute;
  top: 0;
  left: 1rem;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 0.4rem;
}
.page__child-marker .vertical {
  display: block;
  width: 1px;
  height: 2rem;
  background-color: #171717;
}
.page__child-marker .horizontal {
  display: block;
  width: 1.8rem;
  height: 1px;
  background-color: #171717;
}
.page.--inactive-page {
  background-color: rgba(255, 0, 0, 0.1);
}
.page.--inactive-page .page__name .name {
  color: #171717;
}
.page.--inactive-page .page__name .draft-name {
  color: #fb3350;
}
.page.--unpublished-page .page__name .name {
  font-style: italic;
  font-weight: 400;
  color: #757575;
}
.page.--cloned-draft-page {
  background-color: #ffffff;
  padding: 0 1.2rem 0 3rem;
  position: relative;
  min-height: 3.6rem;
  margin-left: 4rem;
}
.page.--cloned-draft-page .page__name {
  font-style: italic;
  font-weight: 400;
}
.page.--cloned-draft-page .page__name .draft-name {
  color: #fb3350;
}
.page.--parent-page {
  background-color: #d6d6d6;
  cursor: not-allowed;
}
.page.--active-page {
  background-color: #e63753;
  color: #e7ecef;
}
.page.--active-page .page__child-marker .vertical,
.page.--active-page .page__child-marker .horizontal {
  background-color: #ffffff;
}
.page.--active-page .page__name {
  color: #e7ecef;
  font-weight: 500;
}
.page.--active-page .page__name .draft-name {
  color: #e7ecef;
}
.page.--active-page .page__name .name {
  color: #e7ecef;
}
.page.--active-page .page__controls .svg:hover {
  color: #171717;
}

.component-creator {
  width: 100%;
  display: flex;
  max-height: 100%;
  flex-grow: 1 1 0;
  flex-direction: column;
}
.component-creator__header {
  width: 100%;
  font-size: 1.3rem;
  font-weight: 500;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid #d6d6d6;
  padding: 0.8rem 1.2rem;
  text-align: center;
}
.component-creator__title {
  width: 100%;
  font-size: 1.2rem;
  color: #6a6a6a;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  padding: 0.4rem 1.2rem;
}
.component-creator__content {
  overflow-y: auto;
  max-height: 100%;
}
.component-creator__card-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 0 1.2rem 1.2rem;
  column-gap: 0.8rem;
  row-gap: 0.8rem;
  gap: 0.8rem;
}
.component-creator__card-container.--full-width {
  grid-template-columns: 1fr;
}
.component-creator__card {
  display: flex;
  cursor: grab;
  align-items: center;
  padding: 1.2rem;
  flex-direction: column;
  background-color: #e7ecef;
  justify-content: flex-start;
  -webkit-transition: box-shadow 0.2s linear;
  -moz-transition: box-shadow 0.2s linear;
  -ms-transition: box-shadow 0.2s linear;
  -o-transition: box-shadow 0.2s linear;
  transition: box-shadow 0.2s linear;
}
.component-creator__card:hover {
  -webkit-box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.2);
}
.component-creator__card--icon-container {
  padding: 0.4rem;
}
.component-creator__card--label {
  padding: 0.4rem;
  text-align: center;
}

.history {
  padding: 0 1.2rem 1.2rem 1.2rem;
}
.history__item {
  display: grid;
  grid-template-columns: 55px auto 30px;
  background-color: #e7ecef;
  margin-bottom: 0.4rem;
  padding: 0.4rem 0.4rem;
  font-size: 1.2rem;
}
.history__item--index {
  grid-area: 1/1/2/2;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.history__item--message {
  grid-area: 1/2/2/3;
  display: block;
}
.history__item--controls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-area: 1/3/2/4;
  cursor: pointer;
}
.history__item--controls:hover {
  color: #a93e73;
}
.history__item.--active {
  background-color: #a93e73;
  color: #ffffff;
}
.history__item.--active .history__item--controls .svg:hover {
  color: #ffffff;
}

.config {
  padding-bottom: 2.4rem;
}
.config__domain {
  padding: 1.2rem;
  font-size: 1.6rem;
}
.config__domain .link {
  cursor: pointer;
  color: #a93e73;
}
.config__domain .link:hover {
  color: #ff0154;
}
.config__description {
  padding: 0 1.2rem 0.8rem;
  font-weight: 500;
  font-size: 1.4rem;
  text-align: justify;
}
.config__sub-title {
  font-weight: bold;
  font-size: 1.6rem;
  padding: 4rem 1.2rem 0.8rem 1.2rem;
}
.config__sub-title.--first {
  padding-top: 0;
}
.config__sub-title.--conversion {
  font-size: 1.2rem;
}
.config .hr {
  height: 0.2rem;
  margin-left: 1.2rem;
  margin-right: 0.8rem;
}
.config .select-input__menu .font-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.config .select-input__menu .example-text {
  font-size: 1.1rem;
}
.config .input-description {
  margin-top: 0.4rem;
}
.config .om-container {
  padding: 1.2rem 1.2rem 0 0;
}
.config .pixel-saved-text {
  width: 100%;
  color: #7de394;
  font-weight: bold;
  font-size: 1.6rem;
  text-align: center;
  padding: 0 1.2rem;
  text-shadow: 0 0 0.1rem #00000022;
}
.config .hidden {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.templates {
  height: 100%;
  overflow: hidden;
  position: relative;
}
.templates__header {
  display: block;
  text-align: center;
  padding-bottom: 0.8rem;
  background-color: #ffffff;
  color: #171717;
  padding: 0.8rem 1.2rem;
  -webkit-box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.2);
}
.templates .templates-view {
  padding-bottom: 2.4rem;
  height: calc(100vh - 37px);
  overflow-y: auto;
}
.templates .templates-view__title {
  padding-bottom: 0.4rem;
}
.templates .templates-view__desc {
  font-size: 1.2rem;
  padding-bottom: 1.2rem;
  color: #fb3350;
  font-weight: 500;
}
.templates .templates-view__restaurants {
  margin-top: 1.6rem;
  padding: 0 1.2rem 2.4rem;
}
.templates .templates-view__restaurants .om-container {
  padding-bottom: 1.2rem;
}
.templates .templates-view__restaurants .template-restaurant {
  background-color: #e0e7e9;
  padding: 0.8rem 1.2rem;
  display: grid;
  row-gap: 0.8rem;
  grid-template-columns: 1fr 9.6rem;
  align-items: stretch;
  margin-bottom: 0.4rem;
  border-left: 2px solid #e7ecef;
}
.templates .templates-view__restaurants .template-restaurant__left {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  align-self: center;
}
.templates .templates-view__restaurants .template-restaurant__left .text {
  padding-left: 0.2rem;
}
.templates .templates-view__restaurants .template-restaurant__controls {
  display: flex;
  align-self: stretch;
  justify-content: flex-end;
  align-items: center;
}
.templates .templates-view__restaurants .template-restaurant__controls--button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-left: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.templates .templates-view__restaurants .template-restaurant__controls--button:hover {
  color: #a93e73;
}
.templates .templates-view__restaurants .template-restaurant__controls--button.danger:hover {
  color: #fb3350;
}
.templates .templates-view__restaurants .template-restaurant__controls--button.no-left-spacing {
  margin-left: 0;
}
.templates .templates-view__restaurants .template-restaurant__image {
  grid-area: 2/1/3/3;
}
.templates .templates-view__restaurants .template-restaurant__image .img {
  max-width: 100%;
  height: auto;
}
.templates .templates-view__restaurants .template-restaurant.current-restaurant {
  border-left: 2px solid #a93e73;
}
.templates .templates-view__restaurants .template-restaurant.current-restaurant .apply-button {
  color: #999999;
  cursor: not-allowed;
}
.templates .templates-view__restaurants .template-restaurant.current-restaurant .apply-button:hover {
  color: #999999;
}
.templates .templates-view__blocks {
  padding: 0 1.2rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.8rem;
}
.templates .templates-view__blocks .template-block:first-of-type {
  margin-top: 0;
}

.template-restaurants {
  display: grid;
  gap: 0.8rem;
  padding-top: 1.2rem;
  grid-template-columns: repeat(2, 2fr);
}

.template-block {
  position: relative;
  background-color: #e0e7e9;
  padding: 1.2rem;
  margin-top: 0.8rem;
  cursor: grab;
  -webkit-transition: box-shadow 200ms linear;
  -moz-transition: box-shadow 200ms linear;
  -ms-transition: box-shadow 200ms linear;
  -o-transition: box-shadow 200ms linear;
  transition: box-shadow 200ms linear;
}
.template-block:hover {
  -webkit-box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.2);
}
.template-block__controls {
  position: absolute;
  top: -1rem;
  right: 0;
  display: flex;
}
.template-block__controls--button {
  cursor: pointer;
  margin-left: 0.4rem;
  border: 1px solid #e7ecef;
  background-color: #ffffff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
}
.template-block__controls--button:hover {
  background-color: #a93e73;
  color: #e7ecef;
}
.template-block__id {
  width: 100%;
  font-weight: 600;
  padding-bottom: 0.8rem;
}
.template-block__image-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.template-block__image-container--image {
  max-width: 100%;
  height: auto;
}

.choose-template {
  font-size: 1.8rem;
  color: #6a6a6a;
  display: grid;
  grid-template-columns: 1fr 30rem;
  align-items: center;
}
.choose-template .om-btn {
  margin-top: 0.4rem;
}
.choose-template .om-btn__icon {
  margin-left: 2.4rem;
}
@media screen and (max-width: 768px) {
  .choose-template {
    grid-template-columns: 1fr;
  }
}

.choose-rest-template {
  display: grid;
  gap: 1.2rem;
  padding-top: 1.2rem;
  grid-template-columns: repeat(2, 1fr);
}
.choose-rest-template .template-restaurant {
  display: flex;
  padding: 1.2rem;
  flex-direction: column;
  justify-content: stretch;
  background-color: #e7ecef;
  -webkit-transition: box-shadow 200ms linear;
  -moz-transition: box-shadow 200ms linear;
  -ms-transition: box-shadow 200ms linear;
  -o-transition: box-shadow 200ms linear;
  transition: box-shadow 200ms linear;
  -webkit-box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
}
.choose-rest-template .template-restaurant__category {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.8rem;
  padding-bottom: 0.2rem;
  border-bottom: 1px solid #d6d6d6;
}
.choose-rest-template .template-restaurant__category .container {
  display: flex;
  align-items: center;
}
.choose-rest-template .template-restaurant__category .text {
  margin-left: 0.4rem;
  font-size: 1.6rem;
}
.choose-rest-template .template-restaurant__description {
  font-size: 1.6rem;
  padding-bottom: 0.8rem;
}
.choose-rest-template .template-restaurant__image {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e63753;
  margin-bottom: 0.8rem;
  background-color: #e0e7e9;
  height: 100%;
}
.choose-rest-template .template-restaurant__image .img {
  max-width: 100%;
  height: auto;
}
.choose-rest-template .template-restaurant__buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.choose-rest-template .template-restaurant__buttons .om-btn:last-of-type {
  margin-left: 1.2rem;
}
.choose-rest-template .template-restaurant:hover {
  -webkit-box-shadow: 0 0 0px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 0px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0px 0 rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 992px) {
  .choose-rest-template {
    grid-template-columns: 1fr;
  }
}

.dictionary {
  height: 100%;
  overflow-y: auto;
}
.dictionary__form {
  display: grid;
  padding: 1.6rem;
  margin: 1.2rem 0;
  column-gap: 1.2rem;
  background-color: #e7ecef;
  border: 0.2rem solid #171717;
  grid-template-columns: repeat(3, 1fr);
}
.dictionary__form .key-name {
  grid-area: 2/1/3/2;
}
.dictionary__form .om-container {
  display: flex;
  grid-column: 2/4;
  column-gap: 1.2rem;
  justify-content: flex-end;
}
.dictionary__list {
  display: grid;
  row-gap: 0.4rem;
  margin-top: 1.2rem;
  grid-template-columns: 1fr;
}
.dictionary__list--element {
  display: grid;
  align-items: center;
  padding-left: 1.2rem;
  border-bottom: 0.2rem solid #e7ecef;
  grid-template-columns: 1fr 1fr 1fr 10rem;
}
.dictionary__list--element .key {
  font-size: 1.2rem;
  word-break: break-all;
  text-align: left;
}
.dictionary__list--element .t {
  font-size: 1.4rem;
  text-align: left;
}
.dictionary__list--element .controls {
  display: flex;
  align-items: center;
  column-gap: 0.4rem;
}
.dictionary__list--element .controls__button {
  width: 4rem;
  height: 4rem;
  color: #e7ecef;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
  background-color: #a93e73;
}
.dictionary__list--element .controls__button.delete {
  background-color: #fb3350;
}
.dictionary__list--element .controls__button:disabled {
  cursor: not-allowed;
  background-color: #ffffff;
}
.dictionary__list .header-element {
  background-color: #e0e7e9;
  font-weight: bold;
}
.dictionary__list .header-element .key {
  font-size: 1.4rem;
}

.iframe-container {
  display: grid;
  height: 100vh;
  align-items: stretch;
  grid-template-columns: 30rem auto;
}
.iframe-container .iframe-editor-view {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.4rem;
}
.iframe-container .iframe-editor-view .container {
  width: 100%;
  height: 100%;
  max-height: 90vh;
  overflow-y: auto;
  max-width: 100rem;
  position: relative;
  padding-top: 4rem;
  border: 3px solid #d529ff;
  background-color: #e0e7e9;
  -webkit-box-shadow: 0 0 12px 4px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 12px 4px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 12px 4px rgba(0, 0, 0, 0.3);
}
.iframe-container.dark-theme-iframe .iframe-editor-view .dark-container {
  color: #e7ecef;
  background-color: #333333;
}
.iframe-container.dark-theme-iframe .iframe-editor-view .dark-container .text-input,
.iframe-container.dark-theme-iframe .iframe-editor-view .dark-container .select-input__search {
  color: #e7ecef;
  background-color: #585858;
}
.iframe-container.dark-theme-iframe .iframe-editor-view .dark-container .text-input::placeholder,
.iframe-container.dark-theme-iframe .iframe-editor-view .dark-container .select-input__search::placeholder {
  color: #c8d4db;
}
.iframe-container.dark-theme-iframe .iframe-editor-view .dark-container .select-input__list {
  background-color: #585858;
}
@media screen and (max-width: 1200px) {
  .iframe-container {
    grid-template-columns: 1fr;
  }
  .iframe-container .iframe-sidemenu {
    position: absolute;
    z-index: 10000;
    width: 30rem;
    bottom: 0;
    left: 0;
    top: 0;
  }
}

.iframe-header {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.iframe-header__top {
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  height: 4rem;
  display: flex;
  color: #e7ecef;
  position: absolute;
  align-items: stretch;
  justify-content: flex-end;
  padding-right: 6.4rem;
  -webkit-box-shadow: 0 2px 2px 0 #00000033;
  -moz-box-shadow: 0 2px 2px 0 #00000033;
  box-shadow: 0 2px 2px 0 #00000033;
  background-image: -moz-linear-gradient(90deg, #ff0154 0%, #490151 100%);
  background-image: -webkit-gradient(90deg, #ff0154 0%, #490151 100%);
  background-image: -webkit-linear-gradient(90deg, #ff0154 0%, #490151 100%);
  background-image: -o-linear-gradient(90deg, #ff0154 0%, #490151 100%);
  background-image: -ms-linear-gradient(90deg, #ff0154 0%, #490151 100%);
  background-image: linear-gradient(90deg, #ff0154 0%, #490151 100%);
}
.iframe-header__top .icon {
  margin-right: 0.8rem;
}
.iframe-header__top--control {
  padding: 0 1.6rem 0 1.2rem;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  height: 100%;
  border: none;
}
.iframe-header__top--control.active {
  background-color: #490151;
  color: #e7ecef;
}
.iframe-header__overlay {
  height: 22rem;
  position: relative;
}
.iframe-header__content {
  grid-template-columns: auto 19rem;
  background-color: #ffffffd6;
  align-items: center;
  position: absolute;
  display: grid;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}
.iframe-header__content--left {
  padding-left: 4.8rem;
}
.iframe-header__content--open {
  align-items: center;
  color: #7de394;
  font-size: 1.6rem;
  font-weight: 600;
  display: flex;
}
.iframe-header__content--open .status-ball {
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
  margin-right: 0.4rem;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: #7de394;
}
.iframe-header__content--name {
  font-weight: 600;
  margin-bottom: 0;
  padding-bottom: 0;
  text-transform: uppercase;
  color: #515151;
}
.iframe-header__content--location {
  font-weight: 600;
  font-size: 1.6rem;
  color: #6a6a6a;
}
.iframe-header__content--right .global__btnv2 .cart-items {
  background-color: #e63753;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-size: 1.2rem;
  color: #e7ecef;
  right: -1.25rem;
  top: -1.25rem;
  display: flex;
  height: 2.5rem;
  width: 2.5rem;
  z-index: 1;
}
@media screen and (max-width: 576px) {
  .iframe-header__top {
    flex-wrap: wrap;
  }
  .iframe-header__top--name {
    width: 100%;
  }
}

.iframe-div {
  display: none;
  background: -moz-radial-gradient(#393939ee 20%, #171717ee 100%);
  background: -webkit-gradient(#393939ee 20%, #171717ee 100%);
  background: -webkit-radial-gradient(#393939ee 20%, #171717ee 100%);
  background: -o-radial-gradient(#393939ee 20%, #171717ee 100%);
  background: -ms-radial-gradient(#393939ee 20%, #171717ee 100%);
  background: radial-gradient(#393939ee 20%, #171717ee 100%);
  width: 100%;
  position: fixed;
  z-index: 100000;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.iframe-div-visible {
  display: flex;
}

.close-iframe {
  top: 1rem;
  right: 1rem;
  width: 4rem;
  height: 4rem;
  border: none;
  z-index: 1001;
  cursor: pointer;
  position: absolute;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: #ff0154;
  -webkit-transition: background-color 200ms linear;
  -moz-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  -o-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
}
.close-iframe:hover {
  background-color: #a93e73;
}

.iframe-embed-code {
  position: relative;
  min-height: 4rem;
  display: grid;
  align-items: stretch;
  grid-template-columns: auto 4rem;
  margin: 0 1.2rem 1.6rem;
  border-bottom: 1px solid #e0e7e9;
}
.iframe-embed-code__link {
  display: flex;
  cursor: pointer;
  color: #e7ecef;
  font-weight: 600;
  font-size: 1.6rem;
  align-items: center;
  background-color: #a93e73;
  padding: 0 1.2rem;
}
.iframe-embed-code__link:focus + .iframe-div {
  display: flex;
}
.iframe-embed-code__copy {
  background-color: #ff0154;
  color: #e7ecef;
  border: none;
  cursor: pointer;
  -webkit-transition: background-color 200ms linear;
  -moz-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  -o-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
}
.iframe-embed-code__copy:hover {
  background-color: #e7004b;
}
.iframe-embed-code__copied {
  left: 0;
  right: 0;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  -webkit-box-shadow: 2px 2px 6px 0 #000000aa;
  -moz-box-shadow: 2px 2px 6px 0 #000000aa;
  box-shadow: 2px 2px 6px 0 #000000aa;
  background-color: #ffd750;
  color: #171717;
  -webkit-animation: float-down 3s linear 0s 1 normal forwards;
  -moz-animation: float-down 3s linear 0s 1 normal forwards;
  -ms-animation: float-down 3s linear 0s 1 normal forwards;
  -o-animation: float-down 3s linear 0s 1 normal forwards;
  animation: float-down 3s linear 0s 1 normal forwards;
}

@keyframes float-down {
  0% {
    top: -10rem;
    opacity: 0;
  }
  10% {
    top: 0rem;
    opacity: 1;
  }
  100% {
    top: 0rem;
    opacity: 1;
  }
}
.dark-theme-iframe .iframe-header__content {
  background-color: #000000d6;
}
.dark-theme-iframe .iframe-header__content--name {
  color: #c2cfd7;
}
.dark-theme-iframe .iframe-header__content--location {
  color: #999999;
}
.dark-theme-iframe .iframe-editor-view .container .checkbox-container .check-mark {
  border-color: #ffffff;
}

.rounded-corners .iframe-editor-view .container .text-input,
.rounded-corners .iframe-editor-view .container .select-input__search {
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
  font-size: 1.6rem;
}
.rounded-corners .iframe-editor-view .container .text-input-unit {
  -webkit-border-top-right-radius: 1rem;
  -webkit-border-bottom-right-radius: 1rem;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topright: 1rem;
  -moz-border-radius-bottomright: 1rem;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-topleft: 0;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.rounded-corners .iframe-editor-view .container .select-input__list {
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
  padding: 1.6rem 0;
}

.iframe-products .topbar {
  z-index: 1;
  width: 100%;
  color: #171717;
  background-color: #ffffff;
  border-top: 2px solid #d6d6d6;
  border-bottom: 2px solid #d6d6d6;
}
.iframe-products .topbar .hash-link {
  align-self: center;
  position: relative;
  padding: 0.8rem 0.8rem;
  white-space: nowrap;
  font-size: 1.4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  cursor: pointer;
}
.iframe-products .topbar .hash-link.active-hash {
  font-weight: 600;
}
.iframe-products .topbar .hash-link .sub-categories {
  display: flex;
  align-items: center;
}
.iframe-products .topbar .hash-link .sub-categories .hash-link {
  font-weight: 400;
  padding: 0 0.8rem;
}
.iframe-products .topbar .hash-link .sub-categories .hash-link.active-hash {
  font-weight: 600;
}
.iframe-products .topbar__container {
  max-width: 120rem;
  margin: 0 auto;
  display: grid;
  align-items: center;
  padding-left: 2.8rem;
  grid-template-columns: 1fr 4rem;
}
.iframe-products .topbar__container--control {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}
.iframe-products .topbar__container--categories {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  max-width: calc(120rem - 4rem);
  overflow-x: auto;
  overflow-y: visible;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.iframe-products .topbar__container--categories .hash-link {
  padding: 1.6rem 1.2rem;
}
.iframe-products .topbar__container--categories::-webkit-scrollbar {
  display: none;
}
.iframe-products .topbar__category-menu {
  position: absolute;
  top: 2.5rem;
  left: 1rem;
  background-color: #ffffff;
  color: #171717;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  z-index: 2;
  width: 30rem;
  min-height: 30rem;
  max-height: 30rem;
  border: 3px solid #e7ecef;
}
.iframe-products .topbar__category-menu .content {
  overflow-y: auto;
}
.iframe-products .topbar__category-menu .hash-link {
  width: 100%;
  flex-wrap: wrap;
  text-align: left;
}
.iframe-products .topbar__category-menu .hash-link .sub-categories {
  width: 100%;
  flex-direction: column;
}
.iframe-products .topbar__category-menu .hash-link .sub-categories .hash-link {
  display: grid;
  padding: 0.8rem;
  white-space: pre-wrap;
  grid-template-columns: 1.6rem auto;
}
.iframe-products .topbar__category-menu .hash-link:hover {
  background-color: #d6d6d6;
}
.iframe-products .topbar.topbar-dark {
  color: #e7ecef;
  background-color: #171717;
  border-top: 2px solid #393939;
  border-bottom: 2px solid #393939;
}
.iframe-products .topbar.topbar-dark .topbar__category-menu {
  border-color: #585858;
  background-color: #333333;
  color: #e7ecef;
}
.iframe-products .topbar.topbar-dark .topbar__category-menu .hash-link:hover,
.iframe-products .topbar.topbar-dark .topbar__category-menu .hash-link:hover {
  background-color: #585858;
}
.iframe-products .topbar.rounded-topbar .topbar__category-menu {
  padding: 1.6rem 0;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  border-radius: 2rem;
}
.iframe-products .iframe-products-container {
  width: 100%;
  background-color: #ffffff;
  padding: 2.4rem 2.4rem 6.4rem;
}
.iframe-products .iframe-products-container .category {
  max-width: 100%;
  margin-bottom: 2.4rem;
}
.iframe-products .iframe-products-container .category__name {
  margin-bottom: 0.4rem;
  text-transform: uppercase;
  font-weight: 600;
}
.iframe-products .iframe-products-container .category__description {
  font-weight: 400;
  font-style: italic;
  font-size: 1.4rem;
  color: #999999;
  margin-bottom: 1.2rem;
}
.iframe-products .iframe-products-container .category__products {
  display: grid;
  gap: 1.6rem;
  grid-template-columns: repeat(2, 1fr);
}
.iframe-products .iframe-products-container .category.category-with-parent .category__name {
  font-size: 1.8rem;
  margin-bottom: 0.8rem;
}
.iframe-products .iframe-products-container .product-element {
  display: grid;
  cursor: pointer;
  padding: 0.8rem;
  border: 1px solid #d6d6d6;
  grid-template-columns: auto 8rem;
  grid-template-rows: 3rem 2.5rem 2.6rem;
  -webkit-transition: background-color 200ms linear;
  -moz-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  -o-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
}
.iframe-products .iframe-products-container .product-element:hover {
  background-color: #e7ecef;
}
.iframe-products .iframe-products-container .product-element__name {
  grid-area: 1/1/2/2;
  font-size: 1.6rem;
  padding-right: 0.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.iframe-products .iframe-products-container .product-element__description {
  padding-right: 0.8rem;
  color: #6a6a6a;
  font-size: 1.4rem;
  grid-area: 2/1/3/2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.iframe-products .iframe-products-container .product-element__price {
  grid-area: 3/1/4/2;
  font-weight: 500;
  font-size: 1.6rem;
}
.iframe-products .iframe-products-container .product-element__image {
  grid-area: 1/2/4/3;
  max-width: 8rem;
  max-height: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media screen and (max-width: 992px) {
  .iframe-products .iframe-products-container .category__products {
    grid-template-columns: 1fr;
  }
}

.rounded-corners .iframe-products .rounded-topbar .topbar__category-menu {
  padding: 1.6rem 0;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  border-radius: 2rem;
}
.rounded-corners .iframe-products .iframe-products-container .product-element {
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
}
.rounded-corners .iframe-products .iframe-products-container .product-element__image {
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
}
.rounded-corners .iframe-products .iframe-products-container .product-element__cart-button {
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  border-radius: 2rem;
}

.dark-container .iframe-products .topbar-dark {
  color: #e7ecef;
  background-color: #333333;
  border-top: 2px solid #393939;
  border-bottom: 2px solid #393939;
}
.dark-container .iframe-products .topbar-dark .topbar__category-menu {
  border-color: #585858;
  background-color: #333333;
  color: #e7ecef;
}
.dark-container .iframe-products .topbar-dark .topbar__category-menu .hash-link:hover,
.dark-container .iframe-products .topbar-dark .topbar__category-menu .hash-link:hover {
  background-color: #585858;
}
.dark-container .iframe-products .iframe-products-container {
  background-color: #333333;
}
.dark-container .iframe-products .iframe-products-container .category__description {
  color: #bfbfbf;
}
.dark-container .iframe-products .iframe-products-container .product-element {
  border-color: #585858;
}
.dark-container .iframe-products .iframe-products-container .product-element:hover {
  background-color: #171717;
}
.dark-container .iframe-products .iframe-products-container .product-element__description {
  color: #bfbfbf;
}

.iframe-sidemenu {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #ffffff;
  transform: translateX(0%);
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
  border-right: 1px solid #a93e73;
  -webkit-transition: transform 300ms linear;
  -moz-transition: transform 300ms linear;
  -ms-transition: transform 300ms linear;
  -o-transition: transform 300ms linear;
  transition: transform 300ms linear;
  -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.4);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.4);
}
.iframe-sidemenu .iframe-sidemenu-toggler {
  border: 2px solid #a93e73;
  background-color: #a93e73;
  justify-content: center;
  align-items: center;
  position: absolute;
  border-left: none;
  cursor: pointer;
  color: #e7ecef;
  display: none;
  height: 8rem;
  right: -3rem;
  width: 3rem;
  top: 45%;
}
.iframe-sidemenu .iframe-sidemenu-toggler:hover {
  background-color: #ffffff;
  color: #a93e73;
}
.iframe-sidemenu__title {
  -webkit-box-shadow: 0 4px 5px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 4px 5px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 5px 1px rgba(0, 0, 0, 0.2);
  justify-content: space-between;
  margin-bottom: 2.4rem;
  background-color: #e7ecef;
  padding: 1.2rem;
  align-items: center;
  text-align: left;
  font-weight: 500;
  color: #171717;
  display: flex;
  width: 100%;
}
.iframe-sidemenu__title .info-icon {
  margin-right: 0.4rem;
  justify-content: center;
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: default;
}
.iframe-sidemenu__button {
  width: 100%;
}
.iframe-sidemenu__toolbox {
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  justify-content: center;
}
.iframe-sidemenu__toolbox-content {
  top: 2.3rem;
  z-index: 10;
  width: 25rem;
  right: 0.3rem;
  display: none;
  cursor: default;
  position: absolute;
  align-items: flex-end;
  flex-direction: column;
  background-color: #ffffff;
  padding-bottom: 1.2rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
  -webkit-box-shadow: 0 0 1rem 0.2rem #00000044;
  -moz-box-shadow: 0 0 1rem 0.2rem #00000044;
  box-shadow: 0 0 1rem 0.2rem #00000044;
}
.iframe-sidemenu__toolbox--head {
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  padding-left: 1.2rem;
  margin-bottom: 0.4rem;
  justify-content: space-between;
  border-bottom: 1px solid #e7ecef;
}
.iframe-sidemenu__toolbox--head .close {
  width: 4rem;
  height: 100%;
  background-color: #e7ecef;
  -webkit-border-top-right-radius: 1rem;
  -webkit-border-bottom-right-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topright: 1rem;
  -moz-border-radius-bottomright: 0;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-topleft: 0;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.iframe-sidemenu__toolbox--head .close:hover {
  color: #e63753;
}
.iframe-sidemenu__toolbox--row {
  width: 100%;
  padding: 0 0.8rem;
}
.iframe-sidemenu__toolbox--row .long-select__options {
  -webkit-box-shadow: 0 0 1rem 0.2rem #00000044;
  -moz-box-shadow: 0 0 1rem 0.2rem #00000044;
  box-shadow: 0 0 1rem 0.2rem #00000044;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
  padding: 0.8rem 0;
  top: 6.5rem;
}
.iframe-sidemenu__toolbox--row .long-select__options--item {
  font-size: 1.4rem;
}
.iframe-sidemenu__toolbox--row .wrapper .text-input-unit {
  -webkit-border-top-right-radius: 1rem;
  -webkit-border-bottom-right-radius: 1rem;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topright: 1rem;
  -moz-border-radius-bottomright: 1rem;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-topleft: 0;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border-bottom: 1px solid #d6d6d6;
}
.iframe-sidemenu__toolbox--row.logout-button {
  height: 4rem;
  display: flex;
  color: #e7ecef;
  align-items: center;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
  background-color: #fb3350;
  justify-content: space-between;
}
.iframe-sidemenu__toolbox--row.lang-row {
  display: flex;
  font-size: 1.6rem;
  justify-content: center;
  padding: 0.8rem 0.8rem 0.8rem;
}
.iframe-sidemenu__toolbox--lang {
  font-size: 1.6rem;
  margin: 0 0.8rem;
  background-color: transparent;
}
.iframe-sidemenu__toolbox--lang:hover {
  color: #a93e73;
}
.iframe-sidemenu__info {
  -webkit-box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.4);
  box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.4);
  border: 2px solid #a93e73;
  -webkit-border-radius: 1.5rem;
  -moz-border-radius: 1.5rem;
  border-radius: 1.5rem;
  background-color: #ffffff;
  padding: 1.2rem;
  position: absolute;
  font-style: italic;
  font-size: 1.6rem;
  color: #6a6a6a;
  width: 30rem;
  left: -1rem;
  z-index: 1;
  top: 3.5rem;
}
.iframe-sidemenu__info::before {
  width: 0;
  height: 0;
  z-index: 2;
  top: -1.8rem;
  left: 0.5rem;
  content: " ";
  display: block;
  position: absolute;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
  border-bottom: 1.8rem solid #a93e73;
}
.iframe-sidemenu__footer {
  display: grid;
  grid-template-rows: 5.5rem;
  grid-template-columns: repeat(2, 1fr);
}
.iframe-sidemenu__footer--control {
  display: flex;
  color: #e7ecef;
  font-size: 1.2rem;
  align-items: center;
  background-color: #585858;
  justify-content: flex-start;
  padding: 0.8rem 1.2rem;
}
.iframe-sidemenu__footer .om-btn {
  height: 100%;
  max-height: 100%;
}
.iframe-sidemenu .iframe-editor {
  width: 100%;
  overflow-y: auto;
  padding-bottom: 2.4rem;
  height: calc(100vh - 17rem);
}
.iframe-sidemenu .iframe-editor-group {
  padding: 0 1.2rem;
  margin-bottom: 1.2rem;
  width: 100%;
}
.iframe-sidemenu .iframe-editor-group .image-input {
  padding: 0;
}
.iframe-sidemenu .iframe-editor-group__head {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e0e7e9;
}
.iframe-sidemenu .iframe-editor-group__head--name {
  font-weight: 600;
  font-size: 1.6rem;
}
.iframe-sidemenu .iframe-editor-group__body {
  padding: 0 0.4rem;
  overflow-y: hidden;
  height: 0;
}
.iframe-sidemenu .iframe-editor-group.active-group .iframe-editor-group__head {
  border-bottom: none;
}
.iframe-sidemenu .iframe-editor-group.active-group .iframe-editor-group__body {
  height: auto;
  overflow: unset;
  border-bottom: 1px solid #e0e7e9;
}
@media screen and (max-width: 1200px) {
  .iframe-sidemenu .iframe-sidemenu-toggler {
    display: flex;
  }
  .iframe-sidemenu.iframe-sidemenu-hidden {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
  }
}

.toolbox-visible .iframe-sidemenu__toolbox-content {
  display: flex;
}

.original-version .iframe-editor {
  height: calc(100vh - 13.5rem);
  padding: 0 2.4rem;
  text-align: justify;
  font-style: italic;
  font-size: 1.6rem;
}
.original-version .iframe-editor .iframe-test {
  padding: 1.2rem 0 0;
}
.original-version .iframe-editor .iframe-test .iframe-embed-code {
  margin: 0;
}
.original-version .iframe-sidemenu__title {
  padding-left: 2.4rem;
}
.original-version .iframe-sidemenu__button {
  min-height: 6rem;
  padding: 0 0.8rem;
}

.frame-info__map-container {
  padding: 2.4rem 2.4rem 0;
  position: relative;
  width: 100%;
}
.frame-info__map-container--map {
  width: 100%;
  height: 30rem;
}
.frame-info__grid {
  display: grid;
  padding: 2.4rem;
  align-items: flex-start;
  gap: 2.4rem 6.4rem;
  grid-template-columns: repeat(2, 1fr);
}
.frame-info__grid--item--title {
  display: flex;
  text-align: left;
  align-items: center;
  margin-bottom: 0.8rem;
  padding-bottom: 0.4rem;
  border-bottom: 1px solid #d6d6d6;
}
.frame-info__grid--item--title .icon {
  margin-right: 0.8rem;
}
.frame-info__grid--item .list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.frame-info__grid--item .list__item {
  display: flex;
  align-items: center;
  padding-left: 1.2rem;
  padding-bottom: 0.4rem;
}
.frame-info__grid--item .list__item .icon {
  margin-right: 1.2rem;
}
.frame-info__grid--item .list__item.crossed-out {
  color: #6a6a6a;
  text-decoration: line-through;
}
.frame-info__grid--item .list.no-space .list__item {
  padding-bottom: 0;
}
@media screen and (max-width: 992px) {
  .frame-info__grid {
    grid-template-columns: 1fr;
  }
}

.dark-container .frame-info__grid--item .list .crossed-out {
  color: #999999;
}

.frame-cart {
  padding: 2.4rem 2.4rem 6.4rem;
}
.frame-cart .cart__container {
  grid-template-columns: 35rem auto;
  max-width: 100%;
  padding: 0;
  margin: 0;
}
.frame-cart .cart__container .cart-block {
  background-color: #ffffff;
  -webkit-box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.1);
}
.frame-cart .cart__items--footer {
  border-right-color: #490151;
}
.frame-cart .cart__items--coupon .global__btnv2 {
  height: 4.1rem;
}
.frame-cart .cart__items--coupon .global__btnv2__icon--triangle {
  border-top-width: 4.1rem;
}
.frame-cart .cart__finalize-button-container .global__btnv2 {
  height: 4.1rem;
  width: 100%;
  grid-template-columns: auto 4.1rem;
}
.frame-cart .cart__finalize-button-container .global__btnv2__icon--triangle {
  border-top-width: 4.1rem;
}
@media screen and (max-width: 920px) {
  .frame-cart .cart__container {
    grid-template-columns: 1fr;
    max-width: 55rem;
    margin: 0 auto;
  }
  .frame-cart .cart__container .cart__registration .registration__block {
    grid-template-columns: repeat(2, 1fr);
  }
  .frame-cart .cart__container .cart__registration .registration__block .city,
.frame-cart .cart__container .cart__registration .registration__block .email,
.frame-cart .cart__container .cart__registration .registration__block .street,
.frame-cart .cart__container .cart__registration .registration__block .is_take_away,
.frame-cart .cart__container .cart__registration .registration__block .phone_number,
.frame-cart .cart__container .cart__registration .registration__block .reg_with_order,
.frame-cart .cart__container .cart__registration .registration__block .registration__title {
    grid-column: 1/3;
  }
  .frame-cart .cart__container .cart__registration .registration__block .first_name,
.frame-cart .cart__container .cart__registration .registration__block .premise_number,
.frame-cart .cart__container .cart__registration .registration__block .door_number {
    grid-column: 1/2;
  }
  .frame-cart .cart__container .cart__registration .registration__block .last_name,
.frame-cart .cart__container .cart__registration .registration__block .floor,
.frame-cart .cart__container .cart__registration .registration__block .doorbell {
    grid-column: 2/3;
  }
  .frame-cart .cart__container .cart__items {
    margin-top: 1.6rem;
  }
  .frame-cart .cart__container .cart__items--footer {
    align-items: stretch;
    flex-direction: column;
  }
  .frame-cart .cart__container .cart__items--footer .price-container {
    padding-top: 1.6rem;
    justify-content: space-between;
  }
  .frame-cart .cart__container .cart__items--coupon {
    align-items: stretch;
    grid-template-columns: auto 22rem;
  }
  .frame-cart .cart__container .cart__items--coupon .wrapper .text-input {
    height: 100%;
  }
  .frame-cart .cart__container .cart__dmca {
    grid-template-columns: 1fr;
    row-gap: 0.8rem;
  }
  .frame-cart .cart__container .cart__radio-row--child {
    padding-top: 0.4rem;
    grid-area: 2/1/3/3;
  }
  .frame-cart .cart__container .cart__radio-row.with-children {
    padding-top: 0.8rem;
    grid-template-columns: 3rem auto;
  }
}
@media screen and (max-width: 576px) {
  .frame-cart .cart__container .cart__items .cart-item {
    grid-template-columns: 10rem auto;
    border-bottom: 2px solid #e0e7e9;
    padding-bottom: 0.4rem;
    margin-bottom: 1.2rem;
  }
  .frame-cart .cart__container .cart__items .cart-item__apply {
    grid-area: 2/1/3/2;
  }
  .frame-cart .cart__container .cart__items .cart-item__price {
    grid-area: 2/1/3/2;
  }
  .frame-cart .cart__container .cart__items .cart-item__name {
    font-size: 1.2rem;
    padding-bottom: 0.8rem;
  }
  .frame-cart .cart__container .cart__items .cart-item__name--options {
    font-size: 1.1rem;
  }
  .frame-cart .cart__container .cart__items .cart-item__control {
    justify-self: flex-end;
    grid-area: 2/2/3/3;
  }
  .frame-cart .cart__container .cart__items--coupon {
    grid-template-columns: 1fr;
  }
  .frame-cart .cart__container .cart__items--coupon .wrapper {
    margin-bottom: 1.6rem;
  }
  .frame-cart .cart__container .cart__items--coupon .wrapper .text-input {
    min-height: 4rem;
  }
}

.rounded-corners .frame-cart .cart__container .cart-block {
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
}
.rounded-corners .frame-cart .frame-cart-items {
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
}
.rounded-corners .frame-cart .frame-cart-items__header {
  -webkit-border-top-right-radius: 1rem;
  -webkit-border-bottom-right-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-top-left-radius: 1rem;
  -moz-border-radius-topright: 1rem;
  -moz-border-radius-bottomright: 0;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-topleft: 1rem;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 1rem;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.rounded-corners .frame-cart .frame-cart-items__summary {
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 1rem;
  -webkit-border-bottom-left-radius: 1rem;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 1rem;
  -moz-border-radius-bottomleft: 1rem;
  -moz-border-radius-topleft: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  border-top-left-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

.dark-theme-iframe .frame-cart .cart__container .cart__items .cart-item__control:hover {
  background-color: #585858;
}
.dark-theme-iframe .frame-cart .cart__container .cart-block {
  background-color: #393939;
}

.iframe-profile .profile__addresses--form--controls .global__btnv2 {
  margin-left: 1.2rem !important;
}
.iframe-profile .profile__my-data-block .global__btnv2 {
  width: 100%;
  max-width: 35.2rem;
}

.app-container {
  display: grid;
  height: 100vh;
  align-items: stretch;
  grid-template-columns: 30rem auto;
}
.app-container .app-editor-view {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.4rem;
}
.app-container .app-editor-view .container {
  width: 100%;
  height: 100%;
  max-height: 85vh;
  overflow: hidden;
  max-width: 43rem;
  position: relative;
  border: 3px solid #d529ff;
  background-color: #e0e7e9;
  padding-top: 7rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
  -webkit-box-shadow: 0 0 12px 4px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 12px 4px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 12px 4px rgba(0, 0, 0, 0.3);
}
.app-container .app-editor-view .container .app-sub-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: relative;
  background-color: transparent;
}
.app-container.dark-theme-app .app-editor-view .dark-container {
  color: #e7ecef;
  background-color: #393939;
}
.app-container.dark-theme-app .app-editor-view .dark-container .text-input,
.app-container.dark-theme-app .app-editor-view .dark-container .select-input__search {
  color: #e7ecef;
  background-color: #585858;
}
.app-container.dark-theme-app .app-editor-view .dark-container .text-input::placeholder,
.app-container.dark-theme-app .app-editor-view .dark-container .select-input__search::placeholder {
  color: #c8d4db;
}
.app-container.dark-theme-app .app-editor-view .dark-container .select-input__list {
  background-color: #585858;
}
@media screen and (max-width: 768px) {
  .app-container {
    grid-template-columns: 1fr;
  }
  .app-container .app-sidemenu {
    position: absolute;
    z-index: 10000;
    width: 30rem;
    bottom: 0;
    left: 0;
    top: 0;
  }
}

.app-navbar {
  -webkit-box-shadow: 0 3px 3px 0 #00000044;
  -moz-box-shadow: 0 3px 3px 0 #00000044;
  box-shadow: 0 3px 3px 0 #00000044;
  justify-content: space-between;
  padding-right: 1.2rem;
  background-color: #ffffff;
  height: 7rem;
  align-items: center;
  position: absolute;
  color: #171717;
  display: flex;
  z-index: 101;
  width: 100%;
  right: 0;
  left: 0;
  top: 0;
}
.app-navbar__control {
  width: 5rem;
  border: none;
  color: inherit;
  cursor: pointer;
  position: relative;
  align-items: center;
  display: inline-flex;
  height: 7rem;
  justify-content: center;
  background-color: transparent;
}
.app-navbar__control--item-count {
  background-color: #fb3350;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-weight: 600;
  color: #e7ecef;
  height: 2.5rem;
  display: flex;
  width: 2.5rem;
  right: -0.5rem;
  top: 0.7rem;
}
.app-navbar__name {
  align-items: center;
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  display: flex;
}
.app-navbar__name--logo {
  margin-right: 0.8rem;
  max-height: 5.5rem;
  width: auto;
}
.app-navbar.dark-navbar {
  background-color: #171717;
  color: #e7ecef;
}
.app-navbar.--NO_SHADOW {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
.app-navbar.--WITH_QR_SCANNER {
  display: grid;
  justify-content: unset;
  grid-template-columns: 5rem auto 5rem 5rem;
}
.app-navbar.--WITH_QR_SCANNER .app-navbar__name {
  justify-content: center;
}

.mobileapp-sidemenu {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  width: 100%;
  z-index: 102;
  display: block;
  color: #171717;
  position: absolute;
  pointer-events: none;
  background-color: #00000099;
  -webkit-transition: opacity 200ms linear;
  -moz-transition: opacity 200ms linear;
  -ms-transition: opacity 200ms linear;
  -o-transition: opacity 200ms linear;
  transition: opacity 200ms linear;
}
.mobileapp-sidemenu .content {
  height: 100%;
  display: flex;
  overflow-y: auto;
  width: 300px;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  flex-direction: column;
  background-color: #ffffff;
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  -webkit-transition: transform 200ms linear;
  -moz-transition: transform 200ms linear;
  -ms-transition: transform 200ms linear;
  -o-transition: transform 200ms linear;
  transition: transform 200ms linear;
}
.mobileapp-sidemenu__image {
  padding: 1.2rem;
  max-width: 100%;
  margin: 0 auto;
  height: auto;
}
.mobileapp-sidemenu__link {
  margin: 0;
  border: none;
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 0.8rem 1.2rem;
}
.mobileapp-sidemenu__link .text {
  margin-left: 1.2rem;
  font-size: 1.6rem;
  font-weight: 600;
}
.mobileapp-sidemenu__link.--LOGOUT, .mobileapp-sidemenu__link.--LOGIN {
  background-color: #e7ecef;
  margin-top: 2.4rem;
  color: #6a6a6a;
}
.mobileapp-sidemenu.--OPENED {
  pointer-events: all;
  opacity: 1;
}
.mobileapp-sidemenu.--OPENED .content {
  transform: translateX(0%);
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
}
.mobileapp-sidemenu.dark-sidemenu {
  color: #e7ecef;
}
.mobileapp-sidemenu.dark-sidemenu .content {
  background-color: #333333;
  color: #e7ecef;
}
.mobileapp-sidemenu.dark-sidemenu .mobileapp-sidemenu__link.--LOGIN, .mobileapp-sidemenu.dark-sidemenu .mobileapp-sidemenu__link.--LOGOUT {
  background-color: #393939;
}

.dark-theme-app .app-editor-view .container .checkbox-container .check-mark {
  border-color: #ffffff;
}

.rounded-corners .app-editor-view .container .text-input,
.rounded-corners .app-editor-view .container .select-input__search {
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
  font-size: 1.6rem;
}
.rounded-corners .app-editor-view .container .select-input__list {
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
  padding: 1.6rem 0;
}
.rounded-corners .text-input-unit {
  -webkit-border-top-right-radius: 1rem;
  -webkit-border-bottom-right-radius: 1rem;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topright: 1rem;
  -moz-border-radius-bottomright: 1rem;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-topleft: 0;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

.app-products {
  height: 100%;
  overflow-y: auto;
}
.app-products .app-categories-container {
  padding: 1.6rem;
  min-height: 100%;
}
.app-products .app-categories-container .app-category {
  display: grid;
  cursor: pointer;
  align-items: center;
  background-color: #ffffff;
  margin-bottom: 1.6rem;
  grid-template-rows: 10rem auto;
  grid-template-columns: 10rem auto;
  -webkit-box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
}
.app-products .app-categories-container .app-category__image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.app-products .app-categories-container .app-category__name {
  padding: 1.2rem;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  font-size: 2.4rem;
  font-weight: 600;
}
.app-products .app-categories-container .app-category__description {
  font-size: 1.4rem;
  font-weight: 400;
  padding: 1.2rem;
  padding-top: 0;
  line-height: 1.8rem;
}
.app-products .app-categories-container .app-category.--DESCRIPTION_VISIBLE {
  grid-template-rows: auto auto;
}
.app-products .app-categories-container .app-category.--DESCRIPTION_VISIBLE .app-category__image {
  grid-row: 1/3;
}
.app-products .app-categories-container .app-category.--DESCRIPTION_VISIBLE .app-category__name {
  padding-bottom: 0.2rem;
}
.app-products .app-products-container {
  padding: 1.6rem;
  min-height: 100%;
}
.app-products .app-products-container__category {
  margin-bottom: 1.6rem;
  font-size: 2rem;
  font-weight: 600;
}
.app-products .app-products-container .app-product {
  display: grid;
  cursor: pointer;
  min-height: 16rem;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  align-items: flex-start;
  column-gap: 1.2rem;
  background-color: #ffffff;
  margin-bottom: 1.6rem;
  padding-right: 1.2rem;
  grid-template-columns: 16rem auto;
  -webkit-box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
}
.app-products .app-products-container .app-product__image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.app-products .app-products-container .app-product__content {
  padding: 1.2rem 1.2rem 3.8rem 0;
  position: relative;
  height: 100%;
}
.app-products .app-products-container .app-product__content--name {
  padding-bottom: 1.2rem;
  position: relative;
  font-size: 2rem;
  font-weight: 600;
}
.app-products .app-products-container .app-product__content--name--underline {
  -webkit-border-radius: 10rem;
  -moz-border-radius: 10rem;
  border-radius: 10rem;
  position: absolute;
  height: 0.3rem;
  width: 80%;
  bottom: 0.6rem;
  left: 0;
}
.app-products .app-products-container .app-product__content--description {
  font-size: 1.2rem;
}
.app-products .app-products-container .app-product__content--price {
  left: 0;
  right: 0;
  bottom: 1rem;
  display: flex;
  position: absolute;
  justify-content: flex-start;
  -webkit-box-shadow: 0 -1.5rem 1.5rem 0.9rem #ffffff;
  -moz-box-shadow: 0 -1.5rem 1.5rem 0.9rem #ffffff;
  box-shadow: 0 -1.5rem 1.5rem 0.9rem #ffffff;
}
.app-products .app-products-container .app-product__content--price .crossed {
  text-decoration: line-through;
  padding-right: 0.4rem;
  font-weight: 400;
  font-size: 1.2rem;
}
.app-products .app-products-container .app-product__content--price .price-label {
  font-weight: 500;
  background-color: #e7ecef;
  padding: 0.4rem 1.2rem;
}
.app-products .app-products-container.--DESCRIPTION_VISIBLE .app-products-container__category .description {
  display: block;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6rem;
}
.app-products .product-container {
  grid-template-rows: 45% 55%;
  background-color: #ffffff;
  align-items: flex-start;
  position: absolute;
  overflow-y: auto;
  display: grid;
  bottom: 7rem;
  z-index: 103;
  width: 100%;
  right: 0;
  left: 0;
  top: 0;
}
.app-products .product-container__image {
  -webkit-transition: height 400ms linear;
  -moz-transition: height 400ms linear;
  -ms-transition: height 400ms linear;
  -o-transition: height 400ms linear;
  transition: height 400ms linear;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  height: 100%;
  width: 100%;
}
.app-products .product-container__control {
  background-color: transparent;
  justify-content: center;
  align-items: center;
  pointer-events: all;
  color: #e7ecef;
  cursor: pointer;
  height: 4.4rem;
  width: 4.4rem;
  display: flex;
  z-index: 2;
  padding: 0;
  margin: 0;
}
.app-products .product-container__control--view-image {
  right: 1rem;
  bottom: 1rem;
  position: absolute;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
  -webkit-box-shadow: inset 0 0 4rem 0rem #00000088;
  -moz-box-shadow: inset 0 0 4rem 0rem #00000088;
  box-shadow: inset 0 0 4rem 0rem #00000088;
}
.app-products .product-container .product {
  align-items: flex-start;
  padding: 1.6rem 1.6rem 3.2rem;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  color: #171717;
}
.app-products .product-container .product__control {
  margin: 0;
  padding: 0;
  width: 3.2rem;
  display: flex;
  height: 3.2rem;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}
.app-products .product-container .product__back-button {
  color: #e7ecef;
  justify-self: flex-start;
  background-color: transparent;
}
.app-products .product-container .product__cart {
  justify-self: flex-end;
}
.app-products .product-container .product__name {
  font-weight: 600;
  line-height: 3.5rem;
  min-height: 4rem;
  text-transform: capitalize;
}
.app-products .product-container .product__description {
  display: flex;
  text-align: left;
  min-height: 4rem;
  align-items: center;
}
.app-products .product-container .product__view {
  justify-self: flex-end;
}
.app-products .product-container .product .global__quantity-hook {
  justify-self: flex-start;
}
.app-products .product-container .product .global__quantity-hook--qty {
  font-size: 2.4rem;
  font-weight: 600;
  width: 4rem;
}
.app-products .product-container .product .global__quantity-hook--btn {
  width: 4rem;
  height: 4rem;
}
.app-products .product-container .product__price {
  cursor: pointer;
  font-weight: 600;
  align-items: flex-end;
  margin-bottom: 1.2rem;
  padding-bottom: 0.4rem;
  border-bottom: 1px solid #e7ecef;
}
.app-products .product-container .product__price .price {
  display: flex;
  font-size: 2rem;
  align-items: flex-end;
  justify-content: flex-start;
}
.app-products .product-container .product__price .price .crossed {
  text-decoration: line-through;
  padding-bottom: 0.4rem;
  padding-right: 0.8rem;
  font-size: 1.2rem;
}
.app-products .product-container .product__row {
  display: grid;
  grid-template-columns: 63% 37%;
}
.app-products .product-container .product__row.--row2 {
  align-items: center;
  padding-bottom: 1.2rem;
}
.app-products .product-container .product__row.--row2 .wrapper {
  margin-bottom: 0;
}
.app-products .product-container .product__row.--row2 .dom__quantity-hook {
  height: 4rem;
}
.app-products .product-container .product__row.--row3 {
  grid-template-columns: 1fr;
  align-items: center;
  padding-bottom: 1.6rem;
  grid-template-columns: auto 4rem;
}
.app-products .product-container .product.--with-subproducts .global__quantity-hook {
  justify-self: flex-end;
}
.app-products .product-container .product .app-options {
  grid-column: 1/3;
}
.app-products .product-container .product .app-options__toggler {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.app-products .product-container .product .app-options__toggler--text {
  font-weight: 600;
  font-size: 1.6rem;
}
.app-products .product-container .product .app-options .grid-row-placeholder {
  font-size: 1.6rem;
  text-align: center;
  padding: 1.2rem;
  border-top: 1px solid #d6d6d6;
}
.app-products .product-container .product .app-options .grid-row-placeholder.--with-spacing {
  padding-bottom: 12rem;
}
.app-products .product-container .product .app-options .option-groups {
  padding: 1.2rem 0;
  border-top: 1px solid #d6d6d6;
}
.app-products .product-container .product .app-options .option-groups .option-group:last-of-type {
  padding-bottom: 0;
}
.app-products .product-container .product .app-options .option-groups .option-group__options {
  grid-template-columns: repeat(2, 1fr);
}
.app-products .product-container .product .app-options .option-groups .option-group__options--header {
  grid-column: 1/3;
}
.app-products .product-container .product .app-options .option-groups .option-group__options .selectable-card {
  min-height: 4.7rem;
}
.app-products .product-add-to-cart {
  -webkit-box-shadow: 0 -30px 20px 5px #ffffffff;
  -moz-box-shadow: 0 -30px 20px 5px #ffffffff;
  box-shadow: 0 -30px 20px 5px #ffffffff;
  justify-content: flex-end;
  background-color: #ffffff;
  align-items: flex-start;
  padding: 0 1.2rem;
  height: 7rem;
  position: absolute;
  display: flex;
  z-index: 104;
  bottom: 0;
  right: 0;
  left: 0;
}
.app-products .product-add-to-cart .price {
  display: inline-block;
}
.app-products .product-add-to-cart .price .crossed,
.app-products .product-add-to-cart .price .inline {
  display: none;
}

.rounded-corners .app-categories-container .app-category {
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
}
.rounded-corners .app-categories-container .app-category__image {
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  -webkit-border-bottom-left-radius: 1rem;
  -webkit-border-top-left-radius: 1rem;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 0;
  -moz-border-radius-bottomleft: 1rem;
  -moz-border-radius-topleft: 1rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 1rem;
  border-top-left-radius: 1rem;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.rounded-corners .app-products-container .app-product {
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
}
.rounded-corners .app-products-container .app-product__content--price .price-label {
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
}
.rounded-corners .app-products-container .app-product__image {
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  -webkit-border-bottom-left-radius: 1rem;
  -webkit-border-top-left-radius: 1rem;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 0;
  -moz-border-radius-bottomleft: 1rem;
  -moz-border-radius-topleft: 1rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 1rem;
  border-top-left-radius: 1rem;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.rounded-corners .product-container .app-options {
  -webkit-border-top-right-radius: 1rem;
  -webkit-border-bottom-right-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-top-left-radius: 1rem;
  -moz-border-radius-topright: 1rem;
  -moz-border-radius-bottomright: 0;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-topleft: 1rem;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 1rem;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.rounded-corners .app-products__mini-modal .content {
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
}
.rounded-corners .app_button {
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
}

.dark-app-products .app-categories-container .app-category {
  background-color: #333333;
  color: #e7ecef;
}
.dark-app-products .app-products-container .app-product {
  background-color: #333333;
  color: #e7ecef;
}
.dark-app-products .app-products-container .app-product__content--price {
  padding-bottom: 0;
  -webkit-box-shadow: 0 -1.5rem 1.5rem 1.5rem #333333ff;
  -moz-box-shadow: 0 -1.5rem 1.5rem 1.5rem #333333ff;
  box-shadow: 0 -1.5rem 1.5rem 1.5rem #333333ff;
}
.dark-app-products .product-container {
  background-color: #333333;
  color: #e7ecef;
}
.dark-app-products .product-container .product {
  color: #e7ecef;
}
.dark-app-products .product-container .app-options {
  color: #e7ecef;
  background-color: #333333;
}
.dark-app-products .product-add-to-cart {
  background-color: #333333;
  -webkit-box-shadow: 0 -40px 40px 10px #333333ff;
  -moz-box-shadow: 0 -40px 40px 10px #333333ff;
  box-shadow: 0 -40px 40px 10px #333333ff;
}
.dark-app-products .app-products__mini-modal .content {
  background-color: #333333;
}

.app-sidemenu {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #ffffff;
  transform: translateX(0%);
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
  border-right: 1px solid #a93e73;
  -webkit-transition: transform 300ms linear;
  -moz-transition: transform 300ms linear;
  -ms-transition: transform 300ms linear;
  -o-transition: transform 300ms linear;
  transition: transform 300ms linear;
  -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.4);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.4);
}
.app-sidemenu .app-sidemenu-toggler {
  border: 2px solid #a93e73;
  background-color: #a93e73;
  justify-content: center;
  align-items: center;
  position: absolute;
  border-left: none;
  cursor: pointer;
  color: #e7ecef;
  display: none;
  height: 8rem;
  right: -3rem;
  width: 3rem;
  top: 45%;
}
.app-sidemenu .app-sidemenu-toggler:hover {
  background-color: #ffffff;
  color: #a93e73;
}
.app-sidemenu__title {
  -webkit-box-shadow: 0 4px 5px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 4px 5px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 5px 1px rgba(0, 0, 0, 0.2);
  justify-content: space-between;
  margin-bottom: 2.4rem;
  background-color: #e7ecef;
  padding: 1.2rem;
  align-items: center;
  text-align: left;
  font-weight: 500;
  color: #171717;
  display: flex;
  width: 100%;
}
.app-sidemenu__title .info-icon {
  margin-right: 0.4rem;
  justify-content: center;
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: default;
}
.app-sidemenu__button {
  width: 100%;
}
.app-sidemenu__toolbox {
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  justify-content: center;
}
.app-sidemenu__toolbox-content {
  top: 2.3rem;
  z-index: 10;
  width: 25rem;
  right: 0.3rem;
  display: none;
  cursor: default;
  position: absolute;
  align-items: flex-end;
  flex-direction: column;
  background-color: #ffffff;
  padding-bottom: 1.2rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
  -webkit-box-shadow: 0 0 1rem 0.2rem #00000044;
  -moz-box-shadow: 0 0 1rem 0.2rem #00000044;
  box-shadow: 0 0 1rem 0.2rem #00000044;
}
.app-sidemenu__toolbox--head {
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  padding-left: 1.2rem;
  margin-bottom: 0.4rem;
  justify-content: space-between;
  border-bottom: 1px solid #e7ecef;
}
.app-sidemenu__toolbox--head .close {
  width: 4rem;
  height: 100%;
  background-color: #e7ecef;
  -webkit-border-top-right-radius: 1rem;
  -webkit-border-bottom-right-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topright: 1rem;
  -moz-border-radius-bottomright: 0;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-topleft: 0;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.app-sidemenu__toolbox--head .close:hover {
  color: #e63753;
}
.app-sidemenu__toolbox--row {
  width: 100%;
  padding: 0 0.8rem;
}
.app-sidemenu__toolbox--row .long-select__options {
  -webkit-box-shadow: 0 0 1rem 0.2rem #00000044;
  -moz-box-shadow: 0 0 1rem 0.2rem #00000044;
  box-shadow: 0 0 1rem 0.2rem #00000044;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
  padding: 0.8rem 0;
  top: 6.5rem;
}
.app-sidemenu__toolbox--row .long-select__options--item {
  font-size: 1.4rem;
}
.app-sidemenu__toolbox--row .wrapper .text-input-unit {
  -webkit-border-top-right-radius: 1rem;
  -webkit-border-bottom-right-radius: 1rem;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topright: 1rem;
  -moz-border-radius-bottomright: 1rem;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-topleft: 0;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border-bottom: 1px solid #d6d6d6;
}
.app-sidemenu__toolbox--row.logout-button {
  height: 4rem;
  display: flex;
  color: #e7ecef;
  align-items: center;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
  background-color: #fb3350;
  justify-content: space-between;
}
.app-sidemenu__toolbox--row.lang-row {
  display: flex;
  font-size: 1.6rem;
  justify-content: center;
  padding: 0.8rem 0.8rem 0.8rem;
}
.app-sidemenu__toolbox--lang {
  font-size: 1.6rem;
  margin: 0 0.8rem;
  background-color: transparent;
}
.app-sidemenu__toolbox--lang:hover {
  color: #a93e73;
}
.app-sidemenu__info {
  -webkit-box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.4);
  box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.4);
  border: 2px solid #a93e73;
  -webkit-border-radius: 1.5rem;
  -moz-border-radius: 1.5rem;
  border-radius: 1.5rem;
  background-color: #ffffff;
  padding: 1.2rem;
  position: absolute;
  font-style: italic;
  font-size: 1.6rem;
  color: #6a6a6a;
  width: 30rem;
  left: -1rem;
  z-index: 1;
  top: 3rem;
}
.app-sidemenu__info::before {
  width: 0;
  height: 0;
  z-index: 2;
  top: -1.8rem;
  left: 0.5rem;
  content: " ";
  display: block;
  position: absolute;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
  border-bottom: 1.8rem solid #a93e73;
}
.app-sidemenu__footer {
  display: grid;
  grid-template-rows: 5.5rem;
  grid-template-columns: repeat(2, 1fr);
}
.app-sidemenu__footer--control {
  display: flex;
  color: #e7ecef;
  font-size: 1.2rem;
  align-items: center;
  background-color: #585858;
  justify-content: flex-start;
  padding: 0.8rem 1.2rem;
}
.app-sidemenu__footer .om-btn {
  height: 100%;
  max-height: 100%;
}
.app-sidemenu .app-editor {
  width: 100%;
  overflow-y: auto;
  padding-bottom: 2.4rem;
  height: calc(100vh - 17rem);
}
.app-sidemenu .app-editor-group {
  padding: 0 1.2rem;
  margin-bottom: 1.2rem;
  width: 100%;
}
.app-sidemenu .app-editor-group .image-input {
  padding: 0;
}
.app-sidemenu .app-editor-group__head {
  display: flex;
  cursor: default;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e0e7e9;
}
.app-sidemenu .app-editor-group__head--name {
  font-weight: 600;
  font-size: 1.6rem;
}
.app-sidemenu .app-editor-group__body {
  padding: 0 0.4rem;
  overflow-y: hidden;
  height: 0;
}
.app-sidemenu .app-editor-group__body .text-input-unit {
  top: 2.1rem;
  right: 0rem;
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d6d6d6;
}
.app-sidemenu .app-editor-group.active-group .app-editor-group__head {
  border-bottom: none;
}
.app-sidemenu .app-editor-group.active-group .app-editor-group__body {
  height: auto;
  overflow: unset;
  border-bottom: 1px solid #e0e7e9;
}
@media screen and (max-width: 768px) {
  .app-sidemenu .app-sidemenu-toggler {
    display: flex;
  }
  .app-sidemenu.app-sidemenu-hidden {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
  }
}

.toolbox-visible .app-sidemenu__toolbox-content {
  display: flex;
}

.original-version .om-btn {
  height: 6rem;
}
.original-version .app-editor {
  height: calc(100vh - 13.5rem);
  padding: 0 1.6rem;
  text-align: justify;
}

.app-info {
  overflow-y: auto;
  height: 100%;
}
.app-info__map-container {
  padding: 2.4rem 2.4rem 0;
  position: relative;
  width: 100%;
}
.app-info__map-container--map {
  width: 100%;
  height: 30rem;
}
.app-info__grid {
  display: grid;
  padding: 2.4rem;
  align-items: flex-start;
  gap: 2.4rem 6.4rem;
  grid-template-columns: 1fr;
}
.app-info__grid--item--title {
  display: flex;
  text-align: left;
  align-items: center;
  margin-bottom: 0.8rem;
  padding-bottom: 0.4rem;
  border-bottom: 1px solid #d6d6d6;
}
.app-info__grid--item--title .icon {
  margin-right: 0.8rem;
}
.app-info__grid--item .list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.app-info__grid--item .list__item {
  display: flex;
  align-items: center;
  padding-left: 1.2rem;
  padding-bottom: 0.4rem;
}
.app-info__grid--item .list__item .icon {
  margin-right: 1.2rem;
}
.app-info__grid--item .list__item.crossed-out {
  color: #6a6a6a;
  text-decoration: line-through;
}
.app-info__grid--item .list.no-space .list__item {
  padding-bottom: 0;
}

.dark-theme-app .app-info__grid--item .list .crossed-out {
  color: #999999;
}

.cart__items--footer {
  background-color: #ffffff;
  padding: 1.6rem;
  margin-top: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cart__items--footer--coupon {
  padding-right: 1.2rem;
  padding-bottom: 0.4rem;
}
.cart__items--footer--coupon .label {
  font-weight: 600;
  font-size: 1.4rem;
}
.cart__items--footer--prices {
  display: flex;
  flex-direction: column;
  min-width: 25rem;
  width: 100%;
}
.cart__items--footer--prices--row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.4rem;
}
.cart__items--footer--prices--row--label {
  font-size: 1.6rem;
  padding-right: 2.4rem;
}
.cart__items--footer--prices--row .price-container {
  display: flex;
  flex-direction: row;
  text-align: right;
}
.cart__items--footer--prices--row .price-container .crossed-price {
  color: #6a6a6a;
  text-decoration: line-through;
  font-weight: normal;
  font-size: 1.2rem;
}
.cart__items--footer--prices--row .price-container .total-price {
  color: #171717;
  font-weight: 500;
}
.cart__items--footer--prices .total-price-row {
  padding-top: 1.6rem;
  margin-bottom: 0;
  font-size: 2rem;
  font-weight: 600;
}
.cart__items--footer .price-container {
  display: grid;
  align-items: stretch;
  justify-content: flex-end;
}
.cart__items--footer .price-container__prices {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.cart__items--footer .price-container__prices .crossed-price {
  text-decoration: line-through;
  color: #6a6a6a;
}
.cart__items--footer .price-container__prices .final-price {
  font-weight: 500;
  font-size: 2.4rem;
}
.cart__items--footer--total-price {
  color: #6a6a6a;
  padding-right: 2.4rem;
  font-size: 1.8rem;
  font-weight: 500;
}

.app-cart {
  height: 100%;
  overflow: hidden;
  position: relative;
}
.app-cart .cart__registration .registration__block {
  grid-template-columns: repeat(2, 1fr);
}
.app-cart .cart__registration .registration__block .address-rows__title {
  margin-top: 0;
  font-size: 1.4rem;
  column-gap: 1.2rem;
}
.app-cart .cart__registration .registration__block .city,
.app-cart .cart__registration .registration__block .email,
.app-cart .cart__registration .registration__block .street,
.app-cart .cart__registration .registration__block .password,
.app-cart .cart__registration .registration__block .street_id,
.app-cart .cart__registration .registration__block .phone_number,
.app-cart .cart__registration .registration__block .address-rows,
.app-cart .cart__registration .registration__block .address_name,
.app-cart .cart__registration .registration__block .address_notes,
.app-cart .cart__registration .registration__block .reg_with_order,
.app-cart .cart__registration .registration__block .delivery_region_id,
.app-cart .cart__registration .registration__block .registration__title {
  grid-column: 1/3;
}
.app-cart .cart__registration .registration__block .door,
.app-cart .cart__registration .registration__block .house,
.app-cart .cart__registration .registration__block .zipcode {
  grid-column: 1/2;
}
.app-cart .cart__registration .registration__block .floor,
.app-cart .cart__registration .registration__block .doorbell {
  grid-column: 2/3;
}
.app-cart .cart__registration .registration__block .first_name {
  grid-column: 1/2;
}
.app-cart .cart__registration .registration__block .last_name {
  grid-column: 2/3;
}
.app-cart .cart__registration .swap-names .first_name {
  grid-column: 2/3;
}
.app-cart .cart__registration .swap-names .last_name {
  grid-column: 1/2;
}
.app-cart .cart__registration .address-block .address-data,
.app-cart .cart__registration .address-block .app-delivery-info,
.app-cart .cart__registration .address-block .estimated-delivery-time {
  grid-column: 1/3;
}
.app-cart .cart__registration .app-delivery-info {
  padding-bottom: 1.2rem;
}
.app-cart .cart-block {
  color: #171717;
  padding: 1.2rem;
  background-color: #ffffff;
}
.app-cart .app-items-container {
  height: 100%;
  overflow-y: auto;
  position: relative;
  padding-bottom: 2.4rem;
  -webkit-box-shadow: inset 0 -4rem 2rem -2rem #00000033;
  -moz-box-shadow: inset 0 -4rem 2rem -2rem #00000033;
  box-shadow: inset 0 -4rem 2rem -2rem #00000033;
}
.app-cart .app-items-container::-webkit-scrollbar-track {
  margin: 0.8rem 0;
  width: 2px !important;
  background-color: transparent !important;
}
.app-cart .app-items-container::-webkit-scrollbar {
  width: 2px !important;
}
.app-cart .app-items-container .cart-item {
  grid-template-columns: 10rem auto 8rem;
  border-bottom: 2px solid #e0e7e9;
  padding-bottom: 0.4rem;
  margin-bottom: 1.2rem;
  position: relative;
}
.app-cart .app-items-container .cart-item:last-of-type {
  border-bottom: none !important;
  margin-bottom: 0;
}
.app-cart .app-items-container .cart-item__apply {
  position: absolute;
  bottom: 0.5rem;
  width: 10rem;
  left: 0;
}
.app-cart .app-items-container .cart-item__price {
  display: flex;
  align-items: center;
}
.app-cart .app-items-container .cart-item__name {
  font-size: 1.4rem;
  padding-bottom: 0;
}
.app-cart .app-items-container .cart-item__name--options {
  font-size: 1.1rem;
}
.app-cart .app-items-container .cart-item__control {
  justify-content: center;
  justify-self: flex-end;
  align-items: center;
  grid-area: 2/2/3/3;
  display: flex;
  height: 3rem;
  width: 3rem;
  padding: 0;
  margin: 0;
}
.app-cart .app-items-container .cart-item.cart-item-with-apply {
  grid-template-columns: 10rem auto 8rem;
}
.app-cart .app-items-container .cart-item.cart-item-with-apply .cart-item__price {
  display: block;
}
.app-cart .app-items-container .cart-item.cart-item-with-apply .cart-item__qty {
  min-height: 7rem;
}
.app-cart .app-coupon-and-prices {
  left: 0.4rem;
  right: 0.4rem;
  bottom: 0.4rem;
  position: absolute;
  padding-bottom: 8rem;
  -webkit-transition: opacity 500ms linear;
  -moz-transition: opacity 500ms linear;
  -ms-transition: opacity 500ms linear;
  -o-transition: opacity 500ms linear;
  transition: opacity 500ms linear;
  -webkit-box-shadow: 0 -4px 6px 0 #00000033;
  -moz-box-shadow: 0 -4px 6px 0 #00000033;
  box-shadow: 0 -4px 6px 0 #00000033;
}
.app-cart .app-coupon-and-prices .total-price-row {
  padding-top: 0.8rem;
}
.app-cart .app-coupon-and-prices__coupon {
  display: grid;
  column-gap: 0.8rem;
  grid-template-columns: repeat(2, 1fr);
}
.app-cart .app-coupon-and-prices__coupon .app_button {
  height: 4rem;
}
.app-cart .app-delivery-options {
  min-height: 100%;
  padding: 0 1.6rem;
}
.app-cart .app-delivery-options__card {
  display: block;
  cursor: pointer;
  color: #6a6a6a;
  align-items: center;
  padding: 1.6rem;
  background-color: #e0e7e9;
  margin-bottom: 0.4rem;
  border: 2px solid transparent;
}
.app-cart .app-delivery-options__card--text {
  width: 100%;
  font-weight: 600;
  font-size: 1.6rem;
  text-align: center;
}
.app-cart .app-delivery-options__card.--WITH_DATEPICKER .app-delivery-options__card--text {
  padding-bottom: 0.4rem;
}
.app-cart .app-delivery-options__card.--WITH_DATEPICKER .text-input {
  padding: 0;
  width: 100%;
  height: 4rem;
  border: none;
  text-align: center;
  margin-top: 0.4rem;
  background-color: #f0f3f5;
}
.app-cart .app-delivery-options__card.--active {
  background-color: #ffffff;
}
.app-cart .app-delivery-options__cart-group {
  padding-bottom: 2.4rem;
}
.app-cart .app-delivery-options__cart-group--title {
  grid-area: 1/1/2/3;
  padding-bottom: 0.4rem;
}
.app-cart .cart-payment-methods {
  margin-top: 1.6rem;
}
.app-cart .cart-payment-methods .raw-select-input {
  margin-bottom: 0;
}
.app-cart .delivery-at-container {
  margin-top: 0 !important;
}
.app-cart .cart-finalize {
  -webkit-box-shadow: 0 -40px 40px 10px #ffffffff;
  -moz-box-shadow: 0 -40px 40px 10px #ffffffff;
  box-shadow: 0 -40px 40px 10px #ffffffff;
  padding: 0 1.6rem 1rem;
  background-color: #ffffff;
  justify-content: center;
  position: absolute;
  display: flex;
  width: 100%;
  bottom: 0;
  right: 0;
  left: 0;
}
.app-cart .address-block .app-delivery-info {
  grid-column: 1/5;
}
.app-cart .cart__message .wrapper {
  margin-bottom: 0;
}
.app-cart__progressbar {
  height: 5rem;
  display: flex;
  position: relative;
  align-items: stretch;
  padding: 0 1.2rem;
  justify-content: space-between;
  -webkit-transition: background-color 400ms linear;
  -moz-transition: background-color 400ms linear;
  -ms-transition: background-color 400ms linear;
  -o-transition: background-color 400ms linear;
  transition: background-color 400ms linear;
}
.app-cart__progressbar--step {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
}
.app-cart__progressbar--step--knob {
  z-index: 3;
  width: 2rem;
  height: 2rem;
  display: block;
  background-color: #ffffff;
  margin-bottom: 0.4rem;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: background-color 400ms cubic-bezier(0.1, 0.1, 1, 0.1);
  -moz-transition: background-color 400ms cubic-bezier(0.1, 0.1, 1, 0.1);
  -ms-transition: background-color 400ms cubic-bezier(0.1, 0.1, 1, 0.1);
  -o-transition: background-color 400ms cubic-bezier(0.1, 0.1, 1, 0.1);
  transition: background-color 400ms cubic-bezier(0.1, 0.1, 1, 0.1);
}
.app-cart__progressbar--thread {
  background-color: #ffffff;
  position: absolute;
  right: 3.2rem;
  left: 2.4rem;
  display: block;
  height: 1rem;
  top: 1.2rem;
  z-index: 1;
}
.app-cart__progressbar--thread .progress {
  -webkit-transition: width 400ms linear;
  -moz-transition: width 400ms linear;
  -ms-transition: width 400ms linear;
  -o-transition: width 400ms linear;
  transition: width 400ms linear;
  position: absolute;
  height: 1rem;
  z-index: 2;
}
.app-cart__content {
  display: grid;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  align-items: flex-start;
  justify-content: flex-start;
  height: calc(100% - 5rem);
  grid-template-columns: repeat(3, 100%);
}
.app-cart__content::-webkit-scrollbar {
  display: none;
}
.app-cart__content--step1, .app-cart__content--step2, .app-cart__content--step3 {
  opacity: 0;
  width: 100%;
  height: 100%;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
  -webkit-transition: opacity 500ms linear;
  -moz-transition: opacity 500ms linear;
  -ms-transition: opacity 500ms linear;
  -o-transition: opacity 500ms linear;
  transition: opacity 500ms linear;
  padding: 0.2rem 0.4rem 0.4rem;
}
.app-cart__content--step1::-webkit-scrollbar, .app-cart__content--step2::-webkit-scrollbar, .app-cart__content--step3::-webkit-scrollbar {
  width: 0.2rem;
  display: none;
}
.app-cart__content--step1::-webkit-scrollbar-track, .app-cart__content--step2::-webkit-scrollbar-track, .app-cart__content--step3::-webkit-scrollbar-track {
  background-color: transparent;
}
.app-cart__content--step1 {
  padding-top: 0.4rem;
  height: calc(100% - 26rem);
}
.app-cart__content--step2 {
  background-color: #ffffff;
  padding-top: 2.4rem;
  border-radius: 0 !important;
  overflow-y: hidden;
}
.app-cart__content--step3 {
  overflow-y: auto;
  padding-bottom: 12rem;
}
.app-cart__content--step3 .app-prices {
  position: relative;
  padding: 1.2rem;
  background-color: #ffffff;
  margin-top: 1.6rem;
}
.app-cart__no-order {
  font-size: 1.6rem;
  text-align: center;
  padding: 1.6rem 1.2rem;
}
.app-cart__controls {
  display: flex;
  padding-top: 0.8rem;
  margin-bottom: 0.4rem;
  justify-content: space-between;
}
.app-cart__controls .--SECONDARY {
  background-color: transparent;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  min-height: unset !important;
}
.app-cart__controls .--SECONDARY .app_button__text {
  padding: 0.4rem 1.2rem 0.4rem 1.2rem;
}
.app-cart__next {
  padding: 0 1.6rem;
  position: absolute;
  height: 6rem;
  bottom: 1rem;
  z-index: 10;
  right: 0;
  left: 0;
}
.app-cart__next .app_button {
  margin: 0 !important;
  width: 100%;
}
.app-cart__next.--with-shadow {
  bottom: 0;
  height: 7rem;
  background-color: #ffffff;
  padding-bottom: 1rem;
  -webkit-box-shadow: 0 -40px 40px 10px #ffffffff;
  -moz-box-shadow: 0 -40px 40px 10px #ffffffff;
  box-shadow: 0 -40px 40px 10px #ffffffff;
}
.app-cart.--step1 .app-cart__content--step1 {
  opacity: 1;
}
.app-cart.--step1 .app-cart__content--step1 .app-coupon-and-prices {
  opacity: 1;
  pointer-events: all;
}
.app-cart.--step2 .app-cart__progressbar {
  background-color: #ffffff;
}
.app-cart.--step2 .app-cart__progressbar--thread {
  background-color: #e7ecef;
}
.app-cart.--step2 .app-cart__progressbar--step--knob {
  background-color: #e7ecef;
}
.app-cart.--step2 .app-coupon-and-prices {
  opacity: 0;
  pointer-events: none;
}
.app-cart.--step2 .app-cart__content--step1 {
  opacity: 0;
}
.app-cart.--step2 .app-cart__content--step1 .app-coupon-and-prices {
  opacity: 0;
  pointer-events: none;
}
.app-cart.--step2 .app-cart__content--step2 {
  opacity: 1;
  overflow-y: auto;
  padding-bottom: 2.4rem;
}
.app-cart.--step2 .app-cart__content--step3 {
  opacity: 0;
  max-height: unset;
  overflow-y: hidden;
  padding-bottom: 2.4rem;
}
.app-cart.--step3 {
  opacity: 1;
}
.app-cart.--step3 .app-coupon-and-prices {
  opacity: 0;
  pointer-events: none;
}
.app-cart.--step3 .app-cart__next-step {
  justify-content: flex-start;
}
.app-cart.--step3 .app-cart__content--step1 {
  opacity: 0;
}
.app-cart.--step3 .app-cart__content--step2 {
  opacity: 0;
  overflow-y: hidden;
  max-height: unset;
}
.app-cart.--step3 .app-cart__content--step3 {
  opacity: 1;
  overflow-y: auto;
}
.app-cart.--step3 .app-cart__content--step3 .cart-finalize {
  pointer-events: all;
}
.app-cart.--step3 .app-cart__content--step3 .cart-finalize .app_button {
  pointer-events: all;
}
.app-cart .address-rows__title {
  margin-top: 0;
  font-size: 1.4rem;
  column-gap: 1.2rem;
}

.rounded-corners .app-cart {
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
}
.rounded-corners .app-cart .cart-block {
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
}
.rounded-corners .app-cart .app-delivery-options__card {
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
}
.rounded-corners .app-cart__content--step1, .rounded-corners .app-cart__content--step2, .rounded-corners .app-cart__content--step3 {
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
}
.rounded-corners .app-cart .app-prices {
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
}

.dark-container .app-cart {
  background-color: #333333;
}
.dark-container .app-cart .cart-block {
  color: #e7ecef;
  background-color: #333333;
}
.dark-container .app-cart__progressbar .text {
  color: #e7ecef;
}
.dark-container .app-cart__progressbar--section {
  background-color: #171717;
}
.dark-container .app-cart__progressbar--arrow {
  border-left-color: #393939;
}
.dark-container .app-cart .app-delivery-options__card {
  -webkit-box-shadow: 2px 2px 6px 0 #00000055;
  -moz-box-shadow: 2px 2px 6px 0 #00000055;
  box-shadow: 2px 2px 6px 0 #00000055;
  background-color: #333333;
  color: #e7ecef;
}
.dark-container .app-cart .app-delivery-options__card.--active {
  color: #e7ecef !important;
}
.dark-container .app-cart .app-prices {
  background-color: #333333;
}
.dark-container .app-cart .cart-finalize {
  background-color: #171717;
  -webkit-box-shadow: 0 -40px 40px 10px #171717ff;
  -moz-box-shadow: 0 -40px 40px 10px #171717ff;
  box-shadow: 0 -40px 40px 10px #171717ff;
}
.dark-container .app-cart .app-items-container .cart-item__name--options {
  color: #d8e0e5;
}
.dark-container .app-cart .app-items-container .cart-item__price .crossed-price {
  color: #bebebe;
}
.dark-container .app-cart .app-items-container .cart-item__control {
  color: #e7ecef;
}
.dark-container .app-cart .registration__block {
  background-color: #333333;
}
.dark-container .app-cart .registration__block .address-rows__address {
  color: #e7ecef;
  background-color: #585858;
}
.dark-container .app-cart .registration__block .address-rows__address--control {
  color: #e7ecef;
}
.dark-container .app-cart.--step2 {
  background-color: #333333;
}
.dark-container .app-cart.--step2 .app-cart__progressbar {
  background-color: #333333;
  color: #e7ecef;
}
.dark-container .app-cart.--step2 .app-cart__content {
  background-color: #333333;
}
.dark-container .app-cart.--step2 .app-cart__content--step2 {
  background-color: #333333 !important;
  color: #e7ecef;
}
.dark-container .app-cart.--step2 .app-cart__content--step3 {
  background-color: #333333;
}
.dark-container .app-cart.--step2 .app-cart__next {
  background-color: #333333;
  -webkit-box-shadow: 0 -40px 40px 10px #333333ff;
  -moz-box-shadow: 0 -40px 40px 10px #333333ff;
  box-shadow: 0 -40px 40px 10px #333333ff;
}
.dark-container .app-cart.--step3 {
  color: #e7ecef;
}
.dark-container .app-cart.--step3 .app-cart__content {
  color: #e7ecef;
}
.dark-container .app-cart.--step3 .app-cart__content--step2 {
  background-color: #333333;
  color: #e7ecef;
}
.dark-container .app-cart.--step3 .app-cart__content--step3 {
  color: #e7ecef;
  background-color: #333333;
}
.dark-container .app-cart.--step3 .app-cart__content--step3 .cart-block .input-label {
  color: #e7ecef;
}

.app-profile .profile-tabs {
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
  padding: 2.4rem 1.2rem;
}
.app-profile .profile-tabs__links {
  display: none;
}
.app-profile .profile__addresses--form--controls .global__btnv2 {
  margin-left: 1.2rem !important;
}
.app-profile .profile__my-data-block .global__btnv2 {
  width: 100%;
  max-width: 35.2rem;
}
.app-profile .profile__prev-orders--order-again {
  grid-template-columns: repeat(2, 50%);
}
.app-profile .profile__prev-orders--order .order-info {
  justify-content: space-between;
}
.app-profile .profile__prev-orders--order .order-info .add-to-cart {
  font-weight: 500;
}
.app-profile .profile__prev-orders--item .price {
  text-align: right;
}

.app_button {
  justify-content: space-between;
  align-items: stretch;
  min-height: 4rem;
  min-width: 14rem;
  display: flex;
}
.app_button__text {
  font-size: 1.6rem;
  align-self: center;
  padding: 0.4rem 1.2rem;
}
.app_button__icon {
  justify-content: center;
  align-items: center;
  min-width: 4rem;
  max-width: 4rem;
  display: flex;
}
.app_button.--SECONDARY {
  -webkit-box-shadow: 2px 2px 4px 0 #00000044;
  -moz-box-shadow: 2px 2px 4px 0 #00000044;
  box-shadow: 2px 2px 4px 0 #00000044;
  background-color: #d6d6d6;
  color: #171717;
}
.app_button.--increased-height {
  width: 100%;
  min-height: 6rem;
}
.app_button.--increased-height .app_button__icon {
  margin-right: 0.8rem;
}
.app_button:disabled {
  background: #393939 !important;
  cursor: not-allowed !important;
  color: #6a6a6a !important;
}
.app_button:disabled .app_button__icon {
  color: #6a6a6a !important;
}

.app-products .app-v2-categories-container {
  z-index: 1;
  width: 100%;
  display: block;
}
.app-products .app-v2-categories-container .app-v2-categories-scroller {
  height: 6rem;
  display: flex;
  max-width: 100vw;
  overflow-x: auto;
  overflow-y: visible;
  align-items: center;
  scrollbar-width: none;
  column-gap: 0.4rem;
  -ms-overflow-style: none;
  justify-content: flex-start;
  padding: 0 1.2rem 1.2rem;
}
.app-products .app-v2-categories-container .app-v2-categories-scroller::-webkit-scrollbar {
  display: none;
}
.app-products .app-v2-categories-container .app-v2-categories-scroller .app-category {
  white-space: nowrap;
  padding: 0.8rem 1.6rem 0.8rem;
}
.app-products .app-v2-categories-container .app-v2-categories-scroller .app-category__name {
  width: 100%;
  font-size: 1.9rem;
  line-height: 2.3rem;
  text-transform: uppercase;
}
.app-products .app-v2-products-container {
  row-gap: 0;
  overflow-y: auto;
  background-color: #ffffff;
  max-height: calc(100% - 6rem);
  min-height: calc(100% - 6rem);
}
.app-products .app-v2-products-container .v2-category-title {
  margin: 0;
  font-size: 2.2rem;
  padding: 2.4rem 0 1.2rem 0;
}
.app-products .app-v2-products-container .app-end-category-container {
  padding: 0;
}
.app-products .app-v2-products-container .app-end-category-container:first-of-type .v2-category-title {
  padding-top: 0;
}
.app-products .app-v2-products-container .v2-product-category {
  padding: 0;
}
.app-products .app-v2-products-container .v2-product-category .app-end-category-container .v2-category-title {
  padding-top: 2.4rem;
}
.app-products .app-v2-products-container .app-v2-product {
  height: 12rem;
  margin-bottom: 0;
  min-height: 12rem;
  padding-bottom: 0;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  grid-template-columns: 16rem auto;
}
.app-products .app-v2-products-container .app-v2-product .app-product__image {
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
}
.app-products .app-v2-products-container .app-v2-product .app-product__content {
  padding: 0;
}
.app-products .app-v2-products-container .app-v2-product .app-product__content--name {
  padding-bottom: 0.4rem;
}
.app-products .app-v2-products-container .app-v2-product .app-product__content--name--underline {
  display: none;
}
.app-products .app-v2-products-container .product-line {
  border-top: 0.1rem solid #e7ecef;
  background-color: transparent;
  margin: 2.4rem 0;
  border-bottom: none;
  border-right: none;
  border-left: none;
}
.app-products .app-v2-products-container .product-line:last-of-type {
  display: none;
}

.rounded-corners .app-products .app-v2-categories-container .app-v2-categories-scroller .app-category {
  -webkit-border-radius: 3rem;
  -moz-border-radius: 3rem;
  border-radius: 3rem;
}

.dark-container .app-products .app-v2-products-container {
  background-color: #333333;
}

#APP_CONTAINER,
.v2-container {
  overflow: hidden;
}

.dot-pulse:after {
  content: " .";
  font-family: "Open Sans";
  -webkit-animation: dots 1s steps(5, end) infinite;
  -moz-animation: dots 1s steps(5, end) infinite;
  -ms-animation: dots 1s steps(5, end) infinite;
  -o-animation: dots 1s steps(5, end) infinite;
  animation: dots 1s steps(5, end) infinite;
}
@keyframes dots {
  0%, 20% {
    color: #a93e7400;
    text-shadow: 0.5rem 0 0 #a93e7400, 1rem 0 0 #a93e7400;
  }
  40% {
    color: #a93e73;
    text-shadow: 0.5rem 0 0 #a93e7400, 1rem 0 0 #a93e7400;
  }
  60% {
    text-shadow: 0.5rem 0 0 #a93e73, 1rem 0 0 #a93e7400;
  }
  80%, 100% {
    text-shadow: 0.5rem 0 0 #a93e73, 1rem 0 0 #a93e73;
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

@-webkit-keyframes smrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@-moz-keyframes smrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@-ms-keyframes smrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@keyframes smrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@-webkit-keyframes smball1 {
  0% {
    -webkit-box-shadow: 15px 0 0 #a93e73;
    -moz-box-shadow: 15px 0 0 #a93e73;
    box-shadow: 15px 0 0 #a93e73;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #a93e73;
    -moz-box-shadow: 0 0 0 #a93e73;
    box-shadow: 0 0 0 #a93e73;
    margin-bottom: 0;
    transform: translate(7.5px, 7.5px);
    -webkit-transform: translate(7.5px, 7.5px);
    -moz-transform: translate(7.5px, 7.5px);
    -ms-transform: translate(7.5px, 7.5px);
    -o-transform: translate(7.5px, 7.5px);
  }
  100% {
    -webkit-box-shadow: 15px 0 0 #a93e73;
    -moz-box-shadow: 15px 0 0 #a93e73;
    box-shadow: 15px 0 0 #a93e73;
    margin-bottom: 5px;
  }
}
@-moz-keyframes smball1 {
  0% {
    -webkit-box-shadow: 15px 0 0 #a93e73;
    -moz-box-shadow: 15px 0 0 #a93e73;
    box-shadow: 15px 0 0 #a93e73;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #a93e73;
    -moz-box-shadow: 0 0 0 #a93e73;
    box-shadow: 0 0 0 #a93e73;
    margin-bottom: 0;
    transform: translate(7.5px, 7.5px);
    -webkit-transform: translate(7.5px, 7.5px);
    -moz-transform: translate(7.5px, 7.5px);
    -ms-transform: translate(7.5px, 7.5px);
    -o-transform: translate(7.5px, 7.5px);
  }
  100% {
    -webkit-box-shadow: 15px 0 0 #a93e73;
    -moz-box-shadow: 15px 0 0 #a93e73;
    box-shadow: 15px 0 0 #a93e73;
    margin-bottom: 5px;
  }
}
@-ms-keyframes smball1 {
  0% {
    -webkit-box-shadow: 15px 0 0 #a93e73;
    -moz-box-shadow: 15px 0 0 #a93e73;
    box-shadow: 15px 0 0 #a93e73;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #a93e73;
    -moz-box-shadow: 0 0 0 #a93e73;
    box-shadow: 0 0 0 #a93e73;
    margin-bottom: 0;
    transform: translate(7.5px, 7.5px);
    -webkit-transform: translate(7.5px, 7.5px);
    -moz-transform: translate(7.5px, 7.5px);
    -ms-transform: translate(7.5px, 7.5px);
    -o-transform: translate(7.5px, 7.5px);
  }
  100% {
    -webkit-box-shadow: 15px 0 0 #a93e73;
    -moz-box-shadow: 15px 0 0 #a93e73;
    box-shadow: 15px 0 0 #a93e73;
    margin-bottom: 5px;
  }
}
@keyframes smball1 {
  0% {
    -webkit-box-shadow: 15px 0 0 #a93e73;
    -moz-box-shadow: 15px 0 0 #a93e73;
    box-shadow: 15px 0 0 #a93e73;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #a93e73;
    -moz-box-shadow: 0 0 0 #a93e73;
    box-shadow: 0 0 0 #a93e73;
    margin-bottom: 0;
    transform: translate(7.5px, 7.5px);
    -webkit-transform: translate(7.5px, 7.5px);
    -moz-transform: translate(7.5px, 7.5px);
    -ms-transform: translate(7.5px, 7.5px);
    -o-transform: translate(7.5px, 7.5px);
  }
  100% {
    -webkit-box-shadow: 15px 0 0 #a93e73;
    -moz-box-shadow: 15px 0 0 #a93e73;
    box-shadow: 15px 0 0 #a93e73;
    margin-bottom: 5px;
  }
}
@-webkit-keyframes smball2 {
  0% {
    -webkit-box-shadow: 15px 0 0 #fb3350;
    -moz-box-shadow: 15px 0 0 #fb3350;
    box-shadow: 15px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-top: -10px;
    transform: translate(7.5px, 7.5px);
    -webkit-transform: translate(7.5px, 7.5px);
    -moz-transform: translate(7.5px, 7.5px);
    -ms-transform: translate(7.5px, 7.5px);
    -o-transform: translate(7.5px, 7.5px);
  }
  100% {
    -webkit-box-shadow: 15px 0 0 #fb3350;
    -moz-box-shadow: 15px 0 0 #fb3350;
    box-shadow: 15px 0 0 #fb3350;
    margin-top: 0;
  }
}
@-moz-keyframes smball2 {
  0% {
    -webkit-box-shadow: 15px 0 0 #fb3350;
    -moz-box-shadow: 15px 0 0 #fb3350;
    box-shadow: 15px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-top: -10px;
    transform: translate(7.5px, 7.5px);
    -webkit-transform: translate(7.5px, 7.5px);
    -moz-transform: translate(7.5px, 7.5px);
    -ms-transform: translate(7.5px, 7.5px);
    -o-transform: translate(7.5px, 7.5px);
  }
  100% {
    -webkit-box-shadow: 15px 0 0 #fb3350;
    -moz-box-shadow: 15px 0 0 #fb3350;
    box-shadow: 15px 0 0 #fb3350;
    margin-top: 0;
  }
}
@-ms-keyframes smball2 {
  0% {
    -webkit-box-shadow: 15px 0 0 #fb3350;
    -moz-box-shadow: 15px 0 0 #fb3350;
    box-shadow: 15px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-top: -10px;
    transform: translate(7.5px, 7.5px);
    -webkit-transform: translate(7.5px, 7.5px);
    -moz-transform: translate(7.5px, 7.5px);
    -ms-transform: translate(7.5px, 7.5px);
    -o-transform: translate(7.5px, 7.5px);
  }
  100% {
    -webkit-box-shadow: 15px 0 0 #fb3350;
    -moz-box-shadow: 15px 0 0 #fb3350;
    box-shadow: 15px 0 0 #fb3350;
    margin-top: 0;
  }
}
@keyframes smball2 {
  0% {
    -webkit-box-shadow: 15px 0 0 #fb3350;
    -moz-box-shadow: 15px 0 0 #fb3350;
    box-shadow: 15px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-top: -10px;
    transform: translate(7.5px, 7.5px);
    -webkit-transform: translate(7.5px, 7.5px);
    -moz-transform: translate(7.5px, 7.5px);
    -ms-transform: translate(7.5px, 7.5px);
    -o-transform: translate(7.5px, 7.5px);
  }
  100% {
    -webkit-box-shadow: 15px 0 0 #fb3350;
    -moz-box-shadow: 15px 0 0 #fb3350;
    box-shadow: 15px 0 0 #fb3350;
    margin-top: 0;
  }
}
.loading-size-sm .loader {
  -webkit-animation: smrotate 1s infinite;
  -moz-animation: smrotate 1s infinite;
  -ms-animation: smrotate 1s infinite;
  -o-animation: smrotate 1s infinite;
  animation: smrotate 1s infinite;
  height: 25px;
  width: 25px;
  margin-bottom: 1.2rem;
}
.loading-size-sm .loader:before,
.loading-size-sm .loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 10px;
  width: 10px;
}
.loading-size-sm .loader:before {
  -webkit-box-shadow: 15px 0 0 #a93e73;
  -moz-box-shadow: 15px 0 0 #a93e73;
  box-shadow: 15px 0 0 #a93e73;
  margin-bottom: 5px;
  -webkit-animation: smball1 1s infinite;
  -moz-animation: smball1 1s infinite;
  -ms-animation: smball1 1s infinite;
  -o-animation: smball1 1s infinite;
  animation: smball1 1s infinite;
  background-color: #fb3350;
}
.loading-size-sm .loader:after {
  -webkit-animation: smball2 1s infinite;
  -moz-animation: smball2 1s infinite;
  -ms-animation: smball2 1s infinite;
  -o-animation: smball2 1s infinite;
  animation: smball2 1s infinite;
  background-color: #a93e73;
  -webkit-box-shadow: 15px 0 0 #fb3350;
  -moz-box-shadow: 15px 0 0 #fb3350;
  box-shadow: 15px 0 0 #fb3350;
}
@-webkit-keyframes smrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@-moz-keyframes smrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@-ms-keyframes smrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@keyframes smrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@-webkit-keyframes smball1 {
  0% {
    -webkit-box-shadow: 15px 0 0 #fb3350;
    -moz-box-shadow: 15px 0 0 #fb3350;
    box-shadow: 15px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-bottom: 0;
    transform: translate(7.5px, 7.5px);
    -webkit-transform: translate(7.5px, 7.5px);
    -moz-transform: translate(7.5px, 7.5px);
    -ms-transform: translate(7.5px, 7.5px);
    -o-transform: translate(7.5px, 7.5px);
  }
  100% {
    -webkit-box-shadow: 15px 0 0 #fb3350;
    -moz-box-shadow: 15px 0 0 #fb3350;
    box-shadow: 15px 0 0 #fb3350;
    margin-bottom: 5px;
  }
}
@-moz-keyframes smball1 {
  0% {
    -webkit-box-shadow: 15px 0 0 #fb3350;
    -moz-box-shadow: 15px 0 0 #fb3350;
    box-shadow: 15px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-bottom: 0;
    transform: translate(7.5px, 7.5px);
    -webkit-transform: translate(7.5px, 7.5px);
    -moz-transform: translate(7.5px, 7.5px);
    -ms-transform: translate(7.5px, 7.5px);
    -o-transform: translate(7.5px, 7.5px);
  }
  100% {
    -webkit-box-shadow: 15px 0 0 #fb3350;
    -moz-box-shadow: 15px 0 0 #fb3350;
    box-shadow: 15px 0 0 #fb3350;
    margin-bottom: 5px;
  }
}
@-ms-keyframes smball1 {
  0% {
    -webkit-box-shadow: 15px 0 0 #fb3350;
    -moz-box-shadow: 15px 0 0 #fb3350;
    box-shadow: 15px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-bottom: 0;
    transform: translate(7.5px, 7.5px);
    -webkit-transform: translate(7.5px, 7.5px);
    -moz-transform: translate(7.5px, 7.5px);
    -ms-transform: translate(7.5px, 7.5px);
    -o-transform: translate(7.5px, 7.5px);
  }
  100% {
    -webkit-box-shadow: 15px 0 0 #fb3350;
    -moz-box-shadow: 15px 0 0 #fb3350;
    box-shadow: 15px 0 0 #fb3350;
    margin-bottom: 5px;
  }
}
@keyframes smball1 {
  0% {
    -webkit-box-shadow: 15px 0 0 #fb3350;
    -moz-box-shadow: 15px 0 0 #fb3350;
    box-shadow: 15px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-bottom: 0;
    transform: translate(7.5px, 7.5px);
    -webkit-transform: translate(7.5px, 7.5px);
    -moz-transform: translate(7.5px, 7.5px);
    -ms-transform: translate(7.5px, 7.5px);
    -o-transform: translate(7.5px, 7.5px);
  }
  100% {
    -webkit-box-shadow: 15px 0 0 #fb3350;
    -moz-box-shadow: 15px 0 0 #fb3350;
    box-shadow: 15px 0 0 #fb3350;
    margin-bottom: 5px;
  }
}
@-webkit-keyframes smball2 {
  0% {
    -webkit-box-shadow: 15px 0 0 #ffa650;
    -moz-box-shadow: 15px 0 0 #ffa650;
    box-shadow: 15px 0 0 #ffa650;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #ffa650;
    -moz-box-shadow: 0 0 0 #ffa650;
    box-shadow: 0 0 0 #ffa650;
    margin-top: -10px;
    transform: translate(7.5px, 7.5px);
    -webkit-transform: translate(7.5px, 7.5px);
    -moz-transform: translate(7.5px, 7.5px);
    -ms-transform: translate(7.5px, 7.5px);
    -o-transform: translate(7.5px, 7.5px);
  }
  100% {
    -webkit-box-shadow: 15px 0 0 #ffa650;
    -moz-box-shadow: 15px 0 0 #ffa650;
    box-shadow: 15px 0 0 #ffa650;
    margin-top: 0;
  }
}
@-moz-keyframes smball2 {
  0% {
    -webkit-box-shadow: 15px 0 0 #ffa650;
    -moz-box-shadow: 15px 0 0 #ffa650;
    box-shadow: 15px 0 0 #ffa650;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #ffa650;
    -moz-box-shadow: 0 0 0 #ffa650;
    box-shadow: 0 0 0 #ffa650;
    margin-top: -10px;
    transform: translate(7.5px, 7.5px);
    -webkit-transform: translate(7.5px, 7.5px);
    -moz-transform: translate(7.5px, 7.5px);
    -ms-transform: translate(7.5px, 7.5px);
    -o-transform: translate(7.5px, 7.5px);
  }
  100% {
    -webkit-box-shadow: 15px 0 0 #ffa650;
    -moz-box-shadow: 15px 0 0 #ffa650;
    box-shadow: 15px 0 0 #ffa650;
    margin-top: 0;
  }
}
@-ms-keyframes smball2 {
  0% {
    -webkit-box-shadow: 15px 0 0 #ffa650;
    -moz-box-shadow: 15px 0 0 #ffa650;
    box-shadow: 15px 0 0 #ffa650;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #ffa650;
    -moz-box-shadow: 0 0 0 #ffa650;
    box-shadow: 0 0 0 #ffa650;
    margin-top: -10px;
    transform: translate(7.5px, 7.5px);
    -webkit-transform: translate(7.5px, 7.5px);
    -moz-transform: translate(7.5px, 7.5px);
    -ms-transform: translate(7.5px, 7.5px);
    -o-transform: translate(7.5px, 7.5px);
  }
  100% {
    -webkit-box-shadow: 15px 0 0 #ffa650;
    -moz-box-shadow: 15px 0 0 #ffa650;
    box-shadow: 15px 0 0 #ffa650;
    margin-top: 0;
  }
}
@keyframes smball2 {
  0% {
    -webkit-box-shadow: 15px 0 0 #ffa650;
    -moz-box-shadow: 15px 0 0 #ffa650;
    box-shadow: 15px 0 0 #ffa650;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #ffa650;
    -moz-box-shadow: 0 0 0 #ffa650;
    box-shadow: 0 0 0 #ffa650;
    margin-top: -10px;
    transform: translate(7.5px, 7.5px);
    -webkit-transform: translate(7.5px, 7.5px);
    -moz-transform: translate(7.5px, 7.5px);
    -ms-transform: translate(7.5px, 7.5px);
    -o-transform: translate(7.5px, 7.5px);
  }
  100% {
    -webkit-box-shadow: 15px 0 0 #ffa650;
    -moz-box-shadow: 15px 0 0 #ffa650;
    box-shadow: 15px 0 0 #ffa650;
    margin-top: 0;
  }
}
.loading-size-sm.alternate-loading .loader {
  -webkit-animation: smrotate 1s infinite;
  -moz-animation: smrotate 1s infinite;
  -ms-animation: smrotate 1s infinite;
  -o-animation: smrotate 1s infinite;
  animation: smrotate 1s infinite;
  height: 25px;
  width: 25px;
  margin-bottom: 1.2rem;
}
.loading-size-sm.alternate-loading .loader:before,
.loading-size-sm.alternate-loading .loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 10px;
  width: 10px;
}
.loading-size-sm.alternate-loading .loader:before {
  -webkit-box-shadow: 15px 0 0 #fb3350;
  -moz-box-shadow: 15px 0 0 #fb3350;
  box-shadow: 15px 0 0 #fb3350;
  margin-bottom: 5px;
  -webkit-animation: smball1 1s infinite;
  -moz-animation: smball1 1s infinite;
  -ms-animation: smball1 1s infinite;
  -o-animation: smball1 1s infinite;
  animation: smball1 1s infinite;
  background-color: #ffa650;
}
.loading-size-sm.alternate-loading .loader:after {
  -webkit-animation: smball2 1s infinite;
  -moz-animation: smball2 1s infinite;
  -ms-animation: smball2 1s infinite;
  -o-animation: smball2 1s infinite;
  animation: smball2 1s infinite;
  background-color: #fb3350;
  -webkit-box-shadow: 15px 0 0 #ffa650;
  -moz-box-shadow: 15px 0 0 #ffa650;
  box-shadow: 15px 0 0 #ffa650;
}

@-webkit-keyframes mdrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@-moz-keyframes mdrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@-ms-keyframes mdrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@keyframes mdrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@-webkit-keyframes mdball1 {
  0% {
    -webkit-box-shadow: 30px 0 0 #a93e73;
    -moz-box-shadow: 30px 0 0 #a93e73;
    box-shadow: 30px 0 0 #a93e73;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #a93e73;
    -moz-box-shadow: 0 0 0 #a93e73;
    box-shadow: 0 0 0 #a93e73;
    margin-bottom: 0;
    transform: translate(15px, 15px);
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
    -ms-transform: translate(15px, 15px);
    -o-transform: translate(15px, 15px);
  }
  100% {
    -webkit-box-shadow: 30px 0 0 #a93e73;
    -moz-box-shadow: 30px 0 0 #a93e73;
    box-shadow: 30px 0 0 #a93e73;
    margin-bottom: 10px;
  }
}
@-moz-keyframes mdball1 {
  0% {
    -webkit-box-shadow: 30px 0 0 #a93e73;
    -moz-box-shadow: 30px 0 0 #a93e73;
    box-shadow: 30px 0 0 #a93e73;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #a93e73;
    -moz-box-shadow: 0 0 0 #a93e73;
    box-shadow: 0 0 0 #a93e73;
    margin-bottom: 0;
    transform: translate(15px, 15px);
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
    -ms-transform: translate(15px, 15px);
    -o-transform: translate(15px, 15px);
  }
  100% {
    -webkit-box-shadow: 30px 0 0 #a93e73;
    -moz-box-shadow: 30px 0 0 #a93e73;
    box-shadow: 30px 0 0 #a93e73;
    margin-bottom: 10px;
  }
}
@-ms-keyframes mdball1 {
  0% {
    -webkit-box-shadow: 30px 0 0 #a93e73;
    -moz-box-shadow: 30px 0 0 #a93e73;
    box-shadow: 30px 0 0 #a93e73;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #a93e73;
    -moz-box-shadow: 0 0 0 #a93e73;
    box-shadow: 0 0 0 #a93e73;
    margin-bottom: 0;
    transform: translate(15px, 15px);
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
    -ms-transform: translate(15px, 15px);
    -o-transform: translate(15px, 15px);
  }
  100% {
    -webkit-box-shadow: 30px 0 0 #a93e73;
    -moz-box-shadow: 30px 0 0 #a93e73;
    box-shadow: 30px 0 0 #a93e73;
    margin-bottom: 10px;
  }
}
@keyframes mdball1 {
  0% {
    -webkit-box-shadow: 30px 0 0 #a93e73;
    -moz-box-shadow: 30px 0 0 #a93e73;
    box-shadow: 30px 0 0 #a93e73;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #a93e73;
    -moz-box-shadow: 0 0 0 #a93e73;
    box-shadow: 0 0 0 #a93e73;
    margin-bottom: 0;
    transform: translate(15px, 15px);
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
    -ms-transform: translate(15px, 15px);
    -o-transform: translate(15px, 15px);
  }
  100% {
    -webkit-box-shadow: 30px 0 0 #a93e73;
    -moz-box-shadow: 30px 0 0 #a93e73;
    box-shadow: 30px 0 0 #a93e73;
    margin-bottom: 10px;
  }
}
@-webkit-keyframes mdball2 {
  0% {
    -webkit-box-shadow: 30px 0 0 #fb3350;
    -moz-box-shadow: 30px 0 0 #fb3350;
    box-shadow: 30px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-top: -20px;
    transform: translate(15px, 15px);
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
    -ms-transform: translate(15px, 15px);
    -o-transform: translate(15px, 15px);
  }
  100% {
    -webkit-box-shadow: 30px 0 0 #fb3350;
    -moz-box-shadow: 30px 0 0 #fb3350;
    box-shadow: 30px 0 0 #fb3350;
    margin-top: 0;
  }
}
@-moz-keyframes mdball2 {
  0% {
    -webkit-box-shadow: 30px 0 0 #fb3350;
    -moz-box-shadow: 30px 0 0 #fb3350;
    box-shadow: 30px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-top: -20px;
    transform: translate(15px, 15px);
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
    -ms-transform: translate(15px, 15px);
    -o-transform: translate(15px, 15px);
  }
  100% {
    -webkit-box-shadow: 30px 0 0 #fb3350;
    -moz-box-shadow: 30px 0 0 #fb3350;
    box-shadow: 30px 0 0 #fb3350;
    margin-top: 0;
  }
}
@-ms-keyframes mdball2 {
  0% {
    -webkit-box-shadow: 30px 0 0 #fb3350;
    -moz-box-shadow: 30px 0 0 #fb3350;
    box-shadow: 30px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-top: -20px;
    transform: translate(15px, 15px);
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
    -ms-transform: translate(15px, 15px);
    -o-transform: translate(15px, 15px);
  }
  100% {
    -webkit-box-shadow: 30px 0 0 #fb3350;
    -moz-box-shadow: 30px 0 0 #fb3350;
    box-shadow: 30px 0 0 #fb3350;
    margin-top: 0;
  }
}
@keyframes mdball2 {
  0% {
    -webkit-box-shadow: 30px 0 0 #fb3350;
    -moz-box-shadow: 30px 0 0 #fb3350;
    box-shadow: 30px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-top: -20px;
    transform: translate(15px, 15px);
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
    -ms-transform: translate(15px, 15px);
    -o-transform: translate(15px, 15px);
  }
  100% {
    -webkit-box-shadow: 30px 0 0 #fb3350;
    -moz-box-shadow: 30px 0 0 #fb3350;
    box-shadow: 30px 0 0 #fb3350;
    margin-top: 0;
  }
}
.loading-size-md .loader {
  -webkit-animation: mdrotate 1s infinite;
  -moz-animation: mdrotate 1s infinite;
  -ms-animation: mdrotate 1s infinite;
  -o-animation: mdrotate 1s infinite;
  animation: mdrotate 1s infinite;
  height: 50px;
  width: 50px;
  margin-bottom: 1.2rem;
}
.loading-size-md .loader:before,
.loading-size-md .loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}
.loading-size-md .loader:before {
  -webkit-box-shadow: 30px 0 0 #a93e73;
  -moz-box-shadow: 30px 0 0 #a93e73;
  box-shadow: 30px 0 0 #a93e73;
  margin-bottom: 10px;
  -webkit-animation: mdball1 1s infinite;
  -moz-animation: mdball1 1s infinite;
  -ms-animation: mdball1 1s infinite;
  -o-animation: mdball1 1s infinite;
  animation: mdball1 1s infinite;
  background-color: #fb3350;
}
.loading-size-md .loader:after {
  -webkit-animation: mdball2 1s infinite;
  -moz-animation: mdball2 1s infinite;
  -ms-animation: mdball2 1s infinite;
  -o-animation: mdball2 1s infinite;
  animation: mdball2 1s infinite;
  background-color: #a93e73;
  -webkit-box-shadow: 30px 0 0 #fb3350;
  -moz-box-shadow: 30px 0 0 #fb3350;
  box-shadow: 30px 0 0 #fb3350;
}
@-webkit-keyframes mdrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@-moz-keyframes mdrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@-ms-keyframes mdrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@keyframes mdrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@-webkit-keyframes mdball1 {
  0% {
    -webkit-box-shadow: 30px 0 0 #fb3350;
    -moz-box-shadow: 30px 0 0 #fb3350;
    box-shadow: 30px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-bottom: 0;
    transform: translate(15px, 15px);
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
    -ms-transform: translate(15px, 15px);
    -o-transform: translate(15px, 15px);
  }
  100% {
    -webkit-box-shadow: 30px 0 0 #fb3350;
    -moz-box-shadow: 30px 0 0 #fb3350;
    box-shadow: 30px 0 0 #fb3350;
    margin-bottom: 10px;
  }
}
@-moz-keyframes mdball1 {
  0% {
    -webkit-box-shadow: 30px 0 0 #fb3350;
    -moz-box-shadow: 30px 0 0 #fb3350;
    box-shadow: 30px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-bottom: 0;
    transform: translate(15px, 15px);
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
    -ms-transform: translate(15px, 15px);
    -o-transform: translate(15px, 15px);
  }
  100% {
    -webkit-box-shadow: 30px 0 0 #fb3350;
    -moz-box-shadow: 30px 0 0 #fb3350;
    box-shadow: 30px 0 0 #fb3350;
    margin-bottom: 10px;
  }
}
@-ms-keyframes mdball1 {
  0% {
    -webkit-box-shadow: 30px 0 0 #fb3350;
    -moz-box-shadow: 30px 0 0 #fb3350;
    box-shadow: 30px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-bottom: 0;
    transform: translate(15px, 15px);
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
    -ms-transform: translate(15px, 15px);
    -o-transform: translate(15px, 15px);
  }
  100% {
    -webkit-box-shadow: 30px 0 0 #fb3350;
    -moz-box-shadow: 30px 0 0 #fb3350;
    box-shadow: 30px 0 0 #fb3350;
    margin-bottom: 10px;
  }
}
@keyframes mdball1 {
  0% {
    -webkit-box-shadow: 30px 0 0 #fb3350;
    -moz-box-shadow: 30px 0 0 #fb3350;
    box-shadow: 30px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-bottom: 0;
    transform: translate(15px, 15px);
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
    -ms-transform: translate(15px, 15px);
    -o-transform: translate(15px, 15px);
  }
  100% {
    -webkit-box-shadow: 30px 0 0 #fb3350;
    -moz-box-shadow: 30px 0 0 #fb3350;
    box-shadow: 30px 0 0 #fb3350;
    margin-bottom: 10px;
  }
}
@-webkit-keyframes mdball2 {
  0% {
    -webkit-box-shadow: 30px 0 0 #ffa650;
    -moz-box-shadow: 30px 0 0 #ffa650;
    box-shadow: 30px 0 0 #ffa650;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #ffa650;
    -moz-box-shadow: 0 0 0 #ffa650;
    box-shadow: 0 0 0 #ffa650;
    margin-top: -20px;
    transform: translate(15px, 15px);
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
    -ms-transform: translate(15px, 15px);
    -o-transform: translate(15px, 15px);
  }
  100% {
    -webkit-box-shadow: 30px 0 0 #ffa650;
    -moz-box-shadow: 30px 0 0 #ffa650;
    box-shadow: 30px 0 0 #ffa650;
    margin-top: 0;
  }
}
@-moz-keyframes mdball2 {
  0% {
    -webkit-box-shadow: 30px 0 0 #ffa650;
    -moz-box-shadow: 30px 0 0 #ffa650;
    box-shadow: 30px 0 0 #ffa650;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #ffa650;
    -moz-box-shadow: 0 0 0 #ffa650;
    box-shadow: 0 0 0 #ffa650;
    margin-top: -20px;
    transform: translate(15px, 15px);
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
    -ms-transform: translate(15px, 15px);
    -o-transform: translate(15px, 15px);
  }
  100% {
    -webkit-box-shadow: 30px 0 0 #ffa650;
    -moz-box-shadow: 30px 0 0 #ffa650;
    box-shadow: 30px 0 0 #ffa650;
    margin-top: 0;
  }
}
@-ms-keyframes mdball2 {
  0% {
    -webkit-box-shadow: 30px 0 0 #ffa650;
    -moz-box-shadow: 30px 0 0 #ffa650;
    box-shadow: 30px 0 0 #ffa650;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #ffa650;
    -moz-box-shadow: 0 0 0 #ffa650;
    box-shadow: 0 0 0 #ffa650;
    margin-top: -20px;
    transform: translate(15px, 15px);
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
    -ms-transform: translate(15px, 15px);
    -o-transform: translate(15px, 15px);
  }
  100% {
    -webkit-box-shadow: 30px 0 0 #ffa650;
    -moz-box-shadow: 30px 0 0 #ffa650;
    box-shadow: 30px 0 0 #ffa650;
    margin-top: 0;
  }
}
@keyframes mdball2 {
  0% {
    -webkit-box-shadow: 30px 0 0 #ffa650;
    -moz-box-shadow: 30px 0 0 #ffa650;
    box-shadow: 30px 0 0 #ffa650;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #ffa650;
    -moz-box-shadow: 0 0 0 #ffa650;
    box-shadow: 0 0 0 #ffa650;
    margin-top: -20px;
    transform: translate(15px, 15px);
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
    -ms-transform: translate(15px, 15px);
    -o-transform: translate(15px, 15px);
  }
  100% {
    -webkit-box-shadow: 30px 0 0 #ffa650;
    -moz-box-shadow: 30px 0 0 #ffa650;
    box-shadow: 30px 0 0 #ffa650;
    margin-top: 0;
  }
}
.loading-size-md.alternate-loading .loader {
  -webkit-animation: mdrotate 1s infinite;
  -moz-animation: mdrotate 1s infinite;
  -ms-animation: mdrotate 1s infinite;
  -o-animation: mdrotate 1s infinite;
  animation: mdrotate 1s infinite;
  height: 50px;
  width: 50px;
  margin-bottom: 1.2rem;
}
.loading-size-md.alternate-loading .loader:before,
.loading-size-md.alternate-loading .loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}
.loading-size-md.alternate-loading .loader:before {
  -webkit-box-shadow: 30px 0 0 #fb3350;
  -moz-box-shadow: 30px 0 0 #fb3350;
  box-shadow: 30px 0 0 #fb3350;
  margin-bottom: 10px;
  -webkit-animation: mdball1 1s infinite;
  -moz-animation: mdball1 1s infinite;
  -ms-animation: mdball1 1s infinite;
  -o-animation: mdball1 1s infinite;
  animation: mdball1 1s infinite;
  background-color: #ffa650;
}
.loading-size-md.alternate-loading .loader:after {
  -webkit-animation: mdball2 1s infinite;
  -moz-animation: mdball2 1s infinite;
  -ms-animation: mdball2 1s infinite;
  -o-animation: mdball2 1s infinite;
  animation: mdball2 1s infinite;
  background-color: #fb3350;
  -webkit-box-shadow: 30px 0 0 #ffa650;
  -moz-box-shadow: 30px 0 0 #ffa650;
  box-shadow: 30px 0 0 #ffa650;
}

@-webkit-keyframes lgrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@-moz-keyframes lgrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@-ms-keyframes lgrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@keyframes lgrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@-webkit-keyframes lgball1 {
  0% {
    -webkit-box-shadow: 60px 0 0 #a93e73;
    -moz-box-shadow: 60px 0 0 #a93e73;
    box-shadow: 60px 0 0 #a93e73;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #a93e73;
    -moz-box-shadow: 0 0 0 #a93e73;
    box-shadow: 0 0 0 #a93e73;
    margin-bottom: 0;
    transform: translate(30px, 30px);
    -webkit-transform: translate(30px, 30px);
    -moz-transform: translate(30px, 30px);
    -ms-transform: translate(30px, 30px);
    -o-transform: translate(30px, 30px);
  }
  100% {
    -webkit-box-shadow: 60px 0 0 #a93e73;
    -moz-box-shadow: 60px 0 0 #a93e73;
    box-shadow: 60px 0 0 #a93e73;
    margin-bottom: 20px;
  }
}
@-moz-keyframes lgball1 {
  0% {
    -webkit-box-shadow: 60px 0 0 #a93e73;
    -moz-box-shadow: 60px 0 0 #a93e73;
    box-shadow: 60px 0 0 #a93e73;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #a93e73;
    -moz-box-shadow: 0 0 0 #a93e73;
    box-shadow: 0 0 0 #a93e73;
    margin-bottom: 0;
    transform: translate(30px, 30px);
    -webkit-transform: translate(30px, 30px);
    -moz-transform: translate(30px, 30px);
    -ms-transform: translate(30px, 30px);
    -o-transform: translate(30px, 30px);
  }
  100% {
    -webkit-box-shadow: 60px 0 0 #a93e73;
    -moz-box-shadow: 60px 0 0 #a93e73;
    box-shadow: 60px 0 0 #a93e73;
    margin-bottom: 20px;
  }
}
@-ms-keyframes lgball1 {
  0% {
    -webkit-box-shadow: 60px 0 0 #a93e73;
    -moz-box-shadow: 60px 0 0 #a93e73;
    box-shadow: 60px 0 0 #a93e73;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #a93e73;
    -moz-box-shadow: 0 0 0 #a93e73;
    box-shadow: 0 0 0 #a93e73;
    margin-bottom: 0;
    transform: translate(30px, 30px);
    -webkit-transform: translate(30px, 30px);
    -moz-transform: translate(30px, 30px);
    -ms-transform: translate(30px, 30px);
    -o-transform: translate(30px, 30px);
  }
  100% {
    -webkit-box-shadow: 60px 0 0 #a93e73;
    -moz-box-shadow: 60px 0 0 #a93e73;
    box-shadow: 60px 0 0 #a93e73;
    margin-bottom: 20px;
  }
}
@keyframes lgball1 {
  0% {
    -webkit-box-shadow: 60px 0 0 #a93e73;
    -moz-box-shadow: 60px 0 0 #a93e73;
    box-shadow: 60px 0 0 #a93e73;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #a93e73;
    -moz-box-shadow: 0 0 0 #a93e73;
    box-shadow: 0 0 0 #a93e73;
    margin-bottom: 0;
    transform: translate(30px, 30px);
    -webkit-transform: translate(30px, 30px);
    -moz-transform: translate(30px, 30px);
    -ms-transform: translate(30px, 30px);
    -o-transform: translate(30px, 30px);
  }
  100% {
    -webkit-box-shadow: 60px 0 0 #a93e73;
    -moz-box-shadow: 60px 0 0 #a93e73;
    box-shadow: 60px 0 0 #a93e73;
    margin-bottom: 20px;
  }
}
@-webkit-keyframes lgball2 {
  0% {
    -webkit-box-shadow: 60px 0 0 #fb3350;
    -moz-box-shadow: 60px 0 0 #fb3350;
    box-shadow: 60px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-top: -40px;
    transform: translate(30px, 30px);
    -webkit-transform: translate(30px, 30px);
    -moz-transform: translate(30px, 30px);
    -ms-transform: translate(30px, 30px);
    -o-transform: translate(30px, 30px);
  }
  100% {
    -webkit-box-shadow: 60px 0 0 #fb3350;
    -moz-box-shadow: 60px 0 0 #fb3350;
    box-shadow: 60px 0 0 #fb3350;
    margin-top: 0;
  }
}
@-moz-keyframes lgball2 {
  0% {
    -webkit-box-shadow: 60px 0 0 #fb3350;
    -moz-box-shadow: 60px 0 0 #fb3350;
    box-shadow: 60px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-top: -40px;
    transform: translate(30px, 30px);
    -webkit-transform: translate(30px, 30px);
    -moz-transform: translate(30px, 30px);
    -ms-transform: translate(30px, 30px);
    -o-transform: translate(30px, 30px);
  }
  100% {
    -webkit-box-shadow: 60px 0 0 #fb3350;
    -moz-box-shadow: 60px 0 0 #fb3350;
    box-shadow: 60px 0 0 #fb3350;
    margin-top: 0;
  }
}
@-ms-keyframes lgball2 {
  0% {
    -webkit-box-shadow: 60px 0 0 #fb3350;
    -moz-box-shadow: 60px 0 0 #fb3350;
    box-shadow: 60px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-top: -40px;
    transform: translate(30px, 30px);
    -webkit-transform: translate(30px, 30px);
    -moz-transform: translate(30px, 30px);
    -ms-transform: translate(30px, 30px);
    -o-transform: translate(30px, 30px);
  }
  100% {
    -webkit-box-shadow: 60px 0 0 #fb3350;
    -moz-box-shadow: 60px 0 0 #fb3350;
    box-shadow: 60px 0 0 #fb3350;
    margin-top: 0;
  }
}
@keyframes lgball2 {
  0% {
    -webkit-box-shadow: 60px 0 0 #fb3350;
    -moz-box-shadow: 60px 0 0 #fb3350;
    box-shadow: 60px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-top: -40px;
    transform: translate(30px, 30px);
    -webkit-transform: translate(30px, 30px);
    -moz-transform: translate(30px, 30px);
    -ms-transform: translate(30px, 30px);
    -o-transform: translate(30px, 30px);
  }
  100% {
    -webkit-box-shadow: 60px 0 0 #fb3350;
    -moz-box-shadow: 60px 0 0 #fb3350;
    box-shadow: 60px 0 0 #fb3350;
    margin-top: 0;
  }
}
.loading-size-lg .loader {
  -webkit-animation: lgrotate 1s infinite;
  -moz-animation: lgrotate 1s infinite;
  -ms-animation: lgrotate 1s infinite;
  -o-animation: lgrotate 1s infinite;
  animation: lgrotate 1s infinite;
  height: 100px;
  width: 100px;
  margin-bottom: 1.2rem;
}
.loading-size-lg .loader:before,
.loading-size-lg .loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 40px;
  width: 40px;
}
.loading-size-lg .loader:before {
  -webkit-box-shadow: 60px 0 0 #a93e73;
  -moz-box-shadow: 60px 0 0 #a93e73;
  box-shadow: 60px 0 0 #a93e73;
  margin-bottom: 20px;
  -webkit-animation: lgball1 1s infinite;
  -moz-animation: lgball1 1s infinite;
  -ms-animation: lgball1 1s infinite;
  -o-animation: lgball1 1s infinite;
  animation: lgball1 1s infinite;
  background-color: #fb3350;
}
.loading-size-lg .loader:after {
  -webkit-animation: lgball2 1s infinite;
  -moz-animation: lgball2 1s infinite;
  -ms-animation: lgball2 1s infinite;
  -o-animation: lgball2 1s infinite;
  animation: lgball2 1s infinite;
  background-color: #a93e73;
  -webkit-box-shadow: 60px 0 0 #fb3350;
  -moz-box-shadow: 60px 0 0 #fb3350;
  box-shadow: 60px 0 0 #fb3350;
}
@-webkit-keyframes lgrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@-moz-keyframes lgrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@-ms-keyframes lgrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@keyframes lgrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@-webkit-keyframes lgball1 {
  0% {
    -webkit-box-shadow: 60px 0 0 #fb3350;
    -moz-box-shadow: 60px 0 0 #fb3350;
    box-shadow: 60px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-bottom: 0;
    transform: translate(30px, 30px);
    -webkit-transform: translate(30px, 30px);
    -moz-transform: translate(30px, 30px);
    -ms-transform: translate(30px, 30px);
    -o-transform: translate(30px, 30px);
  }
  100% {
    -webkit-box-shadow: 60px 0 0 #fb3350;
    -moz-box-shadow: 60px 0 0 #fb3350;
    box-shadow: 60px 0 0 #fb3350;
    margin-bottom: 20px;
  }
}
@-moz-keyframes lgball1 {
  0% {
    -webkit-box-shadow: 60px 0 0 #fb3350;
    -moz-box-shadow: 60px 0 0 #fb3350;
    box-shadow: 60px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-bottom: 0;
    transform: translate(30px, 30px);
    -webkit-transform: translate(30px, 30px);
    -moz-transform: translate(30px, 30px);
    -ms-transform: translate(30px, 30px);
    -o-transform: translate(30px, 30px);
  }
  100% {
    -webkit-box-shadow: 60px 0 0 #fb3350;
    -moz-box-shadow: 60px 0 0 #fb3350;
    box-shadow: 60px 0 0 #fb3350;
    margin-bottom: 20px;
  }
}
@-ms-keyframes lgball1 {
  0% {
    -webkit-box-shadow: 60px 0 0 #fb3350;
    -moz-box-shadow: 60px 0 0 #fb3350;
    box-shadow: 60px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-bottom: 0;
    transform: translate(30px, 30px);
    -webkit-transform: translate(30px, 30px);
    -moz-transform: translate(30px, 30px);
    -ms-transform: translate(30px, 30px);
    -o-transform: translate(30px, 30px);
  }
  100% {
    -webkit-box-shadow: 60px 0 0 #fb3350;
    -moz-box-shadow: 60px 0 0 #fb3350;
    box-shadow: 60px 0 0 #fb3350;
    margin-bottom: 20px;
  }
}
@keyframes lgball1 {
  0% {
    -webkit-box-shadow: 60px 0 0 #fb3350;
    -moz-box-shadow: 60px 0 0 #fb3350;
    box-shadow: 60px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-bottom: 0;
    transform: translate(30px, 30px);
    -webkit-transform: translate(30px, 30px);
    -moz-transform: translate(30px, 30px);
    -ms-transform: translate(30px, 30px);
    -o-transform: translate(30px, 30px);
  }
  100% {
    -webkit-box-shadow: 60px 0 0 #fb3350;
    -moz-box-shadow: 60px 0 0 #fb3350;
    box-shadow: 60px 0 0 #fb3350;
    margin-bottom: 20px;
  }
}
@-webkit-keyframes lgball2 {
  0% {
    -webkit-box-shadow: 60px 0 0 #ffa650;
    -moz-box-shadow: 60px 0 0 #ffa650;
    box-shadow: 60px 0 0 #ffa650;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #ffa650;
    -moz-box-shadow: 0 0 0 #ffa650;
    box-shadow: 0 0 0 #ffa650;
    margin-top: -40px;
    transform: translate(30px, 30px);
    -webkit-transform: translate(30px, 30px);
    -moz-transform: translate(30px, 30px);
    -ms-transform: translate(30px, 30px);
    -o-transform: translate(30px, 30px);
  }
  100% {
    -webkit-box-shadow: 60px 0 0 #ffa650;
    -moz-box-shadow: 60px 0 0 #ffa650;
    box-shadow: 60px 0 0 #ffa650;
    margin-top: 0;
  }
}
@-moz-keyframes lgball2 {
  0% {
    -webkit-box-shadow: 60px 0 0 #ffa650;
    -moz-box-shadow: 60px 0 0 #ffa650;
    box-shadow: 60px 0 0 #ffa650;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #ffa650;
    -moz-box-shadow: 0 0 0 #ffa650;
    box-shadow: 0 0 0 #ffa650;
    margin-top: -40px;
    transform: translate(30px, 30px);
    -webkit-transform: translate(30px, 30px);
    -moz-transform: translate(30px, 30px);
    -ms-transform: translate(30px, 30px);
    -o-transform: translate(30px, 30px);
  }
  100% {
    -webkit-box-shadow: 60px 0 0 #ffa650;
    -moz-box-shadow: 60px 0 0 #ffa650;
    box-shadow: 60px 0 0 #ffa650;
    margin-top: 0;
  }
}
@-ms-keyframes lgball2 {
  0% {
    -webkit-box-shadow: 60px 0 0 #ffa650;
    -moz-box-shadow: 60px 0 0 #ffa650;
    box-shadow: 60px 0 0 #ffa650;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #ffa650;
    -moz-box-shadow: 0 0 0 #ffa650;
    box-shadow: 0 0 0 #ffa650;
    margin-top: -40px;
    transform: translate(30px, 30px);
    -webkit-transform: translate(30px, 30px);
    -moz-transform: translate(30px, 30px);
    -ms-transform: translate(30px, 30px);
    -o-transform: translate(30px, 30px);
  }
  100% {
    -webkit-box-shadow: 60px 0 0 #ffa650;
    -moz-box-shadow: 60px 0 0 #ffa650;
    box-shadow: 60px 0 0 #ffa650;
    margin-top: 0;
  }
}
@keyframes lgball2 {
  0% {
    -webkit-box-shadow: 60px 0 0 #ffa650;
    -moz-box-shadow: 60px 0 0 #ffa650;
    box-shadow: 60px 0 0 #ffa650;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #ffa650;
    -moz-box-shadow: 0 0 0 #ffa650;
    box-shadow: 0 0 0 #ffa650;
    margin-top: -40px;
    transform: translate(30px, 30px);
    -webkit-transform: translate(30px, 30px);
    -moz-transform: translate(30px, 30px);
    -ms-transform: translate(30px, 30px);
    -o-transform: translate(30px, 30px);
  }
  100% {
    -webkit-box-shadow: 60px 0 0 #ffa650;
    -moz-box-shadow: 60px 0 0 #ffa650;
    box-shadow: 60px 0 0 #ffa650;
    margin-top: 0;
  }
}
.loading-size-lg.alternate-loading .loader {
  -webkit-animation: lgrotate 1s infinite;
  -moz-animation: lgrotate 1s infinite;
  -ms-animation: lgrotate 1s infinite;
  -o-animation: lgrotate 1s infinite;
  animation: lgrotate 1s infinite;
  height: 100px;
  width: 100px;
  margin-bottom: 1.2rem;
}
.loading-size-lg.alternate-loading .loader:before,
.loading-size-lg.alternate-loading .loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 40px;
  width: 40px;
}
.loading-size-lg.alternate-loading .loader:before {
  -webkit-box-shadow: 60px 0 0 #fb3350;
  -moz-box-shadow: 60px 0 0 #fb3350;
  box-shadow: 60px 0 0 #fb3350;
  margin-bottom: 20px;
  -webkit-animation: lgball1 1s infinite;
  -moz-animation: lgball1 1s infinite;
  -ms-animation: lgball1 1s infinite;
  -o-animation: lgball1 1s infinite;
  animation: lgball1 1s infinite;
  background-color: #ffa650;
}
.loading-size-lg.alternate-loading .loader:after {
  -webkit-animation: lgball2 1s infinite;
  -moz-animation: lgball2 1s infinite;
  -ms-animation: lgball2 1s infinite;
  -o-animation: lgball2 1s infinite;
  animation: lgball2 1s infinite;
  background-color: #fb3350;
  -webkit-box-shadow: 60px 0 0 #ffa650;
  -moz-box-shadow: 60px 0 0 #ffa650;
  box-shadow: 60px 0 0 #ffa650;
}

.inline-loading .loader {
  margin-bottom: 0 !important;
}

.spaced-container {
  padding: 2.4rem 0;
}

.absolute-container {
  position: absolute;
  z-index: 1000;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.4);
}

.om-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 12rem;
  height: 40px;
  cursor: pointer;
  background-color: #a93e73;
  color: #ffffff;
  border: 2px solid #a93e73;
}
.om-btn:focus {
  border: 2px solid #923061;
}
.om-btn:hover {
  border: 2px solid #923061;
  background-color: #923061;
}
.om-btn__text {
  padding-left: 0.4rem;
}
.om-btn__icon {
  min-width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.4rem;
}
.om-btn.--SECONDARY {
  background-color: #6f6f6f;
  border: 2px solid #6f6f6f;
  color: #e7ecef;
}
.om-btn.--SECONDARY:hover {
  background-color: #7c7c7c;
  border-color: #7c7c7c;
}
.om-btn.--GHOST {
  background-color: transparent;
  border: 2px solid transparent;
  color: #171717;
}
.om-btn.--GHOST:hover {
  background-color: #e0e7e9;
  border: 2px solid #e0e7e9;
}
.om-btn.--SIMPLE {
  background-color: transparent;
  border: 2px solid transparent;
  color: #a93e73;
  text-decoration: none;
}
.om-btn.--SIMPLE:hover {
  background-color: transparent;
  border: 2px solid transparent;
  text-decoration: underline;
  color: #bb4680;
}
.om-btn.--DANGER {
  background-color: #fb3350;
  border: 2px solid #fb3350;
  color: #e7ecef;
}
.om-btn.--DANGER:hover {
  background-color: #fb425d;
  border: 2px solid #fb425d;
}
.om-btn.--FIELD {
  height: 35px;
  min-width: 11rem;
}
.om-btn.--SMALL {
  height: auto;
}
.om-btn.--MEGA {
  min-height: 8rem;
  font-size: 1.8rem;
  background-color: #d529ff;
  border-color: #d529ff;
  -webkit-box-shadow: 2px 2px 8px 4px #00000088;
  -moz-box-shadow: 2px 2px 8px 4px #00000088;
  box-shadow: 2px 2px 8px 4px #00000088;
}
.om-btn.--MEGA:hover {
  background-color: #c500f5;
  border-color: #c500f5;
}
.om-btn.--MEGA .om-btn__text {
  max-width: 20rem;
  text-align: left;
  margin-right: 1.2rem;
}
.om-btn.--disabled {
  background-color: #999999;
  border-color: #999999;
  cursor: not-allowed;
  color: #e7ecef;
}
.om-btn.--disabled:hover {
  background-color: #999999;
  border-color: #999999;
  cursor: not-allowed;
  color: #e7ecef;
}

.om-container {
  display: flex;
  width: 100%;
  align-items: center;
}
.om-container.--left {
  justify-content: flex-start;
}
.om-container.--center {
  justify-content: center;
}
.om-container.--right {
  justify-content: flex-end;
}

.input-grouper {
  display: block;
  padding-bottom: 0.4rem;
  margin-bottom: 1.2rem;
  border-bottom: 1px solid #e7ecef;
}
.input-grouper__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.input-grouper__row:hover .input-grouper__icon,
.input-grouper__row:hover .input-grouper__heading--label {
  color: #a93e73;
}
.input-grouper__icon {
  align-self: flex-end;
  cursor: pointer;
}
.input-grouper__icon:hover {
  color: #a93e73;
}
.input-grouper__heading {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  flex-grow: 1;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.input-grouper__heading .heading-icon {
  max-width: 12px;
  display: inline-flex;
  align-items: center;
  margin-right: 0.8rem;
}
.input-grouper__heading--label {
  color: #6a6a6a;
  line-height: 2.4rem;
  font-size: 1.2rem;
  cursor: pointer;
  display: inline-flex;
  flex-grow: 1;
}
.input-grouper__heading--reset {
  display: inline-flex;
  align-items: center;
  color: #6a6a6a;
  cursor: pointer;
}
.input-grouper__heading--reset .reset-svg {
  display: inline-flex;
  align-items: center;
}
.input-grouper__heading--reset:hover {
  color: #a93e73;
}
.input-grouper__heading .input-grouper__heading--reset:hover ~ .input-grouper__heading--label {
  color: #6a6a6a;
}
.input-grouper__heading .input-grouper__heading--reset:hover ~ .input-grouper__heading--label:hover {
  color: #6a6a6a;
}
.input-grouper__controls {
  display: inline-flex;
}
.input-grouper__form {
  display: none;
}
.input-grouper__form.inputs-visible {
  display: block;
}
.input-grouper__form .wrapper {
  padding: 0;
}
.input-grouper.input-grouper-open .input-grouper__heading--reset {
  margin-right: 1.2rem;
}
.input-grouper.active-group-label .input-grouper__heading--label {
  color: #a93e73;
}

.grouper__head {
  display: grid;
  grid-template-columns: 1fr 1.6rem;
  cursor: pointer;
  padding: 1.2rem 1.2rem 0.4rem 1.2rem;
  border-bottom: 1px solid #e0e7e9;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.grouper__head--title {
  padding-right: 0.8rem;
}
.grouper__head:hover {
  color: #a93e73;
}
.grouper.active-grouper-group .grouper__head {
  color: #a93e73;
  font-weight: 500;
  margin-bottom: 0.8rem;
}

.form-group-header {
  display: flex;
  -webkit-box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.2);
  margin-bottom: 1.2rem;
}
.form-group-header .group {
  width: 50%;
  background-color: #ffffff;
  padding: 0.8rem 1.2rem;
  border-bottom: 2px solid transparent;
  text-align: center;
  cursor: pointer;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.form-group-header .group:first-of-type {
  border-right: 1px solid #e0e7e9;
}
.form-group-header .group.active-group {
  border-bottom: 2px solid #a93e73;
  -webkit-box-shadow: inset 0 4px 6px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 4px 6px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 4px 6px 2px rgba(0, 0, 0, 0.05);
}
.form-group-header .group:hover {
  -webkit-box-shadow: inset 0 4px 6px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 4px 6px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 4px 6px 2px rgba(0, 0, 0, 0.05);
  border-bottom: 2px solid #a93e73;
}
.form-group-header.__single .group {
  width: 100%;
}

.form-group {
  display: none;
}
.form-group.visible-group {
  display: block;
}

.modal-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: -moz-radial-gradient(rgba(150, 150, 150, 0.4) 35%, rgba(0, 0, 0, 0.4) 100%) !important;
  background: -webkit-gradient(rgba(150, 150, 150, 0.4) 35%, rgba(0, 0, 0, 0.4) 100%) !important;
  background: -webkit-radial-gradient(rgba(150, 150, 150, 0.4) 35%, rgba(0, 0, 0, 0.4) 100%) !important;
  background: -o-radial-gradient(rgba(150, 150, 150, 0.4) 35%, rgba(0, 0, 0, 0.4) 100%) !important;
  background: -ms-radial-gradient(rgba(150, 150, 150, 0.4) 35%, rgba(0, 0, 0, 0.4) 100%) !important;
  background: radial-gradient(rgba(150, 150, 150, 0.4) 35%, rgba(0, 0, 0, 0.4) 100%) !important;
  z-index: 10000;
  display: flex;
  padding: 0 2.4rem;
  justify-content: center;
  align-items: center;
}
.modal-container .modal {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 50px auto 50px;
  background-color: #ffffff;
}
.modal-container .modal__heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  position: relative;
  z-index: 10;
  border-bottom: 1px solid #e7ecef;
  grid-area: 1/1/2/2;
  height: 50px;
}
.modal-container .modal__heading--content {
  padding: 0.8rem 1.2rem;
  font-size: 1.8rem;
  font-weight: 500;
}
.modal-container .modal__heading--close {
  height: 49px;
  padding: 0 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.modal-container .modal__heading--close:hover {
  background-color: #e0e7e9;
}
.modal-container .modal__content {
  display: block;
  padding: 2.4rem;
  grid-area: 2/1/3/2;
  overflow-y: auto;
}
.modal-container .modal__controls {
  display: flex;
  z-index: 10;
  align-items: center;
  position: relative;
  justify-content: space-between;
  grid-area: 3/1/4/2;
}
.modal-container .modal__controls--button {
  height: 50px;
  width: 50%;
}
.modal-container .modal-allow-overflow .modal__content {
  overflow-y: unset;
}
.modal-container .modal-allow-overflow .modal__controls {
  z-index: unset;
}
.modal-container .modal-passive {
  grid-template-rows: 45px auto;
}
.modal-container .modal-XS {
  max-width: 320px;
  width: 100%;
}
.modal-container .modal-XS .modal__content {
  max-height: 80vh;
}
.modal-container .modal-SM {
  max-width: 576px;
  width: 100%;
}
.modal-container .modal-SM .modal__content {
  max-height: 80vh;
}
.modal-container .modal-MD {
  max-width: 768px;
  width: 100%;
  height: 50vh;
}
.modal-container .modal-LG {
  max-width: 992px;
  width: 100%;
  height: 80vh;
}
.modal-container .modal-LG .modal__content {
  max-height: unset;
}
@media screen and (max-width: 576px) {
  .modal-container {
    padding: 0;
  }
  .modal-container .modal-XS,
.modal-container .modal-SM,
.modal-container .modal-MD,
.modal-container .modal-LG {
    height: 100vh;
    max-width: 100%;
  }
  .modal-container .modal-XS .modal__content,
.modal-container .modal-SM .modal__content,
.modal-container .modal-MD .modal__content,
.modal-container .modal-LG .modal__content {
    max-height: unset;
  }
}

.darkened-container {
  background: -moz-radial-gradient(rgba(150, 150, 150, 0.9) 20%, black 100%) !important;
  background: -webkit-gradient(rgba(150, 150, 150, 0.9) 20%, black 100%) !important;
  background: -webkit-radial-gradient(rgba(150, 150, 150, 0.9) 20%, black 100%) !important;
  background: -o-radial-gradient(rgba(150, 150, 150, 0.9) 20%, black 100%) !important;
  background: -ms-radial-gradient(rgba(150, 150, 150, 0.9) 20%, black 100%) !important;
  background: radial-gradient(rgba(150, 150, 150, 0.9) 20%, black 100%) !important;
}

.app-modal-container {
  left: 0rem;
  right: 0rem;
  padding: 0;
  position: absolute;
}
.app-modal-container .modal {
  height: 100%;
  max-width: 100%;
}
.app-modal-container .modal__heading {
  background-color: transparent;
}
.app-modal-container .modal__content {
  background-color: transparent;
}
.app-modal-container .modal__controls .om-btn {
  font-size: 1.6rem;
  padding: 0 0.8rem;
}

.inline-loading {
  display: inline-block;
  width: auto;
  height: 1.6rem;
}
.inline-loading .lds-ring {
  display: inline-block;
  position: relative;
  width: 1.6rem;
  height: 1.6rem;
}
.inline-loading .lds-ring div {
  width: 1.4rem;
  display: block;
  height: 1.4rem;
  position: absolute;
  box-sizing: border-box;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 0.2rem solid #ff781d;
  border-color: #ff781d transparent transparent transparent;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -moz-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -ms-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -o-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.inline-loading .lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.inline-loading .lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.inline-loading .lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.inline-loading.alternate-inline-loading .lds-ring div {
  border: 0.2rem solid #ffffff;
  border-color: #ffffff transparent transparent transparent;
}

.context-menu {
  position: relative;
  display: inline-flex;
}
.context-menu__toggler {
  cursor: pointer;
  width: 4rem;
  height: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.context-menu__toggler.allow-growth {
  width: auto;
  height: auto;
}
.context-menu__container {
  z-index: 100;
  position: fixed;
  max-width: 30rem;
  background-color: #ffffff;
  -webkit-box-shadow: 0 4px 7px rgba(0, 0, 0, 0.12), 0 1px 48px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 4px 7px rgba(0, 0, 0, 0.12), 0 1px 48px rgba(0, 0, 0, 0.24);
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.12), 0 1px 48px rgba(0, 0, 0, 0.24);
  -webkit-animation: opac-up 0.2s linear;
  -moz-animation: opac-up 0.2s linear;
  -ms-animation: opac-up 0.2s linear;
  -o-animation: opac-up 0.2s linear;
  animation: opac-up 0.2s linear;
}
.context-menu__container .menu-item {
  display: grid;
  cursor: pointer;
  min-width: 20rem;
  max-width: 30rem;
  align-items: center;
  column-gap: 1.2rem;
  grid-template-columns: 3rem auto;
}
.context-menu__container .menu-item__icon {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.context-menu__container .menu-item__content {
  display: block;
  text-align: left;
}
.context-menu__container .menu-item__content .hash-link {
  padding-left: 0;
  text-align: left;
  white-space: unset;
}
.context-menu__container .menu-item .wrapper {
  margin: 0;
  padding: 0;
}
.context-menu__container .menu-item .wrapper .switch-sub-container {
  padding: 0;
  min-height: 3.4rem;
}
.context-menu__container .menu-item:hover {
  background-color: #e7ecef;
  color: #171717;
}
.context-menu.dark-context-menu .context-menu__container {
  background-color: #171717;
}
.context-menu.dark-context-menu .context-menu__container .menu-item:hover {
  background-color: #333333;
  color: #e7ecef;
}
.context-menu.rounded-context-menu .context-menu__container {
  padding: 1.2rem 0;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
}

.dark-container .context-menu__container,
.dark-frame .context-menu__container,
.dark-theme .context-menu__container {
  background-color: #171717;
}
.dark-container .context-menu__container .menu-item:hover,
.dark-frame .context-menu__container .menu-item:hover,
.dark-theme .context-menu__container .menu-item:hover {
  background-color: #333333;
  color: #e7ecef;
}

@keyframes opac-up {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.carousel {
  width: 100%;
  min-height: 5rem;
  position: relative;
}
.carousel__container {
  display: flex;
  min-height: 5rem;
  flex-wrap: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;
  align-items: flex-end;
  -webkit-transition: all 100ms linear;
  -moz-transition: all 100ms linear;
  -ms-transition: all 100ms linear;
  -o-transition: all 100ms linear;
  transition: all 100ms linear;
}
.carousel__element {
  position: relative;
}
.carousel__control {
  top: 2rem;
  bottom: 0;
  z-index: 1;
  width: 3rem;
  display: flex;
  cursor: pointer;
  color: #e7ecef;
  position: absolute;
  align-items: center;
  justify-content: center;
  background-color: #00000022;
}
.carousel__control:hover {
  color: #e7ecef;
  background-color: #00000044;
}
.carousel__control.--left {
  left: 0;
}
.carousel__control.--right {
  right: 0;
}
.carousel__dot-controls {
  background-color: #00000044;
  justify-content: center;
  column-gap: 0.8rem;
  align-items: center;
  position: absolute;
  display: flex;
  bottom: 0;
  right: 0;
  left: 0;
}
.carousel__dot-controls--dot {
  background-color: transparent;
  color: #e7ecef;
}
.carousel__dot-controls--dot.--ACTIVE {
  color: #ff0154;
}

.wrapper {
  display: block;
  margin-bottom: 1.2rem;
  position: relative;
}
.wrapper .input-label {
  position: relative;
  width: 100%;
  padding-bottom: 0.4rem;
  display: block;
  font-size: 1.2rem;
  color: #6a6a6a;
}
.wrapper .text-input {
  width: 100%;
  border-top: none;
  border-right: none;
  border-left: none;
  font-size: 1.2rem;
  padding: 0.8rem 1.2rem;
  border-bottom: 1px solid #d6d6d6;
  color: #171717;
  height: 4.1rem;
  background-color: #f0f3f5;
}
.wrapper .text-input-unit {
  position: absolute;
  right: 5px;
  top: 12px;
  color: #6a6a6a;
}
.wrapper .clickable-unit {
  cursor: pointer;
}
.wrapper .text-input-label-unit {
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  padding-right: 0.2rem;
}
.wrapper .input-label-with-unit {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wrapper .input-label-with-unit .input-controls {
  display: flex;
  align-items: center;
}
.wrapper .input-label-with-unit .input-controls__size {
  margin-left: 0.4rem;
  cursor: pointer;
}
.wrapper .input-label-with-unit .input-controls__size:hover {
  color: #171717;
}
.wrapper .input-label-with-unit .input-controls__size.active-size {
  color: #171717;
  font-weight: 500;
}
.wrapper .text-area {
  border: none;
  width: 100%;
  resize: vertical;
  background-color: #f3f6f7;
  font-family: "Open Sans";
  border-bottom: 1px solid #d6d6d6;
  padding: 0.4rem 1.2rem;
  max-height: 400px;
  min-height: 120px;
}
.wrapper .input-description {
  font-size: 1.2rem;
  color: #999999;
}
.wrapper .input-error {
  font-size: 1.2rem;
  color: #fb3350;
}
.wrapper .path-input {
  position: relative;
  width: 100%;
  display: block;
}
.wrapper .path-input .text-input {
  padding-left: 4rem;
}
.wrapper .path-input::before {
  position: absolute;
  left: 0;
  top: 0;
  content: "/";
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e0e7e9;
  width: 4.1rem;
  height: 4.1rem;
  font-size: 1.1rem;
}
.wrapper .external-path .text-input {
  padding-left: 1.2rem;
}
.wrapper .external-path::before {
  display: none;
}

.wrapper-disabled {
  cursor: not-allowed;
}
.wrapper-disabled .input-label {
  cursor: not-allowed;
}
.wrapper-disabled .text-input {
  cursor: not-allowed;
  background-color: #d6d6d6;
  color: #b2b2b2;
}

.wrapper-hidden {
  display: none;
}

.checkbox-wrapper {
  margin-bottom: 0;
}

.checkbox-container {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 0;
  text-align: left;
  cursor: pointer;
  font-size: 1.4rem;
  color: #171717 0.6;
  min-height: 3rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox-container .checkbox-input {
  position: absolute;
  opacity: 0;
  left: 2rem;
  top: 3rem;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkbox-container .check-mark {
  position: relative;
  border: 2px solid #171717;
  top: 0;
  left: 0;
  margin-right: 1.6rem;
  min-height: 1.6rem;
  height: 1.6rem;
  min-width: 1.6rem;
  width: 1.6rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.checkbox-container .checkmark {
  position: absolute;
  top: -0.6rem;
  left: 0rem;
}
.checkbox-container .checkbox-link {
  text-decoration: none;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  margin-bottom: 0;
  padding-bottom: 0;
}

.select {
  position: relative;
  width: 100%;
}
.select .hidden-select-input {
  opacity: 0;
  cursor: pointer;
  width: 0;
  height: 0;
  position: absolute;
  left: 2rem;
  top: 3rem;
  padding: 0;
  margin: 0;
}
.select .select-input {
  position: relative;
}
.select .select-input__controls {
  position: absolute;
  color: #999999;
  right: 0;
  height: 4.1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  top: 0;
  padding-right: 0.8rem;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.select .select-input__controls--separator {
  padding: 0 0.4rem;
}
.select .select-input__controls--icon {
  cursor: pointer;
}
.select .select-input__controls--icon:hover {
  color: #ff781d;
}
.select .select-input__search {
  border: none;
  display: flex;
  align-items: center;
  background-color: #f0f3f5;
  height: 4.1rem;
  padding: 0.4rem 6.4rem 0.4rem 1.6rem;
  border-bottom: 0.1rem solid #d6d6d6;
  font-size: 1.2rem;
  color: #171717;
  width: 100%;
}
.select .select-input__search::placeholder {
  color: #6a6a6a;
  font-weight: 400;
  font-style: italic;
}
.select .select-input__list {
  left: 0;
  right: 0;
  z-index: 2;
  width: 100%;
  min-width: 25rem;
  position: absolute;
  margin-top: 0.4rem;
  background-color: #ffffff;
  -webkit-box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.2);
}
.select .select-input__list--input-overlay {
  pointer-events: none;
  background-color: transparent;
  height: 4.5rem;
  pointer-events: none;
  background: none !important;
}
.select .select-input__list--scrollable {
  max-height: 20rem;
  overflow-y: auto;
  pointer-events: all;
}
.select .select-input__list--item {
  cursor: pointer;
  padding: 1.2rem 1.2rem;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.select .select-input__list--item.--active {
  background-color: #a93e73;
  color: #e7ecef;
}
.select .select-input__list--item:hover {
  background-color: #393939;
  color: #e7ecef;
}
.select .select-input__list--item.--selected {
  background-color: #a93e73;
  color: #e7ecef;
  font-weight: 500;
}
.select .input-description {
  padding-top: 0.4rem;
  text-align: justify;
  color: #171717;
}

.select-disabled .select-input__search {
  background-color: #333333;
  cursor: not-allowed;
  color: #6a6a6a;
}
.select-disabled .select-input__search::placeholder {
  color: #6a6a6a;
}
.select-disabled .select-input__controls {
  color: #6a6a6a;
}
.select-disabled .select-input__controls--icon:hover {
  cursor: not-allowed;
  color: #6a6a6a;
}

.column-picker {
  width: 100%;
  margin-bottom: 1.2rem;
}
.column-picker__value {
  background-color: #e7ecef;
  text-align: center;
  padding: 0 0.8rem;
  display: inline-flex;
  height: 4.1rem;
  align-items: center;
  justify-content: center;
  width: 19%;
}
.column-picker__value:hover {
  background-color: #a93e73;
  color: #ffffff;
}
.column-picker__values {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  letter-spacing: 0.1rem;
}
.column-picker__active {
  background-color: #a93e73;
  color: #ffffff;
}
.column-picker.--values-6 .column-picker__value {
  width: 16%;
}
.column-picker.--values-4 .column-picker__value {
  width: 24%;
}
.column-picker.--values-3 .column-picker__value {
  width: 33%;
}
.column-picker.--values-2 .column-picker__value {
  width: 49%;
}

.color-wrapper .color-picker-container {
  display: grid;
  grid-template-columns: auto 34px;
}
.color-wrapper .color-picker-container .color-picker-input {
  padding: 0;
  border: none;
  margin: 0;
  width: 4.1rem;
  height: 4.1rem;
  grid-area: 1/1/2/2;
  border-bottom: 1px solid #d6d6d6;
  cursor: pointer;
}
.color-wrapper .color-picker-container .color-picker {
  display: flex;
  width: 34px;
  justify-content: flex-end;
  align-items: center;
  grid-area: 1/2/2/3;
  position: relative;
}
.color-wrapper .color-picker-container .color-picker .no-color {
  height: 4.1rem;
  content: " ";
  display: block;
  right: 0;
  top: 0;
  width: 4.1rem;
  pointer-events: none;
  background: none !important;
  position: absolute;
  z-index: 1;
}
.color-wrapper .color-picker-container .color-picker .no-color::after {
  display: block;
  content: " ";
  width: 120%;
  height: 2px;
  background-color: #a93e73;
  top: 19px;
  right: -7px;
  position: absolute;
  transform: rotateZ(-45deg);
  -webkit-transform: rotateZ(-45deg);
  -moz-transform: rotateZ(-45deg);
  -ms-transform: rotateZ(-45deg);
  -o-transform: rotateZ(-45deg);
}
.color-wrapper .color-picker-container input[type=color i] {
  background-color: #e7ecef;
}
.color-wrapper .color-picker-container .alpha-slider {
  grid-area: 2/1/3/3;
  width: 100%;
  padding-top: 1.2rem;
}
.color-wrapper .color-picker-container .alpha-slider .input-label {
  padding-bottom: 0;
}
.color-wrapper .color-picker-container .alpha-slider .alpha-input {
  width: 100%;
  height: 3px;
  background: #e7ecef;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
}
.color-wrapper .color-picker-container .alpha-slider .alpha-input::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* Override default look */
  appearance: none;
  width: 12px;
  height: 12px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: #6a6a6a;
  border: 1px solid #6a6a6a;
}
.color-wrapper .color-picker-container .alpha-slider .alpha-input::-webkit-slider-thumb:hover {
  background-color: #a93e73;
  border-color: #a93e73;
}
.color-wrapper .color-picker-container .alpha-slider .alpha-input::-moz-range-thumb {
  height: 12px;
  width: 12px;
  background-color: #6a6a6a;
  border-color: #6a6a6a;
}
.color-wrapper .color-picker-container .alpha-slider .alpha-input::-moz-range-thumb:hover {
  background-color: #393939;
  border-color: #393939;
}

.list-input-field {
  display: grid;
  grid-template-columns: 1fr 6fr 1fr;
  gap: 0.4rem;
}
.list-input-field .wrapper {
  padding: 0 0 0 0;
}
.list-input-field .text-input {
  width: 100%;
}
.list-input-field__button {
  color: #6a6a6a;
  padding: 0 0.4rem;
  font-size: 1.2rem;
  cursor: pointer;
  height: 4.1rem;
  width: 4.1rem;
  background-color: #f3f6f7;
  display: flex;
  justify-content: center;
  align-items: center;
}
.list-input-field__button :hover {
  color: #171717;
}
.list-input-field__button--disabled {
  color: #bebebe;
  cursor: default;
}
.list-input-field__button--disabled :hover {
  color: #bebebe;
}

.list-add-button {
  display: flex;
  justify-content: flex-end;
}

.gradient-picker {
  padding-top: 0.8rem;
}
.gradient-picker .bar-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 1.2rem;
}
.gradient-picker .bar-container .gradient-bar-container {
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}
.gradient-picker .bar-container .gradient-bar-container .gradient-label {
  position: relative;
  width: 100%;
  padding-bottom: 0.2rem;
  display: block;
  font-size: 1.2rem;
  color: #6a6a6a;
}
.gradient-picker .bar-container .gradient-bar-container .gradient-bar {
  display: inline-flex;
  width: 100%;
  border: 1px solid #d6d6d6;
  min-height: 4.1rem;
  margin: 0;
  appearance: none;
  -webkit-appearance: none;
}
.gradient-picker .bar-container .gradient-bar-container .gradient-bar::-webkit-slider-thumb {
  height: 29px;
  width: 7px;
  appearance: none;
  -webkit-appearance: none;
  border: 2px solid #ffffff;
  background-color: transparent;
  -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.gradient-picker .bar-container .gradient-bar-container .gradient-bar::-moz-range-thumb {
  height: 27px;
  width: 2px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border: 2px solid #ffffff;
  background-color: transparent;
  -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
}
.gradient-picker .bar-container .deg-input {
  margin-bottom: 0;
  margin-left: 0.4rem;
  max-width: 70px;
}
.gradient-picker .radial-settings {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.gradient-picker .radial-settings .wrapper {
  max-width: 90px;
}
.gradient-picker .radial-settings.hidden {
  display: none;
}

.file-input {
  width: 0px;
  height: 0px;
  overflow: hidden;
  position: absolute;
  right: 0;
  bottom: 0;
}

.image-input {
  display: grid;
  padding: 0 1.2rem;
  margin-bottom: 1.2rem;
}
.image-input__label {
  position: relative;
  width: 100%;
  padding-bottom: 0.4rem;
  display: block;
  font-size: 1.2rem;
  color: #6a6a6a;
}
.image-input__controls {
  display: flex;
  flex-wrap: wrap;
  gap: 0.4rem;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 1.2rem;
}
.image-input__container {
  width: 100%;
  display: grid;
  height: 4.1rem;
  color: #171717;
  font-size: 1.2rem;
  position: relative;
  align-items: stretch;
  background-color: #f0f3f5;
  border-bottom: 1px solid #d6d6d6;
  color: #171717;
}
.image-input__container.--layout-1 {
  grid-template-columns: 100%;
  background-color: #a93e73;
  color: #e7ecef;
  align-items: center;
  text-align: center;
  border-bottom: none;
  max-width: 20rem;
  height: 4rem;
}
.image-input__container.--layout-2 {
  grid-template-columns: 1fr 4.1rem;
}
.image-input__container.--layout-3 {
  grid-template-columns: 4.1rem 1fr 4.1rem;
}
.image-input__container:hover {
  cursor: pointer;
}
.image-input__container:hover .image-input__upload-icon {
  background-color: #b4427b;
}
.image-input__dimensions {
  color: #999999;
  font-size: 1.2rem;
  padding-top: 0.2rem;
  text-align: center;
  width: 100%;
}
.image-input__title {
  margin: 0;
  font-weight: 500;
}
.image-input__image-selector {
  display: flex;
  flex-wrap: wrap;
  padding-top: 1.2rem;
  gap: 0.8rem;
}
.image-input__image-selector .image-instance {
  width: 15rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e7ecef;
  border: 2px solid #e7ecef;
  position: relative;
  flex-direction: column;
}
.image-input__image-selector .image-instance__img-container {
  height: 10rem;
  width: 15rem;
  padding: 0.8rem 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-input__image-selector .image-instance__image {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
.image-input__image-selector .image-instance__image-mark {
  position: absolute;
  right: 1.5rem;
  top: -1rem;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  color: #171717;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: #d6d6d6;
}
.image-input__image-selector .image-instance__image-mark:hover {
  background-color: #b2b2b2;
  color: #171717;
}
.image-input__image-selector .image-instance__image-delete {
  position: absolute;
  right: -1rem;
  top: -1rem;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  color: #171717;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #d6d6d6;
}
.image-input__image-selector .image-instance__image-delete:hover {
  background-color: #fb3350;
  color: #e7ecef;
}
.image-input__image-selector .image-instance__copied-text {
  left: 0;
  top: -1.5rem;
  width: 10rem;
  text-align: left;
  font-size: 1.1rem;
  position: absolute;
  color: #ff0154;
}
.image-input__image-selector .image-instance__name {
  font-size: 1.4rem;
  display: block;
  width: 100%;
  text-align: center;
  padding: 0 1.2rem 1.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.image-input__image-selector .image-instance.--marked {
  background-color: #e63753;
  opacity: 0.6;
  color: #e7ecef;
}
.image-input__image-selector .image-instance.--copied {
  background-color: #bebebe;
  opacity: 0.8;
}
.image-input__image-selector .image-instance:hover {
  border: 2px solid #a93e73;
  background-color: #a93e73;
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
  opacity: 1;
}
.image-input__image-selector .image-instance:hover .image-instance__name {
  color: #e7ecef;
}
.image-input__image-selector .image-instance.--active {
  border: 2px solid #a93e73;
  background-color: #a93e73;
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
  opacity: 1;
}
.image-input__image-selector .image-instance.--active .image-instance__name {
  color: #e7ecef;
}
.image-input__text {
  padding-left: 1.2rem;
  align-self: flex-start;
  line-height: 4rem;
  vertical-align: middle;
  padding-right: 1.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.image-input__text.--prompt {
  color: #6a6a6a;
}
.image-input__box {
  display: flex;
  height: 4.1rem;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}
.image-input__box--icon {
  cursor: default;
  background-color: #f0f3f5;
  border-bottom: 1px solid #d6d6d6;
}
.image-input__box--image {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
.image-input__clear-icon {
  background-color: #d6d6d6;
}
.image-input__upload-icon {
  border-bottom: 1px solid #a93e73;
  background-color: #a93e73;
  color: #e7ecef;
}

.image-cropper {
  display: flex;
  flex-direction: column;
}
.image-cropper__title {
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0.4rem;
}
.image-cropper__preview {
  margin-top: 1.2rem;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.image-cropper__preview .cropped-image {
  max-width: 100%;
  height: auto;
}

.position-input__container {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 5rem 1fr;
}
.position-input__button {
  cursor: pointer;
  border: none;
  padding: 0.4rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #a93e73;
  color: #e7ecef;
}
.position-input__button:disabled {
  background-color: #e7ecef;
  color: #999999;
  cursor: not-allowed;
}
.position-input__value {
  text-align: center;
  font-size: 1.6rem;
  font-weight: 500;
}

.long-select {
  position: relative;
}
.long-select__hidden-input {
  position: absolute;
  top: 2rem;
  left: 2rem;
  width: 0;
  height: 0;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  opacity: 0;
}
.long-select__input {
  position: relative;
}
.long-select__input .text-input {
  padding-right: 4.8rem;
}
.long-select__input .text-input-unit {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #e0e7e9;
  width: 4.1rem;
  height: 4.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.long-select__input .text-input-unit:hover {
  color: #ff781d;
}
.long-select__options {
  position: absolute;
  max-height: 20rem;
  top: 4rem;
  -webkit-animation: slide-up-from-below 0.2s linear;
  -moz-animation: slide-up-from-below 0.2s linear;
  -ms-animation: slide-up-from-below 0.2s linear;
  -o-animation: slide-up-from-below 0.2s linear;
  animation: slide-up-from-below 0.2s linear;
  right: 0;
  left: 0;
  z-index: 1;
  overflow-y: auto;
  border-top: 1px solid #d6d6d6;
  background-color: #ffffff;
  -webkit-box-shadow: #4c4c4c;
  -moz-box-shadow: #4c4c4c;
  box-shadow: #4c4c4c;
}
.long-select__options--close {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}
.long-select__options--close .svg {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.8rem;
  background-color: #e0e7e9;
}
.long-select__options--new {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.8rem;
  color: #ff781d;
  cursor: pointer;
}
.long-select__options--new:hover {
  background-color: #ff781d;
  color: #e7ecef;
}
.long-select__options--is-typing {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.8rem 0.8rem;
}
.long-select__options--control {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.4rem 0;
  cursor: pointer;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.long-select__options--control:hover {
  background-color: #e0e7e9;
}
.long-select__options--item {
  padding: 0.8rem 0.8rem;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  cursor: pointer;
}
.long-select__options--item:hover {
  background-color: #e7ecef;
}
.long-select__options--item.active-option {
  background-color: #a93e73;
  color: #e7ecef;
}
@media screen and (max-width: 576px) {
  .long-select__input .text-input {
    padding-right: 4rem;
  }
}
@keyframes slide-up-from-below {
  0% {
    z-index: 0;
    opacity: 0.6;
    top: 4rem;
  }
  60% {
    z-index: 1;
    opacity: 0.8;
    top: 9rem;
  }
  100% {
    z-index: 1;
    opacity: 1;
    top: 5.1rem;
  }
}

.multi-select {
  position: relative;
}
.multi-select .hidden-select-input {
  opacity: 0;
  cursor: pointer;
  width: 0;
  height: 0;
  position: absolute;
  left: 2rem;
  top: 3rem;
  padding: 0;
  margin: 0;
}
.multi-select .multi-select-input {
  position: relative;
}
.multi-select .multi-select-input__controls {
  position: absolute;
  color: #999999;
  right: 0;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  top: 0;
  padding-right: 0.8rem;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.multi-select .multi-select-input__controls--separator {
  margin: 0 0.4rem;
  border-left: 1px solid #999999;
  height: 70%;
}
.multi-select .multi-select-input__controls--icon {
  cursor: pointer;
  height: 70%;
}
.multi-select .multi-select-input__controls--icon:hover {
  color: #e63753;
}
.multi-select .multi-select-input__values {
  padding: 0.4rem 6.4rem 0.4rem 1.6rem;
  background-color: #f0f3f5;
  justify-content: flex-start;
  min-height: 4.1rem;
  align-items: center;
  font-size: 1.2rem;
  color: #171717;
  display: flex;
}
.multi-select .multi-select-input__values .placeholder {
  color: #6a6a6a;
  font-weight: 400;
  font-style: italic;
}
.multi-select .multi-select-input__values--option {
  display: grid;
  text-align: left;
  align-items: stretch;
  padding-left: 0.4rem;
  background-color: #e0e7e9;
  margin-right: 0.4rem;
  grid-template-columns: 1fr 1.6rem;
}
.multi-select .multi-select-input__values--option .name {
  display: inline-block;
  max-width: 8rem;
  padding-right: 0.4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.multi-select .multi-select-input__values--option .option-clear-icon {
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  -webkit-border-bottom-left-radius: 0.4rem;
  -webkit-border-top-left-radius: 0.4rem;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 0;
  -moz-border-radius-bottomleft: 0.4rem;
  -moz-border-radius-topleft: 0.4rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0.4rem;
  border-top-left-radius: 0.4rem;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border-left: 1px solid #d6d6d6;
  justify-content: center;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.multi-select .multi-select-input__values--option .option-clear-icon:hover {
  background-color: #a93e73;
  color: #e7ecef;
}
.multi-select .multi-select-input__search {
  width: 100%;
  border: none;
  color: #171717;
  font-size: 1.2rem;
  height: 4.1rem;
  background-color: #f0f3f5;
  padding: 0.4rem 6.4rem 0.4rem 1.6rem;
}
.multi-select .multi-select-input__search::placeholder {
  color: #6a6a6a;
  font-weight: 400;
  font-style: italic;
}
.multi-select .multi-select-input__list {
  left: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  min-width: 25rem;
  position: absolute;
  margin-top: 0.4rem;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 0.4rem 0.2rem #00000026;
  -moz-box-shadow: 0 0 0.4rem 0.2rem #00000026;
  box-shadow: 0 0 0.4rem 0.2rem #00000026;
}
.multi-select .multi-select-input__list--input-overlay {
  pointer-events: none;
  background-color: transparent;
  height: 4.5rem;
  pointer-events: none;
  background: none !important;
}
.multi-select .multi-select-input__list--scrollable {
  max-height: 20rem;
  overflow-y: auto;
  pointer-events: all;
}
.multi-select .multi-select-input__list--item {
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 0.4rem 1.2rem;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.multi-select .multi-select-input__list--item--icon {
  margin-right: 0.4rem;
}
.multi-select .multi-select-input__list--item.--active {
  background-color: #a93e73;
  color: #e7ecef;
}
.multi-select .multi-select-input__list--item:hover {
  background-color: #393939;
  color: #e7ecef;
}
.multi-select .multi-select-input__list--item.--selected {
  font-weight: 500;
}

.multi-select-growable .multi-select-input__values {
  flex-wrap: wrap;
}
.multi-select-growable .multi-select-input__controls {
  height: 100%;
}

.multi-select-disabled .multi-select-input__search {
  background-color: #333333;
  cursor: not-allowed;
  color: #6a6a6a;
}
.multi-select-disabled .multi-select-input__search::placeholder {
  color: #6a6a6a;
}
.multi-select-disabled .multi-select-input__controls {
  color: #6a6a6a;
}
.multi-select-disabled .multi-select-input__controls--icon:hover {
  cursor: not-allowed;
  color: #6a6a6a;
}

.padding-form {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.padding-form .wrapper {
  padding: 0;
}
.padding-form .padding-input {
  margin: 0.4rem 0.2rem 0.8rem;
}
.padding-form .padding-input .text-input {
  padding-right: 2.4rem;
}

.margin-form {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.margin-form .wrapper {
  padding: 0;
}
.margin-form .margin-input {
  margin: 0.4rem 0.2rem 0.8rem;
}
.margin-form .margin-input .text-input {
  padding-right: 2.4rem;
}

.font-form {
  display: grid;
  column-gap: 0.8rem;
  grid-template-columns: 1fr 1fr;
}
.font-form .left-column {
  grid-column: 1/2;
}
.font-form .right-column {
  grid-column: 2/3;
}
.font-form .full-width {
  grid-column: 1/3;
}

.background-form {
  display: block;
}
.background-form .group {
  display: none;
}
.background-form .group.active-group {
  display: block;
}

.border-radius-form {
  display: flex;
}
.border-radius-form .wrapper {
  padding: 0;
}
.border-radius-form .border-radius-input {
  margin: 0.4rem 0.2rem 0.8rem;
}
.border-radius-form .border-radius-input .text-input {
  padding-right: 2.4rem;
}

.bar-container {
  padding-top: 0.8rem;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 1.2rem;
}
.bar-container .input-bar-container {
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}
.bar-container .input-bar-container .label {
  position: relative;
  width: 100%;
  padding-bottom: 0.8rem;
  display: block;
  font-size: 1.2rem;
  color: #6a6a6a;
}
.bar-container .input-bar-container .input-slider {
  width: 100%;
  height: 3px;
  background: #e7ecef;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  margin-bottom: 1.2rem;
  margin-right: 1.2rem;
}
.bar-container .input-bar-container .input-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* Override default look */
  appearance: none;
  width: 12px;
  height: 12px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: #6a6a6a;
  border: 1px solid #6a6a6a;
}
.bar-container .input-bar-container .input-slider::-webkit-slider-thumb:hover {
  background-color: #a93e73;
  border-color: #a93e73;
}
.bar-container .input-bar-container .input-slider::-moz-range-thumb {
  height: 12px;
  width: 12px;
  background-color: #6a6a6a;
  border-color: #6a6a6a;
}
.bar-container .input-bar-container .input-slider::-moz-range-thumb:hover {
  background-color: #393939;
  border-color: #393939;
}

.box-shadow {
  padding-top: 0.8rem;
}
.box-shadow .bar-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 1.2rem;
}
.box-shadow .bar-container .input-bar-container {
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}
.box-shadow .bar-container .input-bar-container .label {
  position: relative;
  width: 100%;
  padding-bottom: 0.8rem;
  display: block;
  font-size: 1.2rem;
  color: #6a6a6a;
}
.box-shadow .bar-container .input-bar-container .input-slider {
  width: 100%;
  height: 3px;
  background: #e7ecef;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  margin-bottom: 1.2rem;
  margin-right: 1.2rem;
}
.box-shadow .bar-container .input-bar-container .input-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* Override default look */
  appearance: none;
  width: 12px;
  height: 12px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: #6a6a6a;
  border: 1px solid #6a6a6a;
}
.box-shadow .bar-container .input-bar-container .input-slider::-webkit-slider-thumb:hover {
  background-color: #a93e73;
  border-color: #a93e73;
}
.box-shadow .bar-container .input-bar-container .input-slider::-moz-range-thumb {
  height: 12px;
  width: 12px;
  background-color: #6a6a6a;
  border-color: #6a6a6a;
}
.box-shadow .bar-container .input-bar-container .input-slider::-moz-range-thumb:hover {
  background-color: #393939;
  border-color: #393939;
}
.box-shadow .bar-container .input-bar-container .gradient-bar {
  display: inline-flex;
  width: 100%;
  border: 1px solid #d6d6d6;
  margin: 0;
  appearance: none;
  -webkit-appearance: none;
}
.box-shadow .bar-container .input-bar-container .gradient-bar::-webkit-slider-thumb {
  height: 9px;
  width: 7px;
  appearance: none;
  -webkit-appearance: none;
  border: 2px solid #ffffff;
  background-color: transparent;
  -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.box-shadow .bar-container .input-bar-container .gradient-bar::-moz-range-thumb {
  height: 7px;
  width: 2px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border: 2px solid #ffffff;
  background-color: transparent;
  -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
}
.box-shadow .bar-container .deg-input {
  margin-bottom: 0;
  margin-left: 0.4rem;
  max-width: 70px;
}
.box-shadow .radial-settings {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.box-shadow .radial-settings .wrapper {
  max-width: 90px;
}
.box-shadow .radial-settings.hidden {
  display: none;
}

.height-form {
  gap: 5px;
  display: grid;
  grid-column-gap: 5px;
  grid-template-columns: repeat(3, 1fr);
}

.width-form {
  gap: 5px;
  display: grid;
  grid-column-gap: 5px;
  grid-template-columns: repeat(3, 1fr);
}

.background-image-form .image-input {
  padding: 0;
}
